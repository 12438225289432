import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux'

import "./Card.css";
import Translation from "i18next";
import {
  thousandsSeparators,
  roundTwoDecimal,
  PriceListEnum,
} from "src/service/shared/utilits";

const Card = (props) => {
  const getImageSrc = () => {
    return props.item.itemImage !== null && props.item.itemImage !== undefined
      ? props.item.itemImage.filter((itemImage) => itemImage.isDefualt)
          .length === 0
        ? props.item.itemImage[0].imageUrl
        : props.item.itemImage[
            props.item.itemImage.findIndex((itemImage) => itemImage.isDefualt)
          ].imageUrl
      : "/f";
  };

  const isTravelExeclusiveFunc = () => {
    return !!props.travelExclusives.filter(
      (travelExclusive) => travelExclusive.id == props.item.id
    ).length;
  };
  const isOfferFunc = () => {
    return !!props.specialOffers.filter(
      (specialOffer) => specialOffer.id == props.item.id
    ).length;
  };

  const isNewArrivalFunc = () => {
    return !!props.newArrivals.filter(
      (newArrival) => newArrival.id == props.item.id
    ).length;
  };
  const isPromocodeFunc = () => {
    return props.promocodeProducts.priceListIndividualRateRes && props.promocodeProducts.priceListIndividualRateRes.filter(
      (item) => item.itemId === props.item.id).length > 0;
  };
  const { priceListApplied, buyGetPriceList, volumePriceList } = props.item;

  const getPriceListName = (volumePriceList) => {
    const selectedVolumePriceList = volumePriceList.filter(
      (item) => item.itemId === props.item.id
    );
    const filterOptions = (element) =>
      element.itemId === selectedVolumePriceList[0].itemId &&
      element.priceListId === selectedVolumePriceList[0].priceListId;
    const filteredPromotionPriceList = props.promotionPriceLists.filter(
      (item) => item.priceListIndividualRateRes.some(filterOptions)
    );
    if (filteredPromotionPriceList.length > 0) {
      return filteredPromotionPriceList[0].name;
    }
    return null;
  };
  const getPriceListNameById = (itemId, priceListId) => {
    const selectedVolumePriceList = volumePriceList.filter(
      (item) => item.itemId === props.item.id
    );
    const filterOptions = (element) =>
      element.itemId === itemId &&
      element.priceListId === priceListId;
    const filteredPromotionPriceList = props.promotionPriceLists.filter(
      (item) => item.priceListIndividualRateRes.some(filterOptions)
    );
    if (filteredPromotionPriceList.length > 0) {
      return filteredPromotionPriceList[0].name;
    }
    return null;
  };

  return (
    <div className="duty-free-card d-flex mb-4">
      <div className="d-pos">
        <div className="d-flex gap-2 duty-free-badge-container">
          {isNewArrivalFunc() ? (
            <span className="duty-free-text-badge">{Translation.t("new")}</span>
          ) : null}
          {isOfferFunc() || isPromocodeFunc() ? (
            <span className="duty-free-text-badge">
              {Translation.t("offer badge")}
            </span>
          ) : null}
          {isTravelExeclusiveFunc() ? (
            <span className="duty-free-card-badge">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/icons/card badge icon/svg/Travel Exclusive.svg"
                }
                alt="Travel Exclusive"
                loading="lazy"
              ></img>
              {/* <span className="text-uppercase new-arrival-badge-text">{Translation.t("new")}</span> */}
            </span>
          ) : null}
        </div>
      </div>

      <Row className="mx-0">
        <Col sm={12} className="duty-free-card-image">
          <div
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + getImageSrc()})`,
            }}
            className="df-card-img"
          ></div>
        </Col>
        <Col sm={12} className="text-left">
          <h2 className="duty-free-card-title">{props.item.name}</h2>
        </Col>
        <Col sm={12} className="text-left py-3 card-bottom-container">
          {!isPromocodeFunc() ? (
            <>
              {props.item.isPercentage ? (
                <>
                  <span className="duty-free-card-price">
                    {props.item.salesRate + " "}
                  </span>
                  <span className="duty-free-card-currency">
                    {props.currency}{" "}
                  </span>
                </>
              ) : (
                <>
                  {" "}
                  <span className="duty-free-card-price">
                    {props.item.currentPrice + " "}
                  </span>
                  <span className="duty-free-card-currency">
                    {props.currency}{" "}
                  </span>
                  {props.item.currentPrice < props.item.salesRate ? (
                    <span className="duty-free-card-old-price">
                      {props.item.salesRate + " " + props.currency}{" "}
                    </span>
                  ) : null}
                </>
              )}
            </>
          ) : (
            <>
              <span className="duty-free-card-price">
                {props.promocodeProducts.priceListIndividualRateRes.filter(
                  (item) => item.itemId === props.item.id
                )[0].amount + " "}
              </span>
              <span className="duty-free-card-currency">{props.currency} </span>
              <span className="duty-free-card-old-price">
                {props.item.salesRate + " " + props.currency}{" "}
              </span>
            </>
          )}
          {props.item.milesForRedeem ? (
            <>
              <span className="px-1" style={{ fontSize: "1.5rem" }}>
                /
              </span>
              <span className="duty-free-card-price-in-miles">
                {thousandsSeparators(
                  roundTwoDecimal(props.item.milesForRedeem)
                ) + " "}
              </span>
              <span
                className="duty-free-card-currency"
                style={{ fontSize: "12px" }}
              >
                {Translation.t("miles")}{" "}
              </span>
            </>
          ) : null}
          {buyGetPriceList.length && priceListApplied ? (
            PriceListEnum[priceListApplied] === "BuyGet" ? (
              <div className="deals-container">
                <span>
                  BUY {buyGetPriceList[0].buyQuantity} GET{" "}
                  {buyGetPriceList[0].getQuantity} For Free
                </span>
              </div>
            ) : null
          ) : null}
          {volumePriceList.length && priceListApplied ? (
            PriceListEnum[priceListApplied] === "Volume" ? (
              <div className="deals-container">
                <span>{getPriceListName(volumePriceList)}</span>
              </div>
            ) : null
          ) : null}
          {props.item.isPercentage ? (
            <div className="deals-container">
              <span>
                {getPriceListNameById(props.item.id, props.item.priceListId)}
              </span>
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currency: state.global.currency,
    locale: state.global.locale,
    newArrivals: state.batch.newArrivals,
    specialOffers: state.batch.specialOffers,
    travelExclusives: state.batch.travelExclusives,
    bestSellers: state.batch.bestSellers,
    promocodeProducts: state.batch.promocodeProducts,
    promotionPriceLists: state.batch.offerPriceLists,
  };
};

export default connect(mapStateToProps)(Card);
