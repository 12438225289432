import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Container, Col, Row, Form } from "react-bootstrap";
// import { propTypes } from "react-bootstrap/esm/Image";
import FeedBackService from "../../service/feed back/FeedBackService";
//   import "./Account.css";
import { connect } from "react-redux";
import Loading from "../shared/UI/Loading";
import { FeedBackFormValidator } from "../../service/validation/FeedBackFormValidator";

const FeedBack = (props) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [feedBackValidator, setFeedBackValidator] = useState(
    new FeedBackFormValidator()
  );
  const [feedBackForm, setFeedBackForm] = useState(() => {
    return {
      fullName: "",
      email: "",
      feedBack: "",
    };
  });

  const submitFeedBack = (event) => {
    event.preventDefault();

    var payload = {
      fullName: "",
      email: "",
      feedBack: "",
    };

    payload.fullName = feedBackForm.fullName;
    payload.email = feedBackForm.email;
    payload.feedBack = feedBackForm.feedBack;

    var feedBackService = new FeedBackService();

    setFeedBackValidator(
      new FeedBackFormValidator().validateFeedBackForm(feedBackForm)
        .FeedBackFormValidation
    );
    if (feedBackValidator.isTheFormValid(feedBackForm)) {
      setIsSubmiting(true);
      feedBackService
        .submitFeedBack(props.clientToken, props.accessToken, payload)
        .then((response) => {
          setIsSubmiting(false);
          setSubmitted(true);
        })
        .catch((error) => {
          setIsSubmiting(false);
          console.log(error);
        });
    }
  };

  const upddateFeedBackForm = (key, value) => {
    var temp = { ...feedBackForm };
    temp[key] = value;

    setFeedBackForm(temp);
  };

  document.title = "Feed Back";

  return (
    <section style={{ minHeight: "50vh", margin: "4rem 0" }}>
      {isSubmiting ? <Loading /> : null}
      <Container>
        <Row>
          {submitted ? (
            <Col xs={12} sm={12} md={8} lg={6} xl={5} className="m-auto">
              <Card
                style={{
                  padding: "3rem 1rem",
                  boxShadow: "0 5px 10px #eee",
                  borderRadius: "10px",
                }}
              >
                <h3>Thank You for your feed back</h3>
              </Card>
            </Col>
          ) : (
            <Form onSubmit={(event) => submitFeedBack(event)}>
              <Col xs={12} sm={12} md={8} lg={6} xl={5} className="m-auto">
                <Card
                  style={{
                    padding: "3rem 1rem",
                    boxShadow: "0 5px 10px #eee",
                    borderRadius: "10px",
                  }}
                >
                  <br />
                  <FontAwesomeIcon
                    icon={faCommentAlt}
                    size="3x"
                    className="d-flex align-self-center text-success"
                  />
                  <br />
                  <h3 className="text-center mb-3">Feed Back</h3>
                  <br />
                  <Col xs={12}>
                    <input
                      type="text"
                      placeholder="Full Name"
                      required
                      onChange={(event) =>
                        upddateFeedBackForm("fullName", event.target.value)
                      }
                      className={
                        feedBackValidator.isFullNameValid
                          ? "duty-free-input-field-control"
                          : "duty-free-input-field-control invalid-input-field"
                      }
                      onFocus={() => (feedBackValidator.isFullNameValid = true)}
                    />
                    {feedBackValidator.isFullNameValid ? null : (
                      <span className="d-block text-left text-danger dty-fr-input-field-validation-error">
                        {feedBackValidator.errors.fullName}
                      </span>
                    )}
                  </Col>
                  <Col xs={12} className="my-3">
                    <input
                      type="email"
                      placeholder="Email"
                      className={
                        feedBackValidator.isEmailValid
                          ? "duty-free-input-field-control"
                          : "duty-free-input-field-control invalid-input-field"
                      }
                      required
                      onChange={(event) =>
                        upddateFeedBackForm("email", event.target.value)
                      }
                      onFocus={() => (feedBackValidator.isEmailValid = true)}
                    />
                    {feedBackValidator.isEmailValid ? null : (
                      <span className="d-block text-left text-danger dty-fr-input-field-validation-error">
                        {feedBackValidator.errors.email}
                      </span>
                    )}
                  </Col>
                  <Col xs={12} className="mb-3">
                    <textarea
                      className={
                        feedBackValidator.isFeedBackBodyValid
                          ? "duty-free-input-field-control"
                          : "duty-free-input-field-control invalid-input-field"
                      }
                      placeholder="Feed Back"
                      id="w3review"
                      name="feedBack"
                      required
                      style={{ height: "8em" }}
                      onChange={(event) =>
                        upddateFeedBackForm("feedBack", event.target.value)
                      }
                      onFocus={() =>
                        (feedBackValidator.isFeedBackBodyValid = true)
                      }
                    ></textarea>
                    {feedBackValidator.isFeedBackBodyValid ? null : (
                      <span className="d-block text-left text-danger dty-fr-input-field-validation-error">
                        {feedBackValidator.errors.feedBackBody}
                      </span>
                    )}
                  </Col>
                  <br />
                  <Col xs={12} className="mb-3">
                    <button
                      //   disabled={isSgreed}
                      type="submit"
                      className="btn-primary-et btn col-12 "
                    >
                      Submit
                    </button>
                  </Col>
                </Card>
              </Col>
            </Form>
          )}
        </Row>
        <br />
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    isSearching: state.search.isSearching,
    searchString: state.search.searchString,
    searchType: state.search.searchType,
    searchResult: state.search.searchResult,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(FeedBack);