import React, { useState } from "react";
import { Form, Col } from "react-bootstrap";
import { connect } from "react-redux";

import {
    faPlane,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import Loading from "../UI/Loading";

import { globalActionType } from "../../../redux/actions/shared/globalAction";
import { PaymentFormValidation } from "../../../service/validation/PaymentFormValidation";
import moment from "moment";
import OnBoardingService from "../../../service/on-boarding/OnBoardingService";
import { batchActionType } from "../../../redux/actions/shared/batchAction";
import ProgressSimpler from "../UI/ProgressSimpler";

const OnBoardingForm = (props) => {
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [isSetting, setISetting] = useState(false);
    const [paxSectorsRes, setPaxSectorsRes] = useState(() => {
        let pax = [];
        if (Object.keys(props.onBoardingDetail).length !== 0)
            pax.push(props.onBoardingDetail);
        return pax
    });
    const [paxSectorsTracker, setPaxSectorsTracker] = useState(0);
    const [paymentValidationForm, setPaymentValidationForm] = useState(
        new PaymentFormValidation()
    );

    const [errorOccuredTicketNumber, setErrorOccuredTicketNumber] =
        useState(false);

    const [ticketNumberNotFound, setTicketNumberNotFound] = useState("");

    const [paymentFormTracker, setPaymentFormTracker] = useState({});

    const updatePaymentFormTracker = (key, value) => {
        var temp = { ...paymentFormTracker };
        temp[key] = value;
        setPaymentFormTracker(temp);
    };


    const fetchFlightInfoUsingTicketNumber = () => {
        if (
            paymentFormTracker.ticketNumber &&
            paymentValidationForm.isTicketNumberValid &&
            !errorOccuredTicketNumber
        ) {
            setIsSubmiting(true);
            var onBoardingService = new OnBoardingService();
            clearFlightInfor();
            setPaxSectorsTracker(0);

            onBoardingService
                .getOnBoardingPassengerFromTicket(
                    props.clientToken,
                    props.accessToken,
                    paymentFormTracker.ticketNumber
                )
                .then((response) => {
                    setIsSubmiting(false);
                    if (response.data.paxSectorsRes.length === 1) {
                        setPaxSectorsTracker(response.data.paxSectorsRes[0].id);
                        setFlightInformation(response.data.paxSectorsRes[0]);
                    }
                    setPaxSectorsRes(response.data.paxSectorsRes);
                    if (response.data.paxSectorsRes.length === 0)
                        setTicketNumberNotFound(
                            response.data.Message
                                ? response.data.Message
                                : response.data.message
                        );
                })
                .catch((error) => {
                    setIsSubmiting(false);
                    setPaxSectorsRes([]);
                    setPaxSectorsTracker(0);
                    if (error.response.data.Message || error.response.data.message) {
                        setTicketNumberNotFound(
                            error.response.data.Message
                                ? error.response.data.Message
                                : error.response.data.message
                        );
                    } else {
                        setTicketNumberNotFound("Something when wrong");
                    }
                    console.error(error, error.response);
                });
        }
    };

    const setFlightInformation = (flightInfo) => {
        var temp = { ...flightInfo };
        temp.departureDate = moment(flightInfo.departureDate).toDate();
        temp.departureDateFromAPI = flightInfo.departureDate;
        temp.ticketNumber = paymentFormTracker.ticketNumber;
        setPaymentFormTracker(temp);
    };
    const clearFlightInfor = () => {
        var temp = { ...paymentFormTracker };
        temp.origin = "";
        temp.destination = "";
        temp.flightNumber = "";
        temp.departureDate = "";
        temp.passengerName = "";

        setPaymentFormTracker(temp);
    };

    const doNothingMethod = () => {
        //
    };

    const proceed = (event) => {
        event.preventDefault();
    };

    const setOnboarding = (status, detail) => {
        if (paxSectorsRes.length !== 0) {
            setISetting(true);
            detail.ticketNumber = paymentFormTracker.ticketNumber;
            var values = { newArrivalItems: [], allInOneList: [], specialOfferItems: [] }
            if (Object.keys(detail).length !== 0 && status) {
                props.updateOnboardingDetail(detail);
                props.setOnboardingStatus(status);
                props.setIsLoadingTrue();
                props.saveBatch(values);
            }
            if (!status) {
                setPaxSectorsRes([]);
                props.updateOnboardingDetail(detail);
                props.setOnboardingStatus(status);
                props.setIsLoadingTrue();
                props.saveBatch(values);
            }
            window.location.reload(false);
        }
    };
    return (
        <div>
            {isSubmiting ? <Loading /> : null}
            <Form
                onSubmit={(event) => proceed(event)}
            >
                <div className="">
                    {props.onBoardingStatus ? null :

                        <Col xs={12} className="my-3 px-0">
                            <input
                                type="number"
                                className={
                                    paymentValidationForm.isTicketNumberValid &&
                                        !errorOccuredTicketNumber
                                        ? "duty-free-input-field-control"
                                        : "duty-free-input-field-control is-invalid-input"
                                }
                                // value={flightNumber}
                                placeholder="Ticket Number"
                                id="TicketNumberInput"
                                onChange={(event) => {
                                    setErrorOccuredTicketNumber(event.target.value.length !== 13);
                                    setPaymentValidationForm(
                                        paymentValidationForm.validateTicketNumber(event.target.value)
                                    );
                                    clearFlightInfor();
                                    setPaxSectorsTracker(0);
                                    setPaxSectorsRes([]);
                                    updatePaymentFormTracker("ticketNumber", event.target.value);
                                    setTicketNumberNotFound("");
                                }}
                                onBlur={() => {
                                    setPaymentValidationForm(
                                        paymentValidationForm.validateTicketNumber(
                                            paymentFormTracker.ticketNumber
                                        )
                                    );
                                    fetchFlightInfoUsingTicketNumber();
                                }}
                            />
                            {errorOccuredTicketNumber ? (
                                <span className="d-block mt-2 text-left text-danger dty-fr-input-field-validation-error">
                                    Ticket Number Character length should be 13. Current character
                                    length (
                                    <strong>{paymentFormTracker.ticketNumber.length}</strong>).
                                </span>
                            ) : null}

                            {ticketNumberNotFound !== "" ? (
                                <span className="d-block my-2 text-left text-danger dty-fr-input-field-validation-error">
                                    {ticketNumberNotFound}
                                </span>
                            ) : null}
                        </Col>}

                    <div className="duty-free-radio-container flex-wrap">
                        {paxSectorsRes.length === 1 ? (
                            <>
                                {paxSectorsRes.map((element) => {
                                    return (
                                        <div
                                            key={element.id}
                                            className="duty-free-custom-radio-btn duty-free-custom-radio-btn-flight d-flex"
                                        >
                                            <div
                                                className="duty-free-custom-radio-insider-container custom-selected-btn duty-free-custom-radio-btn-insider-flight"
                                            // onClick={() => setPaymentOptionTracker(element.id)}
                                            >
                                                <div className="col-12 flight-number-container">
                                                    <input
                                                        type="radio"
                                                        name="flightNumberTracker"
                                                        value="onboard"
                                                        checked
                                                        onChange={() => doNothingMethod()}
                                                        className="float-right"
                                                    />
                                                    <img src={process.env.PUBLIC_URL + "/favicon.ico"} className="mr-2" style={{ width: "26px" }} loading="lazy" />
                                                    <span className="mb-2">{element.flightNumber}</span>
                                                </div>

                                                <div className="row w-100 mx-0 mb-2">
                                                    <div className="col flight-airport-container">
                                                        {element.originAirport} ({element.origin})
                                                    </div>
                                                    <div className="col-1 d-flex justify-content-center align-items-center">
                                                        {" "}
                                                        <FontAwesomeIcon icon={faPlane} />
                                                    </div>
                                                    <div className="col flight-airport-container">
                                                        {element.destinationAirport} ({element.destination})
                                                    </div>
                                                </div>
                                                <div className="col-12 departure-date">
                                                    <span>
                                                        {moment(element.departureDate).format(
                                                            "DD MMM, YYYY"
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <>
                                {paxSectorsRes.map((element) => {
                                    return (
                                        <div
                                            key={element.id}
                                            className="duty-free-custom-radio-btn duty-free-custom-radio-btn-flight d-flex"
                                        >
                                            <div
                                                className={
                                                    paxSectorsTracker === element.id
                                                        ? "duty-free-custom-radio-insider-container duty-free-custom-radio-btn-insider-flight custom-selected-btn"
                                                        : "duty-free-custom-radio-insider-container duty-free-custom-radio-btn-insider-flight"
                                                }
                                                onClick={() => {
                                                    setFlightInformation(element);
                                                    setPaxSectorsTracker(element.id);
                                                }}
                                            >
                                                <div className="col-12 flight-number-container">
                                                    {paxSectorsTracker === element.id ? (
                                                        <input
                                                            type="radio"
                                                            name="flightNumberTracker"
                                                            // defaultValue="onboard"
                                                            value="onboard"
                                                            // defaultChecked
                                                            checked
                                                            // onChange={() => doNothingMethod()}
                                                            className="float-right"
                                                        />
                                                    ) : (
                                                        <input
                                                            type="radio"
                                                            name="flightNumberTracker"
                                                            // defaultValue="onboard"
                                                            value="onboard"
                                                            // onChange={() => doNothingMethod()}
                                                            className="float-right"
                                                        />
                                                    )}
                                                    <img src={process.env.PUBLIC_URL + "/favicon.ico"} className="mr-2" style={{ width: "26px" }} loading="lazy" />
                                                    <span className="mb-2">{element.flightNumber}</span>
                                                </div>
                                                <div className="row w-100 mx-0 mb-2">
                                                    <div className="col flight-airport-container">
                                                        {element.originAirport} ({element.origin})
                                                    </div>
                                                    <div className="col-1 d-flex justify-content-center align-items-center">
                                                        {" "}
                                                        <FontAwesomeIcon icon={faPlane} />
                                                    </div>
                                                    <div className="col flight-airport-container">
                                                        {element.destinationAirport} ({element.destination})
                                                    </div>
                                                </div>
                                                <div className="col-12 departure-date">
                                                    <span>
                                                        {moment(element.departureDate).format(
                                                            "DD MMM, YYYY"
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                    {!paymentValidationForm.isOriginValid &&
                        paxSectorsRes.length !== 0 ? (
                        <span className="d-block my-2 text-left text-danger dty-fr-input-field-validation-error">
                            Please Select Your Flight Information
                        </span>
                    ) : null}
                    <br />
                </div>
            </Form>
            <Col xs={12} className="d-flex px-0">
                {isSetting ? <div className="mx-auto"><ProgressSimpler /></div> : props.onBoardingStatus ? <button
                    type="button"
                    onClick={() => setOnboarding(false, {})}
                    className="btn-secondary-et btn col-12 col-sm-6 mx-auto py-2"
                >
                    Remove
                </button>
                    :
                    <button
                        type="button"
                        onClick={() => setOnboarding(true, paymentFormTracker)}
                        className="btn-primary-et btn col-12 col-sm-6 mx-auto py-2"
                    >
                        Set
                    </button>
                }
            </Col>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        clientToken: state.authClient.clientToken,
        accessToken: state.authAccess.accessToken,
        onBoardingStatus: state.batch.onBoardingStatus,
        onBoardingDetail: state.batch.onBoardingDetail,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateOnboardingDetail: (value) =>
            dispatch({
                type: batchActionType.UPDATEONBOARDINGDETAIL,
                value: value,
            }),
        setOnboardingStatus: (value) =>
            dispatch({
                type: batchActionType.SETONBOARDINGSTATUS,
                value: value,
            }),
        saveBatch: (value) =>
            dispatch({
                type: batchActionType.UPDATEBATCH,
                values: value,
            }),
        setIsLoadingTrue: () =>
            dispatch({
                type: batchActionType.UPDATEBATCHISLOADINGTOTRUE
            }),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OnBoardingForm)
);
