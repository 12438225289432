import { combineReducers, createStore } from 'redux';
// import { configureStore } from 'react-redux'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import globalReducer from '../reducer/shared/globalReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import clientTokenReducer from '../reducer/auth/clientTokenReducer';
import accessTokenReducer from '../reducer/auth/accessTokenReducer';
import storageSession from 'redux-persist/lib/storage/session';
import searchReducer from '../reducer/search/searchReducer';
import cartReducer from '../reducer/cart/cartReducer';
import accountReducer from '../reducer/auth/accountReducer';
import expireIn from 'redux-persist-transform-expire-in';
import batchReducer from '../reducer/shared/batchReducer';

const globalPersistConfig = {
  key: 'global',
  storage: storage,
  blacklist: [
    'isMobile',
    'isConsentAccepted',
    'initializing',
    'filteredResult',
    'sortParams',
    'activeFilterCriteria',
    'filterCriteria',
    'initializing',
    'activePage',
  ],
};
const clientTokenPersistConfig = {
  key: 'clientToken',
  storage: storageSession,
};
const accessTokenPersistConfig = {
  key: 'accessToken',
  storage: storageSession,
};
const cartListPersistConfig = {
  key: 'cart',
  storage: storage,
  transforms: [expireIn(1000 * 60 * 60, 'cart', {})],
};

const searchReducerConfig = {
  key: 'search',
  storage: storageSession,
};

// const expireReducer = require("redux-persist-expire");

const accountReducerConfig = {
  key: 'account',
  storage: storage,
  blacklist: ['shebaMilesCustomerProfile'],
  transforms: [expireIn(1000 * 60 * 60, 'account', false)],
};

const batchReducerConfig = {
  key: 'batch',
  storage: storageSession,
};

// const persistedAccoundReducer = persistReducer(
//   accountReducerConfig,
//   accountReducer,
//   {
//     transforms: [
//       expireReducer("account", {
//         expireSeconds: 60,
//       }),
//       // You can add more `expireReducer` calls here for different reducers
//       // that you may want to expire
//     ],
//   }
// );

const rootReducer = combineReducers({
  global: persistReducer(globalPersistConfig, globalReducer),
  authClient: persistReducer(clientTokenPersistConfig, clientTokenReducer),
  authAccess: persistReducer(accessTokenPersistConfig, accessTokenReducer),
  cart: persistReducer(cartListPersistConfig, cartReducer),
  search: persistReducer(searchReducerConfig, searchReducer),
  account: persistReducer(accountReducerConfig, accountReducer),
  batch: persistReducer(batchReducerConfig, batchReducer),
});

export const store = createStore(rootReducer);
// export const store = createStore(rootReducer, composeWithDevTools());
export const persistor = persistStore(store);
