import {
  Modal,
  Nav,
  Form,
  Button,
  Row,
  Col,
  FormControl,
} from "react-bootstrap";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { connect } from "react-redux";
import { searchActionType } from "../../../redux/actions/search/searchAction";
import { withRouter } from "react-router-dom";
import Translation from "i18next";
import AutoSuggest from "../auto-suggest/AutoSuggest";

class SearchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      searchValue: "",
      searchType: "2",
    };
  }
  closeModal = () => {
    this.setState({ showModal: false, searchValue: "" });
  };
  showModal = () => {
    this.setState({ showModal: true });
  };

  search = () => {
    if (this.state.searchValue !== "") {
      var temp = this.state.searchValue;
      this.closeModal();
      this.setState({ searchValue: "" });
      this.props.updateIsSearching(true);
      this.props.history.push({
        pathname: "/SearchResult",
        params: { searchValue: temp, searchType: this.state.searchType },
      });
    }
    // this.setState({ redirect: true });
  };

  render() {
    return (
      <div>
        <Nav.Link
          className="px-2"
          onClick={() => this.showModal()}
          aria-label="Open search modal"
        >
          <span>
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </Nav.Link>
        <Modal
          show={this.state.showModal}
          onHide={() => this.closeModal()}
          size="lg"
          className="search-modal"
        >
          <Modal.Body className="p-0">
            <Form
              className="w-100 header-search-form"
              onSubmit={(event) => {
                event.preventDefault();
                this.search();
              }}
            >
              <Row className="align-items-center">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="px-0 d-flex"
                >
                  {/* <Form.Select
                    aria-label="duty free select input"
                    id="duty-free-select-input"
                    className="duty-free-select-input duty-free-slect-on-modal"
                    onChange={(event) =>
                      this.setState({ searchType: event.target.value })
                    }
                  >
                    <option value="2">{Translation.t("search by all")}</option>
                    <option value="0">{Translation.t("search by brand")}</option>
                    <option value="1">{Translation.t("search by category")}</option>
                  </Form.Select> */}
                  <FormControl
                    id="inlineFormInputGroup"
                    // placeholder="Enter your search text here"
                    className="rounded-0 border-0"
                    value={this.state.searchValue}
                    onChange={(event) => {
                      this.setState({ searchValue: event.target.value });
                      this.props.updateSearchString(event.target.value);
                    }}
                    placeholder={
                      this.state.searchType === "2"
                        ? Translation.t("search by all text")
                        : this.state.searchType === "0"
                        ? Translation.t("search by brand text")
                        : Translation.t("search by category text")
                    }
                    autoComplete="off"
                    required
                  />
                  <Button
                    // type="submit"
                    className="rounded-0 header-search-button-mobile btn-primary-et btn"
                    onClick={() => this.search()}
                    aria-label="Search"
                  >
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ fontSize: "20px" }}
                    />
                  </Button>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="px-0 d-flex"
                >
                  {this.state.searchValue !== "" &&
                    this.state.searchValue.length > 1 && (
                      <div className="auto-suggest-container">
                        <AutoSuggest
                          searchKey={this.state.searchValue}
                          closeOpenSearchBar={this.closeModal}
                          history={this.props.history}
                        />
                      </div>
                    )}
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    cart: state.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateIsSearching: (value) =>
      dispatch({
        type: searchActionType.UPDATESEARCING,
        value: value,
      }),
    updateSearchString: (value) =>
      dispatch({
        type: searchActionType.UPDATESEARCHSTRING,
        value: value,
      }),
    updateSearchType: (value) =>
      dispatch({
        type: searchActionType.UPDATESEARCHTYPE,
        value: value,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchModal));
