import React from 'react';
import { Container } from 'react-bootstrap';

import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ImportantPages.css';

const AfterSalesSupport = (props) => {
  document.title = 'After Sales Support';

  const webSiteLink = () => {
    return (
      <a href="https://shebadutyfree.ethiopianairlines.com">
        https://shebadutyfree.ethiopianairlines.com
      </a>
    );
  };
  const contactUsEmail = () => {
    return (
      <>
        <a href="mailto:DutyFreeServices@ethiopianairlines.com">
          DutyFreeServices@ethiopianairlines.com
        </a>
        or
        <a href="mailto:InflightDutyfree@ethiopianairlines.com">
          InflightDutyfree@ethiopianairlines.com
        </a>
      </>
    );
  };

  const contactUsNumber = () => {
    return <a href="tel:+251115178101">+251 115 178101</a>;
  };
  return (
    <Container
      className="my-3 px-3 py-3 px-md-5 text-left"
      style={{ marginBottom: '5.7em !important' }}
    >
      <h3 className="text-center text-decoration-underline mb-4">AFTER SALES SUPPORT CENTRE</h3>
      <p>
        To ensure a consistent and accurate support, the After-Sales Support Centre will provide a
        comprehensive customer service facility to passengers with queries relating to Duty Free
        In-flight shopping (On board and on-line).
      </p>
      <p>
        For all issues, please kindly contact After-Sales Support Centre at {contactUsNumber()} with
        GMT+3 from 8:00 AM to 05:00 PM.
      </p>

      <p>
        Or at any convenient time, you can drop an e-mail at {contactUsEmail()} or you can visit our
        Website @ {webSiteLink()}
      </p>

      <h5 className="text-decoration-underline">PRE-ORDERS</h5>
      <ul>
        <li>
          You can pre-order products via our website. And you can call for After Sales Support
          centre for assistance at {contactUsEmail()}.
        </li>
        <li>All pre-ordered products are available for delivery on board.</li>
        <li>You can pre-order Goods till you have left 24 hours for departure.</li>
        <li>Goods are sold for personal use only and not for resale.</li>
        <li>No delivery charge is applied for pre-order products. </li>
        <li>
          Proof of order will be required in order to receive the products and should be presented
          to the crew member on board.
        </li>
        <li>
          Products are held at our risk until it is received by you, at which point in time, the
          risk passes to you.
        </li>
      </ul>

      <h5 className="text-decoration-underline">RETURNS AND CANCELLATIONS</h5>
      <p>
        A passenger can contact the After-Sales Support team when they are unhappy with a product
        for the below reasons:
      </p>
      <ul>
        <li>
          Damaged / Faulty = When you receive Goods that are faulty and If you notify us within 7
          days of receipt, replacement will be done. Please note that Goods shall not be considered
          faulty if a defect is due by your negligence.
        </li>
        <li>Incorrect item received</li>
        <li>Double/over charged</li>
        <li>On-board purchase not received</li>
        <li>Item Out of stock</li>
        <li>Pre-order not received</li>
        <li>Assist with Website orders / systems</li>
      </ul>
      <p>
        Goods which have been customized to your order, but upon receipt if it is deteriorated and
        damaged, you are legally entitled to cancel your Request by notifying us in writing at any
        time within 7 days following receipt of the Goods.
      </p>
      <p>
        We will then replace exactly the same product or the Goods with alternative types up to the
        value of the returned Goods.
      </p>
      <p>For returns and/or cancellations, we must receive the Goods back from you:</p>

      <ul>
        <li>Within 7 days of your notification;</li>
        <li>With the original receipt or delivery note;</li>
        <li>
          With a covering letter (specifying your order number, order date, delivery address,
          contact details, details of the fault).
        </li>
        <li>Goods you return to us needs to be submitted with the conditions set out.</li>
        <li>
          We regret that we are unable to offer refunds for faulty/damages. In such cases, a
          replacement will be offered.
        </li>
        <li>
          Pre-order goods cannot be replaced or exchanged onboard and should be returned to After
          Sales Support Team.
        </li>
      </ul>
      <h5 className="font-weight-bold">ONBOARD PURCHASED REFUNDS AND EXCHANGES </h5>
      <p>
        <strong>Refunds</strong>
      </p>
      <p>
        Refunds are only given onboard for product(s) purchased on the same sector on which it was
        bought.
      </p>
      <p>
        Product purchased on a different sector cannot be refunded on board. Such refunds should be
        referred to the After-Sales Support team.
      </p>
      <p>
        When a refund is made onboard, the form of refund is always the same as the original form of
        payment as shown on the sales receipt, for example, if payment was by credit card, the
        refund is processed back to the same credit card.
      </p>
      <p>
        <strong>Exchanges / Replacement</strong>
      </p>

      <p>
        Exchanges are only made onboard for customers wanting to return a faulty product purchased
        on the same sector on which it was bought – and only if an identical replacement product is
        available.
      </p>
      <p>
        If an identical replacement product is not available, the customer must be referred to the
        After-Sales Support Centre.
      </p>
      <p>
        Faulty product purchased on a different sector MUST NOT be exchanged. Such cases must be
        referred to After Sales Support Centre.
      </p>
    </Container>
  );
};

export default AfterSalesSupport;
