import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { connect } from 'react-redux';
import AccountSideMenu from './AccountSideMenu';
import { accountActionType } from '../../redux/actions/auth/accountAction';
import Loading from '../shared/UI/Loading';
import Translation from 'i18next';

const AccountDashboard = (props) => {
  const [signiningOut, setSigniningOut] = useState(false);

  document.title = Translation.t('my account');

  const logOut = () => {
    setSigniningOut(true);
    setTimeout(() => {
      setSigniningOut(false);
      props.signOut();
      props.history.push('/account');
    }, 1000);
  };
  return (
    <div className="light-background-color">
      <Container className="px-3 py-5 px-md-5">
        {!props.isSignedIn ? props.history.push('/login') : null}
        {signiningOut ? <Loading /> : null}
        <h1 className="text-center mb-5 pb-5">{Translation.t('my account')}</h1>
        <Row>
          <Col sm="12" md="3">
            <AccountSideMenu from={1} />
          </Col>
          <Col sm="12" md="9">
            <p className="text-left">
              {Translation.t('hello')} <strong>{props.userInfo.username}</strong> (
              {Translation.t('not')} <strong>{props.userInfo.username}</strong>?{' '}
              <span className="text-decoration-underline cursor-pointer" onClick={() => logOut()}>
                {Translation.t('log out')}
              </span>
              )
            </p>
            <p className="text-left">{Translation.t('detail')}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    cart: state.cart.cart,
    userInfo: state.account.userInfo,
    isSignedIn: state.account.isSignedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () =>
      dispatch({
        type: accountActionType.SIGNOUT,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountDashboard);
