import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import './Account.css';
import { connect } from 'react-redux';

import AccountService from '../../service/account/AccountService';
import Loading from '../shared/UI/Loading';
import { accountActionType } from '../../redux/actions/auth/accountAction';
import { ResetPasswordModel } from '../../model/account/ResetPasswordModel';
import { ResetPasswordValidator } from '../../service/validation/ResetPasswordValidator';
import { getUrlVars } from 'src/service/shared/utilits';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPasswordValidatorState: new ResetPasswordValidator(), //new ResetPasswordValidator(),
      logging: false,
      passwordChanged: false,
      errorMsg: '',
    };
    this.resetPasswordModel = new ResetPasswordModel();
    this.resetPasswordModelValidator = new ResetPasswordValidator(); //new ResetPasswordValidator();
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    this.setState({
      resetPasswordValidatorState: this.state.resetPasswordValidatorState.validateResetPasswordInfo(
        this.resetPasswordModel
      ).resetPasswordValidation,
    });
    this.resetPasswordModelValidator =
      this.state.resetPasswordValidatorState.validateResetPasswordInfo(
        this.resetPasswordModel
      ).resetPasswordValidation;

    if (
      this.resetPasswordModelValidator.isPasswordValid &&
      this.resetPasswordModelValidator.isConfirmPasswordValid
    ) {
      var accountServiceObj = new AccountService();
      this.setState({ logging: true });

      accountServiceObj
        .resetPassword(this.props.clientToken, this.props.accessToken, {
          verification_token: getUrlVars()['verification_token'],
          password: this.resetPasswordModel.password,
        })
        .then((response) => {
          if (response.data.message === 'operation ended with errors') {
            this.setState({
              logging: false,
              errorMsg: 'Invalid token',
            });
          } else {
            this.setState({ logging: false, passwordChanged: true });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            var errorMessage = '';
            if (error.response.data.errors.Username !== undefined) {
              errorMessage = error.response.data.errors.Username[0];
            }
            if (errorMessage !== '' && error.response.data.errors.Password !== undefined) {
              errorMessage = errorMessage + ', ';
            }
            if (error.response.data.errors.Password !== undefined) {
              errorMessage = errorMessage + error.response.data.errors.Password[0];
            }
            this.setState({ errorMsg: errorMessage });
          }
          if (error.response.status === 500) {
            this.setState({ errorMsg: error.response.data.message });
          }
          console.log(error.response.status, error.response.data.message);
          this.setState({ logging: false });
        });
    }
  };
  onInputKeyDown = (resetPasswordValidator) => {
    this.setState({
      resetPasswordValidatorState: resetPasswordValidator,
      errorMsg: '',
    });
  };

  render() {
    return (
      <section style={{ minHeight: '50vh', margin: '4rem 0' }}>
        {this.state.logging ? <Loading /> : null}
        {this.state.passwordChanged ? (
          <Container className="container shebamiles-login-container">
            <Row>
              <Col xs={12} sm={12} md={8} lg={5} xl={4} className="m-auto">
                <Card
                  style={{
                    paddingBottom: '3rem',
                    boxShadow: '0 5px 10px #eee',
                    borderRadius: '10px',
                  }}
                >
                  <br />
                  <br />
                  <FontAwesomeIcon
                    icon={faUnlockAlt}
                    size="3x"
                    className="d-flex align-self-center text-success"
                  />
                  <br />
                  <div className="pb-4">
                    <h3 className="text-center py-3">Rest Password</h3>
                  </div>

                  <div className="sheba-miles-login mx-4">
                    <h5>Password Changed sucessfully</h5>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container className="container shebamiles-login-container">
            <Row>
              <Col xs={12} sm={12} md={8} lg={5} xl={4} className="m-auto">
                <Card
                  style={{
                    paddingBottom: '3rem',
                    boxShadow: '0 5px 10px #eee',
                    borderRadius: '10px',
                  }}
                >
                  <br />
                  <br />
                  <FontAwesomeIcon
                    icon={faUnlockAlt}
                    size="3x"
                    className="d-flex align-self-center text-success"
                  />
                  <br />
                  <div className="pb-4">
                    <h3 className="text-center py-3">Rest Password</h3>
                  </div>

                  <div className="sheba-miles-login mx-4">
                    <Form onSubmit={this.handleFormSubmit}>
                      <Form.Group className="mb-3" controlId="formPassword">
                        <Form.Label className="float-left">
                          New Password <span>*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder={
                            this.state.resetPasswordValidatorState.isPasswordValid
                              ? ''
                              : 'Email Address field is required...'
                          }
                          onChange={(event) =>
                            (this.resetPasswordModel.password = event.target.value)
                          }
                          className={
                            this.state.resetPasswordValidatorState.isPasswordValid
                              ? 'input-field'
                              : 'invalid-input-field'
                          }
                          onKeyDown={() => {
                            this.resetPasswordModelValidator.isPasswordValid = true;
                            this.onInputKeyDown(this.resetPasswordModelValidator);
                          }}
                          required
                        />
                        {this.resetPasswordModelValidator.isPasswordValid ? null : (
                          <span className="d-block text-left text-danger dty-fr-input-field-validation-error">
                            {this.resetPasswordModelValidator.errors.password}
                          </span>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formConfirmPassword">
                        <Form.Label className="float-left">
                          Confirm Password <span>*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder={
                            this.state.resetPasswordValidatorState.isConfirmPasswordValid
                              ? ''
                              : 'Email Address field is required...'
                          }
                          onChange={(event) =>
                            (this.resetPasswordModel.confirmPassword = event.target.value)
                          }
                          className={
                            this.state.resetPasswordValidatorState.isConfirmPasswordValid
                              ? 'input-field'
                              : 'invalid-input-field'
                          }
                          onKeyDown={() => {
                            this.resetPasswordModelValidator.isConfirmPasswordValid = true;
                            this.onInputKeyDown(this.resetPasswordModelValidator);
                          }}
                          required
                        />
                        {this.resetPasswordModelValidator.isConfirmPasswordValid ? null : (
                          <span className="d-block text-left text-danger dty-fr-input-field-validation-error">
                            {this.resetPasswordModelValidator.errors.confirmPassword}
                          </span>
                        )}
                        {this.state.errorMsg !== '' ? (
                          <span className="d-block text-left text-danger dty-fr-input-field-validation-error">
                            {this.state.errorMsg}
                          </span>
                        ) : null}
                      </Form.Group>
                      <br />
                      <Button variant="primary" type="submit" className="et-btn w-100 py-2">
                        Reset
                      </Button>
                    </Form>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    cart: state.cart.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateIsSignIn: (value) =>
      dispatch({
        type: accountActionType.UPDATEISSIGNIN,
        value: value,
      }),
    updateCustomerInfo: (value) =>
      dispatch({
        type: accountActionType.UPDATECUSTOMERINFOMATION,
        value: value,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
