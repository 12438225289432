import HttpService from "../HttpService";

export default class batchService {
  getPreOrderItems(clientToken, accessToken) {
    let relativeUrl = "/PreOrder/api/V1.0/PreOrder/GetPreOrderItems";
    let ActionPrivilegies = "";

    return HttpService.getServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      {},
      ActionPrivilegies
    );
  }
  
  getOnBoardingItems(clientToken, accessToken, payload) {
    let relativeUrl = "/OnBoardOrder/api/V1.0/OnBoardOrder/GetAllOnBoardItems";
    let ActionPrivilegies = "";
    let updatedPayload = {FlightNumber : payload.flightNumber,
      DepartureDate : payload.departureDateFromAPI,
      Origin: payload.origin,
      Destination : payload.destination,
      TicketNumber:payload.flightNumber};
    return HttpService.getServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      updatedPayload,
      ActionPrivilegies
    );
  }
  
  getProductPriceList(clientToken, accessToken, payload) {
    let relativeUrl = "/PreOrder/api/V1.0/PriceList/GetPriceListByOfferType";
    let ActionPrivilegies = "PreOrder-Access";

    return HttpService.getServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      payload,
      ActionPrivilegies
    );
  }
}
