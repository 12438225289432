import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';

import './Account.css';
const GuestRegistration = () => {
  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <section className="light-background-color" style={{ minHeight: '50vh', padding: '2rem 0' }}>
      <Container className="container shebamiles-login-container">
        <Row>
          <Col xs={12} sm={12} md={8} lg={5} xl={5} className="m-auto">
            <Card
              style={{
                paddingBottom: '3rem',
                boxShadow: '0 5px 10px #eee',
                borderRadius: '10px',
                minHeight: '45vh',
              }}
            >
              <br />
              <br />
              {/* <div className="pb-4">
                  <img
                    src={process.env.PUBLIC_URL + "/images/et-logo.png"}
                    className="img-fluid"
                    style={{ width: "300px" }}
                    loading="lazy"
                  />
                </div> */}
              <div className="mx-4" style={{ paddingBottom: '3rem' }}>
                <h3 className="text-left">Guest Registration</h3>
                <hr />
              </div>

              <div className="sheba-miles-login mx-4">
                <Form onSubmit={handleFormSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="float-left">
                      Please insert your ticket number <span>*</span>
                    </Form.Label>
                    <Form.Control type="text" required />
                  </Form.Group>
                  <br />
                  <br />

                  <Button className="float-left duty-free-outline-primary-btn" href="/account">
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <span className="px-2">Back</span>
                  </Button>
                  <Button variant="primary" type="submit" className="float-right et-btn  py-2">
                    Submit
                  </Button>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GuestRegistration;
