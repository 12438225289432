import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";

import "./Payment.css";
import {
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { cartActionType } from "../../redux/actions/cart/cartAction";

const PaymentCancel = (props) => {
  document.title = "Payment Canceled";
 

  return (
    <Container className="my-5 px-3 py-5 px-md-5 duty-free-payment-container">
        <div className="text-center text-warning pb-3">
        <FontAwesomeIcon size={"3x"} icon={faExclamationCircle} />
        </div>
        <h4 className="text-center">Payment Canceled</h4>
     </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    paymentOptions: state.account.paymentOptions,
    userInfo: state.account.userInfo,
    cart: state.cart.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    swapCart: (value) =>
      dispatch({
        type: cartActionType.SWAPPCART,
        value: value,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCancel);
