import { accessTokenActionType } from "../../actions/auth/accessTokenAction";

const initialState = {
  accessToken: "",
};

const accessTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case accessTokenActionType.SAVEACCESSTOKEN:
      return {
        ...state,
        accessToken: action.token,
      };

    default:
      return state;
  }
};

export default accessTokenReducer;
