import { useEffect, useState } from "react";
import { connect } from "react-redux";

const AutoSuggest = (props) => {
  const [suggestProducts, setSuggestProducts] = useState([]);
  useEffect(() => {
    var tempFilteredProducet = props.products.filter((product) =>
      product.name.toLowerCase().includes(props.searchKey.toLowerCase())
    );
    setSuggestProducts(tempFilteredProducet.slice(0, 5));
  }, [props.searchKey]);
  if (suggestProducts.length === 0) {
    return <p>No Matchs Found</p>;
  } else {
    return (
      <div className="w-100">
        {suggestProducts.map((product, index) => (
          <div
            key={index}
            className="d-flex justify-content-between align-items-center auto-suggest-item w-100 p-2"
            onClick={() => {
              props.history.push({
                pathname: "/product-detail/" + product.id,
                state: product,
              });
              props.closeOpenSearchBar();
            }}
          >
            <div className="d-flex justify-content-start align-items-center">
              <img
                src={product.itemImage[0].imageUrl}
                style={{ width: "60px", height: "60px" }}
                loading="lazy"
              />
              <p className="text-start d-inline" style={{ fontSize: "20px" }}>
                <span className="mx-1"> {product.name}</span>
              </p>
            </div>

            <p key={index}>
              {product.isPercentage ? (
                <>
                  {product.salesRate} {product.currency}
                </>
              ) : (
                <>
                  {product.currentPrice} {product.currency}
                </>
              )}
            </p>
          </div>
        ))}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    products: state.batch.products,
  };
};

export default connect(mapStateToProps, null)(AutoSuggest);
