export const accountActionType = {
    UPDATEISSIGNIN: "UPDATEISSIGNIN",
    UPDATECUSTOMERINFOMATION: "UPDATECUSTOMERINFOMATION",
    UPDATESHEBAMILESCUSTOMERINFO: "UPDATESHEBAMILESCUSTOMERINFO",
    UPDATEPAYMENTOPTIONS: "UPDATEPAYMENTOPTIONS",
    UPDATEPAYMENTSUMMARY: "UPDATEPAYMENTSUMMARY",
    SIGNOUT: "SIGNOUT",
    UPDATEPREORDERLIST: "UPDATEPREORDERLIST",
    UPDATEUSERPROFILE: "UPDATEUSERPROFILE",
    SETGUESTPROFILE: "SETGUESTPROFILE"
  };
  