import { store } from "src/redux/store/store";

export function getBundleItems() {
  const state = store.getState();
  const cart = state.cart.cart;
  const selectedItemsInCart = state.cart.selectedItemsInCart;
  const promotionPriceLists = state.batch.offerPriceLists;

  //Check if all items in any bundle are added on cart
  var priceListId = undefined;
  var bundleItemIds = [];
  var selectedBundlePriceList = null;
  if (promotionPriceLists) {
    promotionPriceLists.map((priceLists) => {
      var checkBundleItemFound = true;
      if (
        priceLists.priceListBundleRateRes.length > 0 &&
        priceLists.priceListBundleRateRes[0].priceListBundleItemsRes &&        
        priceLists.priceListBundleRateRes[0].priceListBundleItemsRes.length > 0
      ) {
        priceLists.priceListBundleRateRes[0].priceListBundleItemsRes.map(
          (individualRates) => {
            if (
              !(
                individualRates.itemId in selectedItemsInCart &&
                selectedItemsInCart[individualRates.itemId] &&
                cart[individualRates.itemId].item.selectedPriceListId ===
                priceLists.priceListBundleRateRes[0].priceListId
              )
            ) {
              checkBundleItemFound = false;
              return;
            }
          }
        );
      } else checkBundleItemFound = false;
      if (checkBundleItemFound) {
        priceListId = priceLists.id;
      }
    });
  }
  if (priceListId) {
    const filteredPromotionPriceList = promotionPriceLists.filter(
      (item) => item.id === priceListId
    );
    if (
      filteredPromotionPriceList &&
      filteredPromotionPriceList.length > 0 &&
      filteredPromotionPriceList[0].priceListBundleRateRes.length > 0
    ) {
      selectedBundlePriceList =
        filteredPromotionPriceList[0].priceListBundleRateRes[0];
      filteredPromotionPriceList[0].priceListBundleRateRes[0].priceListBundleItemsRes.map(
        (item) => {
          if (cart[item.itemId].item) {
            bundleItemIds.push(cart[item.itemId].item.id);
          }
        }
      );
    }
  }  
  return {
    selectedBundlePriceList: selectedBundlePriceList,
    bundleItemIds: bundleItemIds,
  };
}




