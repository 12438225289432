import { searchActionType } from "../../actions/search/searchAction";

const initialState = {
  isSearching: false,
  searchString: "",
  searchType: 2,
  searchResult: [],
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchActionType.UPDATESEARCING:
      return {
        ...state,
        isSearching: action.value,
      };
    case searchActionType.UPDATESEARCHSTRING:
      return {
        ...state,
        searchString: action.value,
      };
    case searchActionType.UPDATESEARCHTYPE:
      return {
        ...state,
        searchType: action.value,
      };

    case searchActionType.UPDATESEARCHRESULT:
      return {
        ...state,
        searchResult: action.value,
      };
    default:
      return state;
  }
};

export default searchReducer;
