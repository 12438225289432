export class ShebaMilesLoginValidatorService {
  constructor() {
    this.isUserNameValid = true;
    this.isPasswordValid = true;
    this.shebaMilesLoginValidLists = [];
  }

  validateShebaMilesLoginInfo(ShebaMilesLoginInfo) {
    this.shebaMilesLoginValidLists = [];
    for (let index = 0; index < 2; index++) {
      this.shebaMilesLoginValidLists.push(true);
    }

    this.isUserNameValid = this.validateShebaMilesLoginType(
      ShebaMilesLoginInfo.userName,
      0
    );
    this.isPasswordValid = this.validateShebaMilesLoginType(
      ShebaMilesLoginInfo.password,
      1
    );

    return {
      shebaMilesLoginValidation: this,
      shebaMilesLoginValidLists: this.shebaMilesLoginValidLists,
    };
  }
  validateShebaMilesLoginType(type, index) {
    if (type === "" || type == null) {
      this.shebaMilesLoginValidLists[index] = false;
      return false;
    } else {
      this.shebaMilesLoginValidLists[index] = true;
      return true;
    }
  }
}
