import React from "react";
import { Container } from "react-bootstrap";

import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./ImportantPages.css";

const SpecialPage = (props) => {
    document.title = "Special Offers";

    return (
        <div className="w-100">
            <img src="https://b2cappdevstorageaccount.blob.core.windows.net/dutyfree/oensgrqu.qz4.jpeg" className="img-fluid" loading="lazy" />
            <Container className="text-left">
                <a className="btn-primary-et btn py-2"
                    href="/offers" onClick={(e) => { e.preventDefault(); props.history.push("/offers"); }}>
                    <h4 style={{ paddingTop: "2px" }}>Buy now
                        <FontAwesomeIcon className="ml-2 pt-1" icon={faAngleRight} />
                    </h4>
                </a>
            </Container>
            <Container className="mt-3 text-left">
                <h4>What is Sheba Duty Free?</h4>
                <p>Sheba Duty Free shopping enables passengers to place orders and pay online with convenience of their personal device, it is one of the best ways to have selected duty-free items on their journey.</p>
                <h4>How it works:</h4>
                <ol>
                    <li>Browse our wide: Special Offers </li>
                    <li>Select your convenient item </li>
                    <li>Add selection to your basket and proceed to check-out</li>
                    <li>Register personal information </li>
                    <li>Insert ticket number </li>
                    <li>Select payment option </li>
                    <li>If your payment method is with Credit Card/ miles , securely pay and finalize your order.</li>
                    <li>Get the order number </li>
                    <li>Show the order number for cabin crew onboard and get the item accordingly  </li>
                </ol>

                <a href="/FAQ"><h4>Sheba Duty Free FAQ</h4></a>
                <p className="mb-4"></p>
            </Container>

        </div>
    );
};

export default SpecialPage;
