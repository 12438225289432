import { Button } from "react-bootstrap";
import React, { Component } from "react";
class Exception extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section style={{ minHeight: "60vh", backgroundColor: "#f2f2f2" }}>
        <div style={{ padding: "6rem 0" }}>
          <img src="./exception.jpg" className="img-fluid" alt="exception" loading="lazy" />
          <div>
            <h3>
              <b>SORRY ! SOMETHING WENT WRONG, PLEASE TRY AGAIN.</b>
            </h3>
          </div>
          <div className="mt-4 pt-4">
            <Button className="duty-free-primary-btn" href="/">
              Try again
            </Button>
          </div>
        </div>
      </section>
    );
  }
}

export default Exception;
