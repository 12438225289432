import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import './Account.css';
import { connect } from 'react-redux';

import AccountService from '../../service/account/AccountService';
import { AccountLoginModel } from '../../model/AccountLoginModel';
import { AccountLoginValidatorService } from '../../service/validation/AccountLoginValidatorService.js';
import Loading from '../shared/UI/Loading';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountLoginValidatorState: new AccountLoginValidatorService(), //new accountLoginValidatorService(),
      logging: false,
      requestSubmited: false,
      errorMsg: '',
    };
    this.accountLogin = new AccountLoginModel();
    this.accountLoginValidator = new AccountLoginValidatorService(); //new accountLoginValidatorService();
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    this.setState({
      accountLoginValidatorState: this.state.accountLoginValidatorState.validateAccountLoginInfo(
        this.accountLogin
      ).AccountLoginValidation,
    });
    this.accountLoginValidator = this.state.accountLoginValidatorState.validateAccountLoginInfo(
      this.accountLogin
    ).AccountLoginValidation;

    if (this.accountLoginValidator.isEmailValid) {
      var accountServiceObj = new AccountService();
      this.setState({ logging: true });

      accountServiceObj
        .forgotPassword(this.props.clientToken, this.props.accessToken, this.accountLogin.email)
        .then((response) => {
          if (response.data.message === 'User account does not exist') {
            this.setState({
              logging: false,
              errorMsg: 'User account does not exist',
            });
          } else {
            this.setState({ logging: false, requestSubmited: true });
          }
          // this.props.updateIsSignIn(true);
          // this.props.updateCustomerInfo(response.data.user);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            var errorMessage = '';
            if (error.response.data.errors.Username !== undefined) {
              errorMessage = error.response.data.errors.Username[0];
            }
            if (errorMessage !== '' && error.response.data.errors.Password !== undefined) {
              errorMessage = errorMessage + ', ';
            }
            if (error.response.data.errors.Password !== undefined) {
              errorMessage = errorMessage + error.response.data.errors.Password[0];
            }
            this.setState({ errorMsg: errorMessage });
          }
          if (error.response.status === 500) {
            this.setState({ errorMsg: error.response.data.message });
          }
          console.log(error.response.status, error.response.data.message);
          this.setState({ logging: false });
        });
    }
  };
  onInputKeyDown = (accountLoginValidator) => {
    this.setState({
      accountLoginValidatorState: accountLoginValidator,
      errorMsg: '',
    });
  };
  render() {
    return (
      <section className="light-background-color" style={{ minHeight: '50vh', padding: '2rem 0' }}>
        {this.state.logging ? <Loading /> : null}
        {this.state.requestSubmited ? (
          <Container className="container shebamiles-login-container">
            <Row>
              <Col xs={12} sm={12} md={8} lg={5} xl={4} className="m-auto">
                <Card
                  style={{
                    paddingBottom: '3rem',
                    boxShadow: '0 5px 10px #eee',
                    borderRadius: '10px',
                  }}
                >
                  <br />
                  <br />
                  <FontAwesomeIcon
                    icon={faUnlockAlt}
                    size="3x"
                    className="d-flex align-self-center text-success"
                  />
                  <br />
                  <div className="pb-4">
                    <h3 className="text-center py-3">Forgot Password</h3>
                  </div>
                  <h5>We have seen reset link to you email. Check your Email</h5>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container className="container shebamiles-login-container">
            <Row>
              <Col xs={12} sm={12} md={8} lg={5} xl={4} className="m-auto">
                <Card
                  style={{
                    paddingBottom: '3rem',
                    boxShadow: '0 5px 10px #eee',
                    borderRadius: '10px',
                  }}
                >
                  <br />
                  <br />
                  <FontAwesomeIcon
                    icon={faUnlockAlt}
                    size="3x"
                    className="d-flex align-self-center text-success"
                  />
                  <br />
                  <div className="pb-4">
                    <h3 className="text-center py-3">Forgot Password</h3>
                  </div>

                  <div className="sheba-miles-login mx-4">
                    <Form onSubmit={this.handleFormSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="float-left">
                          Email Address <span>*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={
                            this.state.accountLoginValidatorState.isEmailValid
                              ? ''
                              : 'Email Address field is required...'
                          }
                          onChange={(event) => (this.accountLogin.email = event.target.value)}
                          className={
                            this.state.accountLoginValidatorState.isEmailValid
                              ? 'input-field'
                              : 'invalid-input-field'
                          }
                          onKeyDown={() => {
                            this.accountLoginValidator.isEmailValid = true;
                            this.onInputKeyDown(this.accountLoginValidator);
                          }}
                          required
                        />
                        {this.accountLoginValidator.isEmailValid ? null : (
                          <span className="d-block text-left text-danger dty-fr-input-field-validation-error">
                            {this.accountLoginValidator.errors.email}
                          </span>
                        )}
                        {this.state.errorMsg !== '' ? (
                          <span className="d-block text-left text-danger dty-fr-input-field-validation-error">
                            {this.state.errorMsg}
                          </span>
                        ) : null}
                      </Form.Group>
                      <br />
                      <button
                        // variant="primary"
                        type="submit"
                        className="btn-primary-et btn px-3 w-100 py-2"
                      >
                        Submit
                      </button>
                    </Form>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    cart: state.cart.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
