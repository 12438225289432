import React, { Component } from "react";
// import { Accordion } from "react-bootstrap";
class BrandMenuMob extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleClick(url) {
    window.location = url;
  }
  capitalizeFirstLater = (text) => {
    var arr = [];
    if (text.split(" ").length > 0) {
      arr = text.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
      }
      return arr.join(" ");
    }
    if (text.split("-").length > 0) {
      arr = text.split("-");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
      }
      return arr.join("-");
    }

    return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
  };
  render() {
    return (
      <section>
        <div>
          {this.props.brandList.map((brand, index) => (
            <div
              key={index}
              className="text-left pl-4"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.handleClick(
                  "/product-list-by-brand/" + brand.name + "/" + brand.id
                )
              }
            >
              <span>{this.capitalizeFirstLater(brand.name)}</span>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default BrandMenuMob;
