import SortService from 'src/service/shared/sort/SortService';
import { globalActionType } from '../../actions/shared/globalAction';

const initialState = {
  locale: 'en',
  isLoading: false,
  isMobile: false,
  currentLocation: 'home',
  currency: 'USD',
  currencyList: [],
  isMedium: false,
  isGlobalSessionExpired: false,
  isSearching: false,
  searchString: '',
  searchType: 1,
  airportList: [],
  routeConfigRes: [],
  categoryList: [],
  isConsentAccepted: false,
  brandList: [],
  filteredResult: [],
  sortParams: {
    sortType: SortService.SortOptions.ByPrice,
    orderBy: SortService.orderBy.DESC,
  },
  activeFilterCriteria: {
    brands: [],
    categories: [],
    priceList: [],
  },
  filterCriteria: {
    brands: [],
    categories: [],
    priceList: [],
  },
  initializing: true,
  activePage: 1,
  pageSize: 12,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case globalActionType.UPDATELOCALE:
      return {
        ...state,
        locale: action.value,
      };
    case globalActionType.UPDATEISMOBILE:
      return {
        ...state,
        isMobile: action.value,
      };
    case globalActionType.UPDATEISMEDIUM:
      return {
        ...state,
        isMedium: action.value,
      };
    case globalActionType.TOGGLEISLOADING:
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    case globalActionType.UPDATEISLOADING:
      return {
        ...state,
        isLoading: action.value,
      };
    case globalActionType.UPDATESEARCING:
      return {
        ...state,
        isSearching: action.value,
      };
    case globalActionType.UPDATESEARCHSTRING:
      return {
        ...state,
        searchString: action.value,
      };
    case globalActionType.UPDATESEARCHTYPE:
      return {
        ...state,
        searchType: action.value,
      };
    case globalActionType.UPDATEAIRPORTLIST:
      return {
        ...state,
        airportList: action.value,
      };
    case globalActionType.UPDATEROUTECONFIG:
      return {
        ...state,
        routeConfigRes: action.value,
      };
    case globalActionType.UPDATECURRENCY:
      return {
        ...state,
        currency: action.value,
      };
    case globalActionType.UPDATECURRENCYLIST:
      return {
        ...state,
        currencyList: action.value,
      };
    case globalActionType.UPDATEISCONSENTACCEPTED:
      return {
        ...state,
        isConsentAccepted: action.value,
      };
    case globalActionType.UPDATECATEGORYLIST:
      return {
        ...state,
        categoryList: action.value,
      };
    case globalActionType.SETFILTEREDLIST:
      return {
        ...state,
        filteredResult: action.value,
      };
    case globalActionType.SETSORTPARAM:
      return {
        ...state,
        sortParams: action.value,
      };
    case globalActionType.SETACTIVECRITERIA:
      return {
        ...state,
        activeFilterCriteria: action.value,
      };
    case globalActionType.SETCRITERIA:
      return {
        ...state,
        filterCriteria: action.value,
      };
    case globalActionType.SETBRANDLIST:
      return {
        ...state,
        brandList: action.value,
      };
    case globalActionType.SETACTIVEPAGE:
      return {
        ...state,
        activePage: action.value,
      };
    case globalActionType.SETINITIALIZING:
      return {
        ...state,
        initializing: action.value,
      };
    default:
      return state;
  }
};

export default globalReducer;
