import { Col, Row, Form, Button } from "react-bootstrap";
import "./PromoCodeWidget.css";
import Translation from "i18next";
import { useState } from "react";
import ProductService from "../../service/product service/ProductService";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { batchActionType } from "src/redux/actions/shared/batchAction";

const PromoCodeWidget = (props) => {
  const history = useHistory();
  const [formIsValid, setValidated] = useState(true);
  const [promocode, setPromocode] = useState("");
  const [responseError, setResponseError] = useState();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(false);
    }
    event.preventDefault();
    getPromocodeProducts(promocode);
  };
  const getPromocodeProducts = (promocode) => {
    let productService = new ProductService();
    let payload = {
      PromoCode: promocode,
    };
    productService
      .getProductsByPromocode(props.clientToken, props.accessToken, payload)
      .then((response) => {
        if (response.data.status == 0) {
          setResponseError(
            "Unable to show products associated with your promocode. Kindly confirm the accuracy of the promotion code, please."
          );
        } else {
          props.setPromoProducts(response.data.priceList);
          history.push("/promocode-products");
        }
      })
      .catch((error) => {
        console.log(error);
        setResponseError(
          "Error occured while showing products associated with your promocode. Please try again later!"
        );
      });
  };
  return (
    <section id="services" className="services">
      <div className="container aos-init aos-animate" data-aos="fade-up">
        <div className="section-header">
          <h2>{Translation.t("Have Promotion Code")}</h2>
          <p>            
            {Translation.t("Promotion Code Text")}
          </p>
        </div>

        <Form noValidate onSubmit={handleSubmit}>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <Row>
                <Col sm={4}>
                  <Form.Label column="lg" className="text-center">
                  {Translation.t("Enter Promotion Code")}
                  </Form.Label>
                </Col>
                <Col sm={6}>
                  <input
                    type="text"
                    required
                    value={promocode}
                    onChange={(e) => setPromocode(e.target.value)}
                    className={
                      formIsValid
                        ? "duty-free-input-field-control mb-2"
                        : "duty-free-input-field-control mb-2 invalid-input-field"
                    }
                  />
                  {formIsValid ? null : (
                    <span className="d-block text-left text-danger dty-fr-input-field-validation-error mt-2">
                      {Translation.t("Please provide a valid promo code")}
                    </span>
                  )}

                  {responseError ? (
                    <span className="d-block text-left text-danger dty-fr-input-field-validation-error mt-2">
                      {responseError}
                    </span>
                  ) : null}
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    type="submit"
                    className="float-left et-btn btn-lg py-2"
                  >
                    {Translation.t("submit")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPromoProducts: (promoProducts) =>
      dispatch({
        type: batchActionType.SETPROMOCODEPRODUCTS,
        value: promoProducts,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeWidget);
