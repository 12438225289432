import React, { lazy, Suspense } from 'react';
import ProgressSimpler from '../UI/ProgressSimpler';

import Account from '../../account/Account';
import ShebaMilesLogin from '../../account/ShebaMilesLogin';
import Home from '../../home/Home';
import Product from '../../product/Product';
import ConfirmationSummary from '../../summary/ConfirmationSummary';
import GuestRegistration from '../../account/GuestRegistration';
import ContactUs from '../../important-pages/ContactUs';
import Exception from '../exception/Exception';
import Login from '../../account/Login';
import PaymentFailure from '../../payment/PaymentFailure';
import PaymentCancel from '../../payment/PaymentCancel';
import PaymentPending from '../../payment/PaymentPending';
import PaymentError from '../../payment/PaymentError';
import FeedBack from '../../feed back/FeedBack';
import ForgotPassword from '../../account/ForgotPassword';
import AccountDashboard from '../../account/AccountDashboard';
import ResetPassword from '../../account/ResetPassword';
import BrandsPage from '../../important-pages/BrandsPage';
import AfterSalesSupport from '../../important-pages/AfterSalesSupport ';
import SpecialPage from '../../important-pages/SpecialPage';

const TempPrivacy = lazy(() => import('../../important-pages/PrivacyPolicy'));
const PrivacyPolicy = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempPrivacy />
  </Suspense>
);
const TempAcountRegistration = lazy(() => import('../../account/AcountRegistration'));
const AcountRegistration = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempAcountRegistration />
  </Suspense>
);
const TempGuestCheckOut = lazy(() => import('../../form/GuestCheckOut'));
const GuestCheckOut = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempGuestCheckOut />
  </Suspense>
);
const TempOrders = lazy(() => import('../../account/Orders'));
const Orders = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempOrders />
  </Suspense>
);

const TempPreorderDetail = lazy(() => import('../../account/PreorderDetail'));
const PreorderDetail = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempPreorderDetail />
  </Suspense>
);

const TempPayment = lazy(() => import('../../payment/Payment'));
const Payment = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempPayment />
  </Suspense>
);

const TempAccountDetails = lazy(() => import('../../account/AccountDetails'));
const AccountDetails = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempAccountDetails />
  </Suspense>
);

const TempNewArrivals = lazy(() => import('../../new-arrivals/NewArrivals'));
const NewArrivals = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempNewArrivals />
  </Suspense>
);

const TempOffers = lazy(() => import('../../offers/Offers'));
const Offers = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempOffers />
  </Suspense>
);
const TempPromoCode = lazy(() => import('../../promotions/PromoCode'));
const PromoCode = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempPromoCode />
  </Suspense>
);
const TempSpecialOffers = lazy(() => import('../../offers/SpecialOffers'));
const SpecialOffers = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempSpecialOffers />
  </Suspense>
);
const TempCollections = lazy(() => import('../../offers/Collections'));
const Collections = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempCollections />
  </Suspense>
);
const TempCart = lazy(() => import('../../cart/Cart'));
const Cart = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempCart />
  </Suspense>
);

const TempDutyFreeAllowances = lazy(() => import('../../important-pages/DutyFreeAllowances'));
const DutyFreeAllowances = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempDutyFreeAllowances />
  </Suspense>
);

const TempSearchResult = lazy(() => import('../../search/SearchResult'));
const SearchResult = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempSearchResult />
  </Suspense>
);

const TempWhatIsPreOrder = lazy(() => import('../../important-pages/WhatIsPreOrder'));
const WhatIsPreOrder = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempWhatIsPreOrder />
  </Suspense>
);

const TempFAQ = lazy(() => import('../../important-pages/FAQ'));
const FAQ = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempFAQ />
  </Suspense>
);

const TempHowToOrder = lazy(() => import('../../important-pages/HowToOrder'));
const HowToOrder = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempHowToOrder />
  </Suspense>
);

const TempTermsAndConditions = lazy(() => import('../../important-pages/TermsAndConditions'));
const TermsAndConditions = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempTermsAndConditions />
  </Suspense>
);

const TempProductList = lazy(() => import('../../product-list/ProductList'));
const ProductList = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempProductList />
  </Suspense>
);

const TempBrandList = lazy(() => import('../../brand-list/BrandList'));
const BrandList = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempBrandList />
  </Suspense>
);

const TempTravelExclusives = lazy(() => import('../../travel-exclusives/TravelExclusives'));
const TravelExclusives = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempTravelExclusives />
  </Suspense>
);

const TempBestSellers = lazy(() => import('../../best-sellers/BestSellers'));
const BestSellers = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempBestSellers />
  </Suspense>
);

const TempDeals = lazy(() => import('../../deals/Deals'));
const Deals = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempDeals />
  </Suspense>
);

const TempOrderTracking = lazy(() => import('src/component/order-tracking/OrderTracking'));
const OrderTracking = () => (
  <Suspense fallback={<ProgressSimpler />}>
    <TempOrderTracking />
  </Suspense>
);

const RouteList = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/cart',
    component: Cart,
  },
  {
    path: '/account',
    component: Account,
  },
  {
    path: '/shebamiles/login',
    component: ShebaMilesLogin,
  },
  {
    path: '/new-arrivals',
    component: NewArrivals,
  },
  {
    path: '/promotions',
    component: Offers,
  },
  {
    path: '/special-offers',
    component: SpecialOffers,
  },
  {
    path: '/collections/:id',
    component: Collections,
  },
  {
    path: '/promocode-products/:promocode?',
    component: PromoCode,
  },
  {
    path: '/checkout',
    component: Payment,
  },
  {
    path: '/guest-reg',
    component: GuestRegistration,
  },
  {
    path: '/product-detail/:id',
    component: Product,
  },
  {
    path: '/contact-us',
    component: ContactUs,
  },
  {
    path: '/what-is-preorder',
    component: WhatIsPreOrder,
  },
  {
    path: '/FAQ',
    component: FAQ,
  },
  {
    path: '/how-to-order',
    component: HowToOrder,
  },
  {
    path: '/SearchResult',
    component: SearchResult,
  },
  {
    path: '/terms-and-conditions',
    component: TermsAndConditions,
  },
  {
    path: '/product-list/:cat/:id',
    component: ProductList,
  },
  {
    path: '/product-list/:cat/:id',
    component: ProductList,
  },
  {
    path: '/product-list-by-brand/:cat/:id',
    component: BrandList,
  },
  {
    path: '/exception',
    component: Exception,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/registration',
    component: AcountRegistration,
  },  
  {
    path: '/guest-checkout',
    component: GuestCheckOut,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/confirmation',
    component: ConfirmationSummary,
  },
  {
    path: '/payment-failure',
    component: PaymentFailure,
  },
  {
    path: '/payment-cancel',
    component: PaymentCancel,
  },
  {
    path: '/payment-pending',
    component: PaymentPending,
  },
  {
    path: '/payment-error',
    component: PaymentError,
  },
  {
    path: '/feed-back',
    component: FeedBack,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/account/dashboard',
    component: AccountDashboard,
  },
  {
    path: '/account/orders',
    component: Orders,
  },
  {
    path: '/account/orders/:id',
    component: PreorderDetail,
  },
  {
    path: '/account/account-details',
    component: AccountDetails,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/duty-free-allowances',
    component: DutyFreeAllowances,
  },
  {
    path: '/brands',
    component: BrandsPage,
  },
  {
    path: '/after-sales-support',
    component: AfterSalesSupport,
  },
  {
    path: '/Special',
    component: SpecialPage,
  },
  {
    path: '/travel-exclusives',
    component: TravelExclusives,
  },
  {
    path: '/best-sellers',
    component: BestSellers,
  },
  {
    path: '/deals',
    component: Deals,
  },
  {
    path: '/order-tracking',
    component: OrderTracking,
  },
];

export default RouteList;
