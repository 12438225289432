import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookF,
    faTwitter,
    faLinkedinIn,
    faYoutube,
    faInstagram,
    faWeibo,
} from '@fortawesome/free-brands-svg-icons';

import './Footer.css';
import Translation from 'i18next';
import PreOrderService from '../../../service/preOrder/PreOrderService';
import Toast from 'react-bootstrap/Toast';

const Footer = (props) => {
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [isToastOpened, setIsToastOpened] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [responseMassage, setResponseMassage] = useState('');

    const subscribeToSpecialOffer = () => {
        setIsSubmiting(true);
        var preOrderService = new PreOrderService();
        var payload = { email: emailAddress };
        preOrderService
            .getSubscribetoMail(props.clientToken, props.accessToken, payload)
            .then((response) => {
                setResponseMassage(response.data.message);
                setIsToastOpened(true);
                setIsSubmiting(false);
                setEmailAddress('');
            })
            .catch((error) => {
                console.error(error.response.data.message);
                setIsSubmiting(false);
            });
    };

    return (
        <div className="footer">
            <Toast
                className="dty-free-toast-container"
                onClose={() => setIsToastOpened(false)}
                show={isToastOpened}
                bg={'success'}
                delay={5000}
                autohide
            >
                <Toast.Header>
                    <p className="py-3 duty-free-unselectable-text h6">{responseMassage}</p>
                </Toast.Header>
            </Toast>
            <Container className="text-left footer-sub-container">
                <Row className="footer-links-container">
                    <Col md={6} lg={3} sm={12}>
                        <h4>{Translation.t('shopping at EDF')}</h4>
                        <ul className="list-unstyled">
                            <li>
                                <a href="/what-is-preorder">{Translation.t('what is pre-order')}</a>
                            </li>

                            <li>
                                <a href="/how-to-order">{Translation.t('how to order')}</a>
                            </li>
                            <li>
                                <a href="/duty-free-allowances">{Translation.t('duty free allowances')}</a>
                            </li>
                            <li>
                                <a href="https://www.paperturn-view.com/us/sheba-duty-free-magazine/duty-free-magazine-july-2024?pid=ODg8816511&v=3" target="_blank" rel="noreferrer">
                                    {Translation.t('sheba guide')}
                                </a>
                            </li>
                            <li>
                                <a href="/order-tracking">{Translation.t('order tracking')}</a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={6} lg={3} sm={12}>
                        <h4>{Translation.t('customer care')}</h4>
                        <ul className="list-unstyled">
                            <li>
                                <a href="/contact-us">{Translation.t('contact us')}</a>
                            </li>
                            <li>
                                <a href="/terms-and-conditions">{Translation.t('terms and conditions')}</a>
                            </li>
                            <li>
                                <a href="/privacy-policy">{Translation.t('privacy policy')}</a>
                            </li>
                            <li>
                                <a href="/after-sales-support">{Translation.t('after sales support')}</a>
                            </li>
                            <li>
                                <a href="/FAQ">{Translation.t('faqs')}</a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={6} lg={3} sm={12}>
                        <h4>{Translation.t('ethiopian group')}</h4>
                        <ul className="list-unstyled">
                            <li>
                                <a href="https://www.ethiopianairlines.com" target="_blank" rel="noreferrer">
                                    {Translation.t('ethiopian airlines')}
                                </a>
                            </li>
                            {/* <li>
                <a
                  href="http://www.ethiopian-airports.com/#xd_co_f=ZDUyNjUzNWQtNzE0ZS00MGM1LWI1NDQtMzA5MWQyMGJmOGZh~"
                  target="_blank"
                  rel="noreferrer"
                >
                  {Translation.t('ethiopian airports')}
                </a>
              </li> */}
                            <li>
                                <a href="https://www.ethiopianskylighthotel.com" target="_blank" rel="noreferrer">
                                    {Translation.t('ethiopian skylight hotel')}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://corporate.ethiopianairlines.com/catering"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {Translation.t('in-flight catering')}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://corporate.ethiopianairlines.com/ground-service"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {Translation.t('ground services')}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://corporate.ethiopianairlines.com/mro"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {Translation.t('maintenance, repair and overhaul')}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://corporate.ethiopianairlines.com/eaa"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {Translation.t('aviation university')}
                                </a>
                            </li>
                            <li>
                                <a href="https://cargo.ethiopianairlines.com/" target="_blank" rel="noreferrer">
                                    {Translation.t('cargo')}
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={6} lg={3} sm={12}>
                        <h4 className="mb-3">{Translation.t('payment options')}</h4>
                        <Row className="mx-0 mb-3">
                            <Col xs={4} className="pl-0 mb-3">
                                <img
                                    className="img-fluid"
                                    loading="lazy"
                                    width="68"
                                    height="42"
                                    alt="american express"
                                    src={process.env.PUBLIC_URL + '/icons/american express-min.png'}
                                />
                            </Col>
                            <Col xs={4} className="pl-0">
                                <img
                                    className="img-fluid"
                                    loading="lazy"
                                    width="68"
                                    height="42"
                                    alt="Diners club international"
                                    src={process.env.PUBLIC_URL + '/icons/diners club1-min.png'}
                                />
                            </Col>
                            <Col xs={4} className="pl-0">
                                <img
                                    className="img-fluid"
                                    loading="lazy"
                                    width="68"
                                    height="42"
                                    alt="MasterCard"
                                    src={process.env.PUBLIC_URL + '/icons/mastercard-min.png'}
                                />
                            </Col>
                            <Col xs={4} className="pl-0">
                                <img
                                    className="img-fluid"
                                    loading="lazy"
                                    width="68"
                                    height="42"
                                    alt="Visa"
                                    src={process.env.PUBLIC_URL + '/icons/visa_icon-min.png'}
                                />
                            </Col>
                            <Col xs={4} className="pl-0">
                                <img
                                    className="img-fluid"
                                    loading="lazy"
                                    width="68"
                                    height="42"
                                    alt="MasterPass"
                                    src={process.env.PUBLIC_URL + '/icons/masterpass1-min.png'}
                                />
                            </Col>
                            <Col xs={4} className="pl-0">
                                <img
                                    className="img-fluid"
                                    loading="lazy"
                                    width="68"
                                    height="42"
                                    alt="Union Pay"
                                    src={process.env.PUBLIC_URL + '/icons/union_pay-min.png'}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="text-left footer-sub-container">
                <Row className="footer-links-container justify-content-center">
                    <Col md={6} lg={4} sm={12} className="my-4">
                        <h4>{Translation.t('subscribe to our special offers')}</h4>
                        <Form
                            className="row mx-0 my-3"
                            onSubmit={(e) => {
                                e.preventDefault();
                                subscribeToSpecialOffer();
                            }}
                        >
                            <Col xs={12} md={7} lg={8} className="mb-3 pl-0">
                                <input
                                    type="email"
                                    placeholder={Translation.t('enter your email address')}
                                    onChange={(e) => setEmailAddress(e.target.value)}
                                    value={emailAddress}
                                    className="duty-free-input-field-control w-100"
                                    required
                                />
                            </Col>
                            <Col xs={12} md={5} lg={4} className="pl-0 mb-3">
                                <button
                                    disabled={isSubmiting}
                                    type="submit"
                                    className="duty-free-outline-primary-btn btn btn-primary w-100"
                                    style={{ padding: '0.75em' }}
                                >
                                    {isSubmiting ? (
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        Translation.t('subscribe')
                                    )}
                                </button>
                            </Col>
                        </Form>
                    </Col>
                    <Col md={6} lg={4} sm={12} className="my-4 justify-content-md-center d-flex">
                        <div>
                            <h4>{Translation.t('social media')}</h4>
                            <p className="mb-3">{Translation.t('connect with us on social media')}</p>
                            <a
                                href="https://www.facebook.com/EthiopianAirlines.GB"
                                className="social-links-container fa-facebook"
                                style={{ marginLeft: 0 }}
                                title="Facebook"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={faFacebookF} className="social-links-fontawsome" />
                            </a>
                            <a
                                href="https://twitter.com/flyethiopian"
                                className="social-links-container fa-twitter"
                                title="Twitter"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={faTwitter} className="social-links-fontawsome" />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/ethiopian-airlines/"
                                className="social-links-container fa-linkedin"
                                title="LinkedIn"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={faLinkedinIn} className="social-links-fontawsome" />
                            </a>
                            <a
                                href="https://www.youtube.com/@ethiopianairlinesdigitalam2056"
                                className="social-links-container fa-youtube"
                                title="Youtube"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={faYoutube} className="social-links-fontawsome" />
                            </a>
                            <a
                                href="https://www.instagram.com/fly.ethiopian/"
                                className="social-links-container fa-instagram"
                                title="Instagram"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={faInstagram} className="social-links-fontawsome" />
                            </a>
                            <a
                                href="https://weibo.com/ethiopianairlinesCN"
                                className="social-links-container fa-weibo"
                                title="Weibo"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={faWeibo} className="social-links-fontawsome" />
                            </a>
                        </div>
                    </Col>
                    <Col md={6} lg={4} sm={12} className="d-flex align-items-center">
                        <Row className="mx-0">
                            <Col xs={5} className="px-0">
                                <img
                                    className="img-fluid"
                                    loading="lazy"
                                    alt="Ethiopian airlines logo"
                                    width="174"
                                    height="65.5"
                                    src={process.env.PUBLIC_URL + '/icons/et-logo-min.png'}
                                />
                            </Col>
                            <Col xs={7} className="px-0">
                                <img
                                    className="img-fluid"
                                    loading="lazy"
                                    alt="star alliance logo"
                                    width="243"
                                    height="53"
                                    src={process.env.PUBLIC_URL + '/icons/staralliance-logo-min.png'}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="py-3 footer-end">
                <p className="mb-0">&copy; {new Date().getFullYear()} - Sheba Duty Free</p>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isMobile: state.global.isMobile,
        locale: state.global.locale,
        clientToken: state.authClient.clientToken,
        accessToken: state.authAccess.accessToken,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
