import React from "react";
// import { Container } from "react-bootstrap";
import { connect } from "react-redux";

import "./Consent.css";
import {  faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { globalActionType } from "../../../redux/actions/shared/globalAction";
import CookieService from "../../../service/shared/CookieService";

// import { cartActionType } from "../../redux/actions/cart/cartAction";

const Consent = (props) => {
  const cookieService = new CookieService();
  //   document.title = "Payment Error";

  return (
    <div className="consent-container">
      <div className="row py-3 mx-0">
        <div className="col-12 col-sm-9 col-lg-10">
          <p className="gdpr-consetn-container-p cookie-text-size">
            Cookies help us to improve your user experience on Ethiopian Duty
            Free websites. We use cookies to analyze our traffic personalize
            content, ads, and to provide social media features. navigate to our
            privacy policy for more detailed description <a rel="noreferrer" target="_blank" href="/privacy-policy">Here <FontAwesomeIcon icon={faExternalLinkAlt}/> </a> .
          </p>
        </div>
        <div className="row-fl row mx-auto col-12 col-sm-3  col-lg-2 d-flex align-items-center justify-content-around">
          <button
            className="cookie-text-size cookie-btn cookie-btn-active"
            type="button"
            onClick={() => {
              props.updateIsConsentAccepted(true);
              cookieService.setCookie("_consent", true);
            }}
          >
            Accept Cookies
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isConsentAccepted: state.global.isConsentAccepted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateIsConsentAccepted: (value) =>
      dispatch({
        type: globalActionType.UPDATEISCONSENTACCEPTED,
        value: value,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Consent);
