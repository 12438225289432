import {
  faChevronLeft,
  // faUserAlt,
  // faUserLock,
  // faUserNinja,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { accountActionType } from '../../redux/actions/auth/accountAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { ShebaMilesAccountLogin } from '../../model/account/ShebaMilesAccountLogin';
import { ShebaMilesLoginValidatorService } from '../../service/validation/ShebaMilesLoginValidatorService';
import AccountService from '../../service/account/AccountService';
import Loading from '../shared/UI/Loading';
import { Redirect } from 'react-router-dom';
import Translation from 'i18next';

import './Account.css';

class ShebaMilesLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: '',
      redirectToRegistration: false,
      redirectToCheckout: false,
      shebaMilesLoginValidatorState: new ShebaMilesLoginValidatorService(),
    };
    this.shebaMilesLogin = new ShebaMilesAccountLogin();
    this.shebaMilesLoginValidator = new ShebaMilesLoginValidatorService();
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    this.setState({
      shebaMilesLoginValidatorState:
        this.state.shebaMilesLoginValidatorState.validateShebaMilesLoginInfo(this.shebaMilesLogin)
          .shebaMilesLoginValidation,
    });
    this.shebaMilesLoginValidator =
      this.state.shebaMilesLoginValidatorState.validateShebaMilesLoginInfo(
        this.shebaMilesLogin
      ).shebaMilesLoginValidation;
    var tempValidForm = true;
    for (let i = 0; i < this.shebaMilesLoginValidator.shebaMilesLoginValidLists.length; i++) {
      if (!this.shebaMilesLoginValidator.shebaMilesLoginValidLists[i]) {
        tempValidForm = false;
        break;
      }
    }

    if (tempValidForm) {
      var accountServiceObj = new AccountService();
      this.setState({ logging: true });

      accountServiceObj
        .loginWithShebaMiles(this.props.clientToken, this.props.accessToken, {
          MemberID: this.shebaMilesLogin.userName,
          Password: this.shebaMilesLogin.password,
        })
        .then((response) => {
          // this.props.updateIsSignIn(true);
          this.props.updatePaymentOptions(response.data.paymentOptions);
          this.props.updateShebaMilesCustomerInfo(response.data);
          if (response.data.customerAlreadyRegistered) {
            this.props.updateIsSignIn(true);
            var tempUserData = {
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              userId: response.data.customerId,
              username: response.data.email,
              accessToken: response.data.accessToken,
            };
            let tempShebaUserObj = {
              user: tempUserData,
              accessToken: response.data.token
          };
            this.props.updateCustomerInfo(tempShebaUserObj);
            this.setState({ logging: false, redirectToCheckout: true });
          } else {
            this.setState({ logging: false, redirectToRegistration: true });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            var errorMessage = '';
            if (error.response.data.errors.Username !== undefined) {
              errorMessage = error.response.data.errors.Username[0];
            }
            if (errorMessage !== '' && error.response.data.errors.Password !== undefined) {
              errorMessage = errorMessage + ', ';
            }
            if (error.response.data.errors.Password !== undefined) {
              errorMessage = errorMessage + error.response.data.errors.Password[0];
            }
            this.setState({ errorMsg: errorMessage });
          }
          if (error.response.status === 500) {
            this.setState({ errorMsg: error.response.data.message });
          }
          console.log(error.response.status, error.response.data.message);
          this.setState({ logging: false });
        });
    }
  };
  onInputKeyDown = (shebaMilesLoginValidator) => {
    this.setState({ shebaMilesLoginValidatorState: shebaMilesLoginValidator });
  };

  accountService = new AccountService();

  render() {
    if (this.state.redirectToCheckout) {
      return (
        <Redirect
          to={{
            pathname: this.accountService.getRedirectUrl(this.props.location.search),
          }}
        />
      );
    }
    if (this.state.redirectToRegistration) {
      return (
        <Redirect
          to={{
            pathname: '/registration',
          }}
        />
      );
    }
    return (
      <section className="light-background-color" style={{ minHeight: '50vh', padding: '2rem 0' }}>
        {this.state.logging ? <Loading /> : null}
        <Container className="container shebamiles-login-container">
          <Row>
            <Col xs={12} sm={12} md={8} lg={5} xl={4} className="m-auto">
              <Card
                style={{
                  paddingBottom: '3rem',
                  boxShadow: '0 5px 10px #eee',
                  borderRadius: '10px',
                }}
              >
                <br />
                <br />
                <div className="pb-4">
                  <img
                    src={process.env.PUBLIC_URL + '/images/sheba-miles.jpg'}
                    alt="sheba miles logo"
                    loading="lazy"
                  />
                </div>

                <div className="sheba-miles-login mx-4">
                  <Form onSubmit={this.handleFormSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="float-left">
                        {Translation.t('shebamiles membership number')} <span>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={
                          this.state.shebaMilesLoginValidatorState.isUserNameValid
                            ? ''
                            : Translation.t('membership number field is required')
                        }
                        onChange={(event) => (this.shebaMilesLogin.userName = event.target.value)}
                        className={
                          this.state.shebaMilesLoginValidatorState.isUserNameValid
                            ? 'input-field'
                            : 'invalid-input-field'
                        }
                        onKeyDown={() => {
                          this.shebaMilesLoginValidator.isUserNameValid = true;
                          this.onInputKeyDown(this.shebaMilesLoginValidator);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-5" controlId="formBasicPassword">
                      <Form.Label className="float-left">
                        {Translation.t('password')} <span>*</span>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={
                          this.state.shebaMilesLoginValidatorState.isPasswordValid
                            ? ''
                            : Translation.t('password field is required')
                        }
                        onChange={(event) => (this.shebaMilesLogin.password = event.target.value)}
                        className={
                          this.state.shebaMilesLoginValidatorState.isPasswordValid
                            ? 'input-field'
                            : 'invalid-input-field'
                        }
                        onKeyDown={() => {
                          this.shebaMilesLoginValidator.isPasswordValid = true;
                          this.onInputKeyDown(this.shebaMilesLoginValidator);
                        }}
                      />
                    </Form.Group>
                    {this.state.errorMsg !== '' ? (
                      <span className="d-block text-left mb-3 text-danger dty-fr-input-field-validation-error">
                        {this.state.errorMsg}
                      </span>
                    ) : null}
                    <Button className="float-left duty-free-outline-primary-btn" href="/account">
                      <FontAwesomeIcon icon={faChevronLeft} />
                      <span className="px-2">{Translation.t('back')}</span>
                    </Button>
                    <button
                      // variant="primary"
                      type="submit"
                      className="float-right btn-primary-et btn px-3  py-2"
                    >
                      {Translation.t('continue')}
                    </button>
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    cart: state.cart.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateIsSignIn: (value) =>
      dispatch({
        type: accountActionType.UPDATEISSIGNIN,
        value: value,
      }),
    updateCustomerInfo: (value) =>
      dispatch({
        type: accountActionType.UPDATECUSTOMERINFOMATION,
        value: value,
      }),
    updateShebaMilesCustomerInfo: (value) =>
      dispatch({
        type: accountActionType.UPDATESHEBAMILESCUSTOMERINFO,
        value: value,
      }),
    updatePaymentOptions: (value) =>
      dispatch({
        type: accountActionType.UPDATEPAYMENTOPTIONS,
        value: value,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ShebaMilesLogin);
