import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
// import { BrowserRouter, Link } from "react-router-dom";

import ProgressSimpler from '../../UI/ProgressSimpler';
import Translation from 'i18next';

// import SubMenu from "./SubMenu";
class BrandMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubMenu: false,
    };
  }
  handleClick(url) {
    window.location = url;
  }
  capitalizeFirstLater = (text) => {
    var arr = [];
    if (text.split(' ').length > 0) {
      arr = text.split(' ');
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
      }
      return arr.join(' ');
    }
    if (text.split('-').length > 0) {
      arr = text.split('-');
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
      }
      return arr.join('-');
    }

    return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
  };
  render() {
    return (
      <div
        className="mega-menu custom-mega-menu-container  dropdown-menu brand-mega-menu"
        style={{
          display: this.props.showMenu ? 'block' : 'block',
          textAlign: this.props.isLoading ? 'center' : 'none',
          maxWidth: '1140px',
        }}
      >
        {this.props.isLoading && !this.props.brandList ? (
          <ProgressSimpler />
        ) : (
          <div className="menu-item-container">
            <div className="menu-item-list p-4 row mx-0">
              <Row className="popular-brands-container col-12 mx-0">
                {JSON.parse(JSON.stringify(this.props.brandList))
                  .filter((element) => element.isPowerFullBrand)
                  .slice(0, 21)
                  .map((brand, index) => {
                    return (
                      <div key={index} className="nav-menu-brand-container">
                        <a
                          href={'/product-list-by-brand/' + brand.name + '/' + brand.id}
                          onClick={() =>
                            this.handleClick(
                              '/product-list-by-brand/' + brand.name + '/' + brand.id
                            )
                          }
                          className=""
                          style={{ minWidth: '95px', maxWidth: '95px' }}
                          key={'polular-brands-' + index}
                        >
                          <img className="w-100" src={brand.brandImageURL} loading="lazy" />
                        </a>
                      </div>
                    );
                  })}
                <div className="d-flex align-items-end">
                  <a
                    className="duty-free-outline-primary-btn btn btn-primary"
                    href="/brands"
                    onClick={(event) => {
                      event.preventDefault();
                      this.props.history.push('/brands');
                    }}
                  >
                    {Translation.t('view more')}
                  </a>
                </div>
              </Row>
              {/* <Col xs={4} className="popular-brand-side-container py-4">
                <h6 className="mb-3 text-left">{Translation.t("our popular brands")}</h6>
                <ul className="list-group row mx-0 d-block">
                  {JSON.parse(JSON.stringify(this.props.brandList))
                    .filter((element) => element.isPowerFullBrand).slice(0, 9)
                    .map((brand, index) => (
                      <li
                        className="list-group-item col-12"
                        key={index}
                        style={{backgroundColor : "#ccc"}}
                      >
                        <a
                          onClick={() =>
                            this.handleClick(
                              "/product-list-by-brand/" +
                              brand.name +
                              "/" +
                              brand.id
                            )
                          }
                          href={
                            "/product-list-by-brand/" +
                            brand.name +
                            "/" +
                            brand.id
                          }
                          className="product-cat"
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                        >
                          {this.capitalizeFirstLater(brand.name)}
                        </a>
                      </li>
                    ))}
                </ul>
                <a
                  className="float-right duty-free-outline-primary-btn btn btn-primary"
                  href="/brands"
                  onClick={(event) => {
                    event.preventDefault();
                    this.props.history.push("/brands");
                  }}
                >
                  {Translation.t("view more")}
                </a>
              </Col> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BrandMenu;
