export const cartActionType = {
  UPDATECARTAFTERCURRENCYCHANGE: 'UPDATECARTAFTERCURRENCYCHANGE',
  UPDATEQUANTITY: 'UPDATEQUANTITY',
  REMOVEFITEMFROMCART: 'REMOVEFITEMFROMCART',
  SWAPPCART: 'SWAPPCART',
  UPDATESPECIALDISCOUNT: 'UPDATESPECIALDISCOUNT',
  DELETESPECIALOFER: 'DELETESPECIALOFER',
  DELETEBUYGETOFFER: 'DELETEBUYGETOFFER',
  UPDATESELECTEDITEM: 'UPDATESELECTEDITEM',
  ADDTOCART: 'ADDTOCART',
};
