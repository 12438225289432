import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
// import Banner from "./component/home/banner/Banner";
import Header from './component/shared/header/Header';
// import logo from "./logo.svg";
import './App.css';
import { connect } from 'react-redux';
import RouteList from './component/shared/route/RouteList';

import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';
import Footer from './component/shared/footer/Footer';
import { accessTokenActionType } from './redux/actions/auth/accessTokenAction';
import { clientTokenActionType } from './redux/actions/auth/clientTokenAction';
import { Component } from 'react';

import HttpService from './service/shared/HttpService';
// import ProgressLoader from "./component/shared/UI/ProgressLoader";
import ProgressSimpler from './component/shared/UI/ProgressSimpler';
import Consent from './component/shared/consent/Consent';
import CookieService from './service/shared/CookieService';
import { globalActionType } from './redux/actions/shared/globalAction';

import batchService from './service/shared/batch/batchService';
import { batchActionType } from './redux/actions/shared/batchAction';
import './service/shared/multi-lingual/MultiLingual';
import Translation from 'i18next';
import ProductListEmpty from './component/product-list/ProductListEmpty';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.cookieService = new CookieService();
  }
  generateToken = () => {
    return HttpService.getTokenServive()
      .then((response) => {
        this.props.saveClientToken(response.data.clientAccessToken);
        this.props.saveAccessToken(response.data.accessToken);
        //set base url
        var batchServiceObj;
        HttpService.baseUrl = response.data.baseUrl;
        // all currenciess
        // getPreOrderItems = () => {
        if (this.props.onBoardingStatus) {
          batchServiceObj = new batchService();
          batchServiceObj
            .getOnBoardingItems(
              response.data.clientAccessToken,
              response.data.accessToken,
              this.props.onBoardingDetail
            )
            .then((response) => {
              this.props.saveBatch(response.data);
              this.props.updatebatchIsLoading();
            });
        } else {
          batchServiceObj = new batchService();
          batchServiceObj
            .getPreOrderItems(response.data.clientAccessToken, response.data.accessToken)
            .then((response) => {
              this.props.saveBatch(response.data);
              this.props.updatebatchIsLoading();
            })
            .catch(() => {
              this.props.updatebatchIsLoading();
            });
        }

        // }
        //getting promotion price lists        
        batchServiceObj = new batchService();
        batchServiceObj
          .getProductPriceList(response.data.clientAccessToken, response.data.accessToken,
            {
              offerType: 1,
            }
          )
          .then((response) => {
            this.props.setOfferPriceLists(response.data.priceLists);
          })
          .catch((error) => {});

        HttpService.getServiceGeneralPurpose(
          '/MasterData/api/V1.0/Currency/GetAll',
          response.data.clientAccessToken,
          response.data.accessToken,
          {},
          'PreOrder-Access'
        )
          .then((currencyRes) => {
            this.props.updateCurrencyList(currencyRes.data.currenciesRes);
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            console.log(error);
          });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.props.history.push('/exception');
        this.setState({ isLoading: false });
        console.log(error);
      });
  };
  async componentDidMount() {
    await this.generateToken();
  }

  render() {
    return (
      <div className="App">
        {!this.state.isLoading ? (
          <div>
            <a
              href="/feed-back"
              className="btn-feed-back btn-outline-radius-et btn d-none d-lg-block"
              aria-label="Feedback"
              style={{ width: '122px' }}
            >
              {Translation.t('feedback')}
            </a>
            <BrowserRouter>
              <Header />
              <div className="body">
                <Switch>
                  {RouteList.map((route, i) => (
                    <Route
                      path={route.path}
                      key={i}
                      exact
                      render={(props) => {
                        return <route.component {...props} />;
                      }}
                    />
                  ))}
                  <Route>
                    <ProductListEmpty message={'Page could not be found!'} showBackButton={true} />
                  </Route>
                </Switch>
              </div>
            </BrowserRouter>

            <Footer />
          </div>
        ) : (
          <ProgressSimpler />
        )}
        {this.cookieService.getCookie('_consent') || this.props.isConsentAccepted ? null : (
          <Consent />
        )}
        {/* {this.props.isConsentAccepted ? null : <Consent />} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isConsentAccepted: state.global.isConsentAccepted,
    locale: state.global.locale,
    onBoardingStatus: state.batch.onBoardingStatus,
    onBoardingDetail: state.batch.onBoardingDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveClientToken: (token) =>
      dispatch({
        type: clientTokenActionType.SAVECLIENTTOKEN,
        token: token,
      }),
    saveAccessToken: (token) =>
      dispatch({
        type: accessTokenActionType.SAVEACCESSTOKEN,
        token: token,
      }),
    updateCurrencyList: (value) =>
      dispatch({
        type: globalActionType.UPDATECURRENCYLIST,
        value: value,
      }),
    saveBatch: (value) =>
      dispatch({
        type: batchActionType.UPDATEBATCH,
        values: value,
      }),
    updatebatchIsLoading: () =>
      dispatch({
        type: batchActionType.UPDATEBATCHISLOADING,
      }),
      setOfferPriceLists: (value) =>
      dispatch({
        type: batchActionType.SETOFFERPRICELISTS,
        value: value,
      }),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
