export class AccountLoginValidatorService {
  constructor() {
    this.isEmailValid = true;
    this.isPasswordValid = true;
    this.accountLoginValidLists = [];
    this.errors = { email: "", password: "" };
  }

  validateAccountLoginInfo(AccountLoginInfo) {
    this.accountLoginValidLists = [];
    for (let index = 0; index < 2; index++) {
      this.accountLoginValidLists.push(true);
    }

    this.isEmailValid = this.validateEmail(AccountLoginInfo.email, 0);
    this.isPasswordValid = this.validatePassword(AccountLoginInfo.password, 1);

    return {
      AccountLoginValidation: this,
      AccountLoginValidLists: this.accountLoginValidLists,
    };
  }
  validateAccountLoginType(type, index) {
    if (type === "" || type == null) {
      this.accountLoginValidLists[index] = false;
      return false;
    } else {
      this.accountLoginValidLists[index] = true;
      return true;
    }
  }

  validatePassword(type, index) {
    if (type === "" || type == null) {
      this.accountLoginValidLists[index] = false;
      this.errors.password = "Password field is required.";
      return false;
    } else {
      this.accountLoginValidLists[index] = true;
      return true;
    }
  }
  validateEmail(type, index) {
    // var mailformat =
      // /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (type === "" || type == null) {
      this.accountLoginValidLists[index] = false;
      this.errors.email = "Username field is required.";
      return false;
    } 
    // else if (!mailformat.test(type)) {
    //   this.accountLoginValidLists[index] = false;
    //   this.errors.email = "Email address format is wrong";
    //   return false;
    // }
     else {
      this.accountLoginValidLists[index] = true;
      return true;
    }
  }
}
