import React from 'react';
import { Accordion } from 'react-bootstrap';
import { capitalizeFirstLater } from 'src/service/shared/utilits';
const MobMenu = (props) => {
  const handleClick = (url) => {
    window.location = url;
  };

  return (
    <section>
      <Accordion flush>
        {props.categoryList.map((category, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>{category.name}</Accordion.Header>
            {category.children.map((subCat, index2) => (
              <Accordion.Body
                className="text-left"
                key={index2}
                style={{ cursor: 'pointer', borderBottom: '1px solid #eee' }}
                onClick={() => handleClick('/product-list/' + subCat.name + '/' + subCat.id)}
              >
                {capitalizeFirstLater(subCat.name)}
              </Accordion.Body>
            ))}
          </Accordion.Item>
        ))}
      </Accordion>
    </section>
  );
};

export default MobMenu;
