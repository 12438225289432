export class ResetPasswordValidator {
  constructor() {
    this.isPasswordValid = true;
    this.isConfirmPasswordValid = true;
    this.resetPasswordValidLists = [];
    this.errors = {
      password: "",
      confirmPassword: "",
    };
  }

  validateResetPasswordInfo(resetPasswordInof) {
    this.resetPasswordValidLists = [];
    for (let index = 0; index < 2; index++) {
      this.resetPasswordValidLists.push(true);
    }

    this.isPasswordValid = this.validateResetPasswordTypePassword(
      resetPasswordInof.password,
      0,
      "Password",
      "password"
    );
    this.isConfirmPasswordValid = this.validateResetPasswordTypePassword(
      resetPasswordInof.confirmPassword,
      1,
      "Confirm Password",
      "confirmPassword",
      resetPasswordInof.password
    );

    return {
      resetPasswordValidation: this,
      resetPasswordValidLists: this.resetPasswordValidLists,
    };
  }

  validateResetPasswordTypePassword(type, index, title, errorCode, password) {
    if (type === "" || type == null) {
      this.resetPasswordValidLists[index] = false;
      this.errors[errorCode] = title + " is required";
      return false;
    } else {
      if (index === 0) {
        if (type.length < 8) {
          this.resetPasswordValidLists[index] = false;
          this.errors.password =
            "Password character length should be greater than 8.";
          return false;
        }
      }
      if (index === 1) {
        if (password !== type) {
          this.resetPasswordValidLists[index] = false;
          this.errors.confirmPassword = "Confirm password does not match.";
          return false;
        }
      }
      this.resetPasswordValidLists[index] = true;
      return true;
    }
  }
}
