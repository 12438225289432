export const globalActionType = {
  UPDATELOCALE: 'UPDATELOCALE',
  UPDATEISMOBILE: 'UPDATEISMOBILE',
  TOGGLEISLOADING: 'TOGGLEISLOADING',
  UPDATEISLOADING: 'UPDATEISLOADING',
  UPDATEISMEDIUM: 'UPDATEISMEDIUM',
  KEEPMESIGNEDIN: 'KEEPMESIGNEDIN',
  SIGNOUT: 'SIGNOUT',
  SIGNEDIN: 'SIGNEDIN',
  UPDATEISGLOBALSESSIONEXPIRED: 'UPDATEISGLOBALSESSIONEXPIRED',
  UPDATESEARCING: 'UPDATESEARCING',
  UPDATESEARCHSTRING: 'UPDATESEARCHSTRING',
  UPDATESEARCHTYPE: 'UPDATESEARCHTYPE',
  UPDATEAIRPORTLIST: 'UPDATEAIRPORTLIST',
  UPDATEROUTECONFIG: 'UPDATEROUTECONFIG',
  UPDATEISCONSENTACCEPTED: 'UPDATEISCONSENTACCEPTED',
  UPDATECURRENCY: 'UPDATECURRENCY',
  UPDATECURRENCYLIST: 'UPDATECURRENCYLIST',
  UPDATECATEGORYLIST: 'UPDATECATEGORYLIST',
  SETBRANDLIST: 'SETBRANDLIST',
  SETACTIVEPAGE: 'SETACTIVEPAGE',
  SETFILTEREDLIST: 'SETFILTEREDLIST',
  SETACTIVECRITERIA: 'SETACTIVECRITERIA',
  SETCRITERIA: 'SETCRITERIA',
  SETSORTPARAM: 'SETSORTPARAM',
  SETINITIALIZING: 'SETINITIALIZING',
};
