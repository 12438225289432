import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Banner from './banner/Banner';
import PromotionPage from './promotion-page/PromotionPage';
import HomeProducts from './HomeProducts';
import PromoCodeWidget from '../promotions/PromoCodeWidget';

const Home = (props) => {
  const upToTopBtn = useRef(null);
  document.title = 'Ethiopian Duty Free';

  const rootElement = document.documentElement;

  useEffect(() => {
    // upToTopBtn = document.querySelector("#up-to-top");
    upToTopBtn.current.addEventListener('click', scrollToTop);
    document.addEventListener('scroll', showBtn);
    var temp = upToTopBtn.current;

    return function cleanup() {
      temp.removeEventListener('click', scrollToTop);
      document.removeEventListener('scroll', showBtn);
    };
  });

  function scrollToTop() {
    rootElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  function showBtn() {
    if (document.body.scrollTop > 100 || rootElement.scrollTop > 100) {
      upToTopBtn.current.classList.add('active');
    } else {
      upToTopBtn.current.classList.remove('active');
    }
  }

  return (
    <div>
      <Banner history={props.history} />
      <PromoCodeWidget />
      <section className="home-product-list-container df-product-container">
        <HomeProducts
          list={props.newArrivals}
          newArrival={true}
          viewAllUrl={'/new-arrivals'}
          title={'new arrivals'}
        />
        <PromotionPage history={props.history} />
        <HomeProducts
          list={props.specialOffers}
          offer={true}
          viewAllUrl={'/offers'}
          title={'special offers'}
        />
      </section>
      <div id="up-to-top" ref={upToTopBtn}>
        <span>
          <FontAwesomeIcon icon={faAngleUp} />
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isMobile: state.global.isMobile,
    specialOffers: state.batch.specialOffers,
    newArrivals: state.batch.newArrivals,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
