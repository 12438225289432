import React from "react";
import "./Loading.css";
import "./Skeleton.css";

const Loading = () => {
  return (
    <div>
      <div id="loader-wrapper">
        <div id="loader"></div>
      </div>
      <div id="log-wrapper">
        <div id="log"></div>
      </div>
    </div>
  );
};

export default Loading;
