import { Carousel, Container } from "react-bootstrap";
import React, { Component } from "react";
import { connect } from "react-redux";
import HttpService from "../../../service/shared/HttpService";
import Translation from "i18next";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerListRes: { large: [], medium: [], small: [] },
      isLoading: true,
      width: window.innerWidth,
    };
  }

  async componentDidMount() {
    let relativeUrl = "/PreOrder/api/V1.0/Banner/GetAll";
    let clientToken = this.props.clientToken;
    let accessToken = this.props.accessToken;
    window.addEventListener("resize", () => {
      this.setState({ width: window.innerWidth });
    });
    await HttpService.getService(relativeUrl, clientToken, accessToken).then(
      (response) => {
        var result = { large: [], medium: [], small: [] };
        for (var i = 0; i < response.data.bannerListRes.length; i++) {
          switch (response.data.bannerListRes[i].bunnerImageSize) {
            case 1:
              result.small.push(response.data.bannerListRes[i]);
              break;
            case 2:
              result.medium.push(response.data.bannerListRes[i]);
              break;
            default:
              result.large.push(response.data.bannerListRes[i]);
              break;
          }
        }
        // revert array
        result.small = result.small.reverse();
        result.medium = result.medium.reverse();
        result.large = result.large.reverse();

        //shift
        result.small.unshift(result.small[result.small.length - 1]);
        result.small.pop();
        result.medium.unshift(result.medium[result.medium.length - 1]);
        result.medium.pop();
        result.large.unshift(result.large[result.large.length - 1]);
        result.large.pop();

        this.setState({
          bannerListRes: result,
          isLoading: false,
        });
      },
      (error) => {
        this.setState({ isLoading: false, offerList: [] });
      }
    );
  }
  getInterval(bannerList, index) {
    if (this.state.width > 900) {
      if (bannerList.large[index].videoURL == null) {
        return 3500;
      } else {
        return 30000;
      }
    } else if (this.state.width >= 480 && this.state.width <= 900) {
      if (bannerList.medium[index].videoURL == null) {
        return 3500;
      } else {
        return 30000;
      }
    } else if (this.state.width < 480) {
      if (bannerList.small[index].videoURL == null) {
        return 3500;
      } else {
        return 30000;
      }
    }
    return 3500;
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Carousel>
            <Carousel.Item key={1}>
              <div className="w-100 skeleton skeleton-main-image"></div>
              {/* <picture>
                <source 
                  media="(min-width: 900px)"
                  srcSet="https://b2cappdevstorageaccount.blob.core.windows.net/dutyfree/bha0dter.mbc.jpeg" 
                className="w-100" />
                <source 
                  media="(min-width: 480px)" 
                  srcSet="https://b2cappdevstorageaccount.blob.core.windows.net/dutyfree/4naevdqp.yhg.png" 
                  className="w-100" />
                <source 
                  media="(max-width: 480px)" 
                  srcSet="https://b2cappdevstorageaccount.blob.core.windows.net/dutyfree/mqh243pi.0uw.png" 
                  className="w-100" />

                <img fetchpriority="high" src="https://b2cappdevstorageaccount.blob.core.windows.net/dutyfree/bha0dter.mbc.jpeg" alt="Banner" className="w-100" />
                </picture> */}
            </Carousel.Item>
          </Carousel>
        ) : (
          <Carousel fade controls={false}>
            {this.state.bannerListRes.large.map((element, index) => {
              return (
                <Carousel.Item
                  key={index}
                  interval={this.getInterval(this.state.bannerListRes, index)}
                >
                  {this.state.width > 900 && (
                    <>
                      {element.videoURL == null && (
                        <picture>
                          <img
                            fetchpriority="high"
                            src={element.imageURL}
                            alt="Banner"
                            className="w-100"
                            height="600"
                          />
                        </picture>
                      )}
                      {element.videoURL != null && (
                        <video autoPlay poster={element.imageURL} loop>
                          <source src={element.videoURL} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </>
                  )}
                  {this.state.width >= 480 &&
                    this.state.width <= 900 &&
                    this.state.bannerListRes.medium[index] !== undefined && (
                      <>
                        {this.state.bannerListRes.medium[index].videoURL ==
                          null && (
                          <picture>
                            <source
                              media="(min-width: 480px)"
                              srcSet={
                                this.state.bannerListRes.medium[index].imageURL
                              }
                              className="w-100"
                              height="450"
                            />
                            <img
                              fetchpriority="high"
                              src={element.imageURL}
                              alt="Banner"
                              className="w-100"
                              height="328"
                            />
                          </picture>
                        )}
                        {this.state.bannerListRes.medium[index].videoURL !=
                          null && (
                          <video
                            autoPlay
                            loop
                            poster={
                              this.state.bannerListRes.medium[index].imageURL
                            }
                          >
                            <source
                              src={
                                this.state.bannerListRes.medium[index].videoURL
                              }
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </>
                    )}
                  {this.state.width < 480 &&
                    this.state.bannerListRes.small[index] !== undefined && (
                      <>
                        {this.state.bannerListRes.small[index].videoURL ==
                          null && (
                          <picture>
                            <source
                              media="(max-width: 480px)"
                              height="328"
                              srcSet={
                                this.state.bannerListRes.small[index].imageURL
                              }
                              className="w-100"
                            />
                            <img
                              fetchpriority="high"
                              src={element.imageURL}
                              alt="Banner"
                              className="w-100"
                              height="328"
                            />
                          </picture>
                        )}
                        {this.state.bannerListRes.small[index].videoURL !=
                          null && (
                          <video
                            height={328}
                            width={480}
                            style={{ objectFit: "fill" }}
                            autoPlay
                            loop
                            poster="https://b2cappdevstorageaccount.blob.core.windows.net/dutyfree/rizpohzd.0qk.jpeg"
                          >
                            <source
                              src={
                                this.state.bannerListRes.small[index].videoURL
                              }
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </>
                    )}

                  <Carousel.Caption className=" h-100 w-100">
                    <Container>
                      <div
                        className="h-100 mb-5 pb-5 w-100 d-flex flex-column align-items-center align-items-md-start justify-content-center p-3"
                        style={{ zIndex: 2000 }}
                      >
                        <h1
                          style={{
                            textShadow: "#A3A3A3 3px 3px 1px",
                          }}
                          className="text-white invisible"
                        >
                          {Translation.t("new arrival on board")}
                        </h1>
                        <h1
                          style={{
                            textShadow: "#A3A3A3 3px 3px 1px",
                          }}
                          className="text-white invisible"
                        >
                          {Translation.t("sheba duty free")}
                        </h1>
                      </div>
                    </Container>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.global.isMobile,
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    locale: state.global.locale,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
