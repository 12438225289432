export function thousandsSeparators(num) {
  if (num !== null) {
    let num_parts = num.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return num_parts.join('.');
  }
  return 0;
}
export function getSalesPrice(num) {
  if (num !== null) return num;
  return 0;
}
export function roundTwoDecimal(val) {
  return Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100;
}
export function capitalizeFirstLater(text) {
  let arr = [];
  if (text.split(' ').length > 0) {
    arr = text.split(' ');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
    }
    return arr.join(' ');
  }
  if (text.split('-').length > 0) {
    arr = text.split('-');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
    }
    return arr.join('-');
  }

  return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
}

export function getUrlVars() {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

export function getPaginatedResult(searchResult, activePage, pageSize) {
  if (Math.ceil(searchResult.length / pageSize) === activePage) {
    return searchResult.slice((activePage - 1) * pageSize);
  }
  return searchResult.slice((activePage - 1) * pageSize, activePage * pageSize);
}

export const PriceListEnum = {
  1: 'Volume',
  2: 'Unit',
  3: 'Percentage',
  4: 'BuyGet',
};

export const bannedItemCodesInEU = [
  'FF185',
  'FF184',
  'FF047',
  'FM222',
  'FM119',
  'FL110',
  'FF189',
  'FG207',
];
