export class FeedBackFormValidator {
  constructor() {
    this.isEmailValid = true;
    this.isFullNameValid = true;
    this.isFeedBackBodyValid = true;
    this.feedbackFormValidLists = [];
    this.errors = { fullName: "", feedBackBody: "", email: "" };
  }

  validateFeedBackForm(feedBackForm) {
    this.feedbackFormValidLists = [];
    for (let index = 0; index < 3; index++) {
      this.feedbackFormValidLists.push(true);
    }

    this.isEmailValid = this.validateEmail(feedBackForm.email, 0);
    this.isFullNameValid = this.validateName(feedBackForm.fullName, 1);
    this.isFeedBackBodyValid = this.validateFeedBackFormType(
      feedBackForm.feedBack,
      2
    );

    return {
      FeedBackFormValidation: this,
      feedbackFormValidLists: this.feedbackFormValidLists,
    };
  }
  validateFeedBackFormType(type, index) {
    if (type === "" || type == null) {
      this.feedbackFormValidLists[index] = false;
      this.errors.feedBackBody =
        "Feed back is required";
      return false;
    } else {
      this.feedbackFormValidLists[index] = true;
      return true;
    }
  }
  validateEmail(type, index) {
    var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (type === "" || type == null) {
      this.feedbackFormValidLists[index] = false;
      this.errors.email = "Email Address field is required.";
      return false;
    } else if (!mailformat.test(type)) {
      this.feedbackFormValidLists[index] = false;
      this.errors.email = "Email address format is invalid";
      return false;
    } else {
      this.feedbackFormValidLists[index] = true;
      return true;
    }
  }

  validateName(type, index) {
    // var regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
    var numberPattern = /\d+/g;
    if (type === "" || type == null) {
      this.feedbackFormValidLists[index] = false;
      this.errors.fullName =
        "Full Name is required";
      return false;
    } else if (numberPattern.test(type)) {
      this.feedbackFormValidLists[index] = false;
      this.errors.fullName =
        "Full Name can not contain numberic character";
      return false;
    } else {
      this.feedbackFormValidLists[index] = true;
      return true;
    }
  }

  isTheFormValid(feedBackForm) {
    var isValid = true;
    this.validateFeedBackForm(feedBackForm).feedbackFormValidLists.forEach(
      (element) => {
        if (!element) isValid = false;
      }
    );
    return isValid;
  }
}
