import { faChevronLeft, faUserAlt, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card, Container, Col, Row } from 'react-bootstrap';
import AccountService from '../../service/account/AccountService';
import './Account.css';
import Translation from 'i18next';

const Account = (props) => {
  const accountService = new AccountService();
  document.title = 'Ethiopian Duty Free | Account';

  return (
    <section className="light-background-color" style={{ minHeight: '50vh', padding: '2rem 0' }}>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={8} lg={6} xl={5} className="m-auto">
            <Card
              style={{
                padding: '3rem 1rem',
                boxShadow: '0 5px 10px #eee',
                borderRadius: '10px',
              }}
            >
              <div className="mb-4">
                <img
                  height="75"
                  width="262"
                  className="img-fluid"
                  src={process.env.PUBLIC_URL + 'duty-free-logo.png'}
                  alt="logo"
                  loading="lazy"
                />
              </div>
              <div>
                <p className="mt-2 mb-2 sheba-miles-txt">
                  {Translation.t('login with your sheba duty free account')}
                </p>
              </div>
              <br />
              <div className="account-btn row mx-0 px-3 col-12 col-xl-10 mx-auto">
                <Button
                  className="et-btn df-account-btn py-0 text-left d-flex"
                  href={
                    '/login?redirectUrl=' + accountService.getRedirectUrl(props.location.search)
                  }
                  style={{ backgroundColor: '#2e7d32', height: '48px' }}
                >
                  <div className="d-flex pr-3" style={{ borderRight: '1px solid #ffffff' }}>
                    <FontAwesomeIcon icon={faUserLock} size={'2x'} className="my-auto" />
                  </div>
                  <span className="mx-auto py-2">{Translation.t('login')}</span>
                </Button>
                <span className="px-4 py-3">{Translation.t('or')}</span>
                <Button
                  className="duty-free-outline-primary-btn p-0 text-left d-flex"
                  href={
                    '/shebamiles/login?redirectUrl=' +
                    accountService.getRedirectUrl(props.location.search)
                  }
                  id="log-in-using-sheba-miles"
                >
                  <img
                    width="64"
                    height="48"
                    className="ml-3 pr-3"
                    style={{ borderRight: '1px solid #0000006b' }}
                                      src={process.env.PUBLIC_URL + '/images/shebamiles_active.png'}
                    alt="sheba miles logo"
                    loading="lazy"
                  />
                  <span className="px-3 m-auto">
                    {Translation.t('login with shebamiles account')}
                  </span>
                </Button>
                <span className="px-4 py-3">{Translation.t('or')}</span>
                <Button
                  className="et-btn df-account-btn py-0 text-left d-flex"
                  href={
                    '/registration?redirectUrl=' +
                    accountService.getRedirectUrl(props.location.search)
                  }
                  style={{ backgroundColor: '#2e7d32', height: '48px' }}
                >
                  <div className="d-flex pr-3" style={{ borderRight: '1px solid #ffffff' }}>
                    <FontAwesomeIcon icon={faUserAlt} size={'2x'} className="my-auto" />
                  </div>
                  <span className="mx-auto py-2">{Translation.t('register')}</span>
                </Button>
                {accountService.getRedirectUrl(props.location.search) == "/checkout"?
                <>
                 <span className="px-4 py-3">{Translation.t('or')}</span>
                 <Button
                  className="duty-free-outline-primary-btn p-0 text-left d-flex"
                  href={
                    '/guest-checkout?redirectUrl=' +
                    accountService.getRedirectUrl(props.location.search)
                  }
                  id="continue-as-guest"
                >
                 <div className="d-flex px-3" style={{ borderRight: '1px solid #0000006b', height:'48px'}}>
                    <FontAwesomeIcon icon={faUserAlt} size={'2x'} className="my-auto" />
                  </div>
                  <span className="px-3 m-auto">
                    {Translation.t('Continue as Guest')}
                  </span>
                </Button></> : null}
              </div>
              <br />
              <br />
              <div className="ml-3">
                <Button className="float-left duty-free-outline-primary-btn" href="/">
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span className="px-2">{Translation.t('back')}</span>
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
    </section>
  );
};

export default Account;
