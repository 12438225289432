import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import ReactImageZoom from "react-image-zoom";

const ProductImage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const setImageTrackerConfig = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    let index = 0;
    let item = props.product;
    if (item?.itemImage !== null && item?.itemImage.length > 1) {
      index =
        item?.itemImage.filter((itemImage) => itemImage.isDefualt).length === 0
          ? 0
          : item?.itemImage.findIndex((itemImage) => itemImage.isDefualt);
    }
    let temp = {
      imageList:
        item?.itemImage !== null ? item?.itemImage : [{ imageUrl: "" }],
      index: index,
    };
    return temp;
  };
  const [imageTracker, setImageTracker] = useState(() => {
    if (props.product) return setImageTrackerConfig();
    return null;
  });

  const changeActiveImage = (index) => {
    let temp = JSON.parse(JSON.stringify(imageTracker));

    temp.index = index;
    setImageTracker(temp);
    setShowVideo(false);
  };

  const imageZoomDimession = {
    width: 376,
    height: 376,
    scale: 1.6,
  };
  const imageZommProps = {
    ...imageZoomDimession,
    img:
      Object.keys(imageTracker).length !== 0
        ? imageTracker.imageList[imageTracker.index].imageUrl
        : "",
    zoomPosition: window.innerWidth < 992 ? "bottom" : "right",
    scale: window.innerWidth < 1400 ? 1.6 : 1.7,
  };

  useEffect(() => {
    setImageTracker(setImageTrackerConfig());
    if (props.product.youtubeAddressURL != null) {
      setShowVideo(true);
    }
  }, [props.product.name]);

  const displayVideo = () => {
    setShowVideo(true);
  };

  const youtubeEmbed = (embedId) => (
    <div className="video-responsive">
      <iframe
        width={imageZoomDimession.width}
        height={imageZoomDimession.height}
        src={`https://www.youtube.com/embed/${embedId}&rel=0`}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
        title="Embedded youtube"
      />
    </div>
  );
  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header
          style={{ borderBottom: "none" }}
          closeButton
        ></Modal.Header>
        <Modal.Body className="p-0">
          <img
            id="img-1-itself"
            className="img-fluid p-3"
            src={imageTracker.imageList[imageTracker.index].imageUrl}
            alt="product"
            loading="lazy"
          />
        </Modal.Body>
      </Modal>
      <Col sm={12} lg={6} className="pr-4">
        <Row className="mx-0 bg-white rounded pt-3 h-100">
          <Col>
            {showVideo ? (
              <>
                <div>
                  {youtubeEmbed(
                    props.product.youtubeAddressURL.split("/")[
                      props.product.youtubeAddressURL.split("/").length - 1
                    ]
                  )}
                </div>
              </>
            ) : (
              <>
                {" "}
                {window.innerWidth > 425 ? (
                  <div
                    className="zoomed-image-container"
                    style={{
                      width: imageZoomDimession.width,
                      height: imageZoomDimession.height,
                    }}
                  >
                    <ReactImageZoom {...imageZommProps} />
                  </div>
                ) : (
                  <img
                    src={imageTracker.imageList[imageTracker.index].imageUrl}
                    alt="product"
                    onClick={() => setShowModal(true)}
                    onKeyDown={() => setShowModal(true)}
                    className="img-fluid"
                    loading="lazy"
                  />
                )}
              </>
            )}
          </Col>

          <Col xs={12} className="row mx-0 my-3">
            {props.product.youtubeAddressURL != null && (
              <div
                className={
                  showVideo
                    ? "duty-free-available-image-list active"
                    : "duty-free-available-image-list"
                }
                onClick={() => displayVideo()}
                onKeyDown={() => displayVideo()}
              >
                <img
                  className="img-fluid"
                  id="img-1"
                  src={imageTracker.imageList[0].imageUrl}
                  alt="product"
                  loading="lazy"
                />
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  color="#718289"
                  className="video-play-icon"
                  style={{
                    marginTop: "15px",
                    fontSize: "18px",
                  }}
                />{" "}
              </div>
            )}
            {imageTracker.imageList.map((imageList, index) => {
              return (
                <div
                  key={index}
                  className={
                    imageTracker.index === index && !showVideo
                      ? "duty-free-available-image-list active"
                      : "duty-free-available-image-list"
                  }
                  onClick={() => changeActiveImage(index)}
                  onKeyDown={() => changeActiveImage(index)}
                >
                  <img
                    className="img-fluid"
                    id="img-1"
                    src={imageList.imageUrl}
                    alt="product"
                    loading="lazy"
                  />
                </div>
              );
            })}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ProductImage;
