export const batchActionType = {
    UPDATENEWARRIVALS: "UPDATENEWARRIVALS",
    UPDATESPECIALOFFERS: "UPDATESPECIALOFFERS",
    UPDATEPRODUCTS: "UPDATEPRODUCTS",
    UPDATEBATCH: "UPDATEBATCH",
    UPDATEBATCHISLOADING : "UPDATEBATCHISLOADING",
    UPDATEBATCHISLOADINGTOTRUE: "UPDATEBATCHISLOADINGTOTRUE",
    SETONBOARDINGSTATUS : "SETONBOARDINGSTATUS",
    UPDATEONBOARDINGDETAIL : "UPDATEONBOARDINGDETAIL",
    SETPROMOCODEPRODUCTS : "SETPROMOCODEPRODUCTS",    
    SETOFFERPRICELISTS : "SETOFFERPRICELISTS"
  };   