export class PaymentFormValidation {
  constructor(
    payload = {
      isPickFromValid: true,
      isOriginValid: true,
      isDestinationValid: true,
      isFlightNumberValid: true,
      isDepartureDateValid: true,
      isTicketNumberValid: true,
      isSeatNumberValid: true,
      paymentFormValidLists: [],
    }
  ) {
    this.isPickFromValid = payload.isPickFromValid;
    this.isOriginValid = payload.isOriginValid;
    this.isDestinationValid = payload.isDestinationValid;
    this.isFlightNumberValid = payload.isFlightNumberValid;
    this.isDepartureDateValid = payload.isDepartureDateValid;
    this.isTicketNumberValid = payload.isTicketNumberValid;
    this.isSeatNumberValid = payload.isSeatNumberValid;
    this.paymentFormValidLists = payload.paymentFormValidLists;
  }

  paymentFormValidationInfo(PaymentFormInfo) {
    this.paymentFormValidLists = [];
    for (let index = 0; index < 4; index++) {
      this.paymentFormValidLists.push(true);
    }

    this.isPickFromValid = this.validatePaymentFormType(
      PaymentFormInfo.pickFrom,
      0
    );
    this.isOriginValid = this.validatePaymentFormType(
      PaymentFormInfo.origin,
      1
    );
    this.isFlightNumberValid = this.validatePaymentFormType(
      PaymentFormInfo.flightNumber,
      2
    );
    this.isDepartureDateValid = this.validatePaymentFormType(
      PaymentFormInfo.departureDate,
      3
    );
    this.isDestinationValid = this.validatePaymentFormType(
      PaymentFormInfo.destination,
      4
    );
    this.isTicketNumberValid = this.validatePaymentFormType(
      PaymentFormInfo.ticketNumber,
      5
    );

    this.isSeatNumberValid = this.validatePaymentFormType(
      PaymentFormInfo.seatNumber,
      6
    );
    return {
      paymentFormValidationInfo: this,
      paymentFormValidLists: this.paymentFormValidLists,
    };
  }
  isTheFormValid(PaymentFormInfo) {
    var isValid = true;
    this.paymentFormValidationInfo(
      PaymentFormInfo
    ).paymentFormValidLists.forEach((element) => {
      if (!element) isValid = false;
    });
    return isValid;
  }
  validatePaymentFormType(type, index) {
    if (type === "" || type == null) {
      this.paymentFormValidLists[index] = false;
      return false;
    } else {
      this.paymentFormValidLists[index] = true;
      return true;
    }
  }

  validatePickFrom(value) {
    var temp = new PaymentFormValidation(JSON.parse(JSON.stringify(this)));
    temp.isPickFromValid = !(value === "" || value == null);
    return temp;
  }
  validateOrigin(value) {
    // var temp = new PaymentFormValidation();
    var temp = new PaymentFormValidation(JSON.parse(JSON.stringify(this)));
    temp.isOriginValid = !(value === "" || value == null);
    return temp;
  }
  validateDestination(value) {
    var temp = new PaymentFormValidation(JSON.parse(JSON.stringify(this)));
    temp.isDestinationValid = !(value === "" || value == null);
    return temp;
  }
  validateFlightNumber(value) {
    var temp = new PaymentFormValidation(JSON.parse(JSON.stringify(this)));
    temp.isFlightNumberValid = !(value === "" || value == null);
    return temp;
  }
  validateDepartureDate(value) {
    var temp = new PaymentFormValidation(JSON.parse(JSON.stringify(this)));
    temp.isDepartureDateValid = !(value === "" || value == null);
    return temp;
  }
  validateTicketNumber(value) {
    var temp = new PaymentFormValidation(JSON.parse(JSON.stringify(this)));
    temp.isTicketNumberValid = !(value === "" || value == null);
    return temp;
  }

  validateFlightInfo(origin, destination, flightNumber, departureDate){

    var temp = new PaymentFormValidation(JSON.parse(JSON.stringify(this)));

    temp.isDepartureDateValid = !(departureDate === "" || departureDate == null);
    temp.isFlightNumberValid = !(flightNumber === "" || flightNumber == null);
    temp.isDestinationValid = !(destination === "" || destination == null);
    temp.isOriginValid = !(origin === "" || origin == null);
    
    return temp;
  }

  validateSeatNumber(value) {
    var temp = new PaymentFormValidation(JSON.parse(JSON.stringify(this)));
    temp.isSeatNumberValid = !(value === "" || value == null);
    return temp;
  }

}
