import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Translation from "i18next";

class ProductListEmpty extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section>
        <Container className="my-4" style={{ minHeight: "50vh" }}>
          <div className="mt-4 pt-4">
            <img src="/search-not-found.png" alt="Not found" style={{ height: "174px" }} loading="lazy" />
          </div>
          <div>
            <h3>
              <b>{this.props.message}</b>
            </h3>
          </div>
          {this.props.showBackButton && (
            <div className="mt-4 pt-4">
              <Button
                className="duty-free-primary-btn"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/",
                    state: null,
                  })
                }
              >
                {Translation.t("back to home")}
              </Button>
            </div>
          )}
        </Container>
      </section>
    );
  }
}

export default withRouter(ProductListEmpty);
