import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Translation from 'i18next';
import Card from '../home/Card';
import { connect } from 'react-redux';
import ProductService from '../../service/product service/ProductService';
import { Link } from 'react-router-dom';
import AddToCart from '../cart/AddToCart';
import SortService from 'src/service/shared/sort/SortService';

const RelatedProducts = (props) => {
  const sortService = new SortService();

  const triggerForNewRelatedItem = () => {
    let productService = new ProductService();
    let product = props.products.find((element) => element.id == props.match.params.id);
    let payload = {
      ItemCategoryId: product.itemCategoryId,
      BrandId: product.brandId,
      CurrentItemId: product.id,
    };
    productService
      .getRelatedProduct(props.clientToken, props.accessToken, payload)
      .then((response) => {
        setRelatedProducts(response.data.similarItems);
        setIsloading(false);
      })
      .catch(() => {
        setIsloading(false);
        return [];
      });
  };

  const [sliderTracker, setSliderTracker] = useState(0);
  const [isLoading, setIsloading] = useState(true);

  const [relatedProducts, setRelatedProducts] = useState(() => {
    triggerForNewRelatedItem();
    return [];
  });

  const next = () => {
    let sliderTemp = sliderTracker;
    setSliderTracker(sliderTemp + 1);
  };
  const prev = () => {
    let sliderTemp = sliderTracker;
    setSliderTracker(sliderTemp - 1);
  };

  const getResult = () => {
    return sortService.sortProducts(relatedProducts, 'ByPrice', 'DESC').slice(sliderTracker);
  };

  useEffect(() => {
    setIsloading(true);
    triggerForNewRelatedItem();
    setSliderTracker(0);
  }, [props.match.params.id]);

  return (
    <div>
      <div className="pt-3 pb-5">
        <h2 className="text-center mb-4 pl-3">{Translation.t('you might also like')}</h2>
        {isLoading ? (
          <Row className="mx-0 col-12 px-0">
            {[...Array(4)].map((element, index) => (
              <Col xs={12} sm={6} md={4} lg={3} key={'offer-skeleton' + index} className="mb-4">
                <div className="duty-free-card d-flex mb-4">
                  <div className="mx-0 row w-100">
                    <div className="duty-free-card-image col-sm-12 px-0">
                      <div
                        className="skeleton skeleton-product-img"
                        style={{ width: '100%' }}
                      ></div>
                    </div>
                    <div className="text-left col-sm-12 mt-3">
                      <h5 className="duty-free-card-title text-left skeleton skeleton-title w-100">
                        {' '}
                      </h5>
                    </div>
                    <div className="text-left py-3 col-sm-12">
                      <div className="row mx-0 col-12 px-0 mb-2">
                        <div className="col-3">
                          <div className="skeleton skeleton-title mb-0 w-100"></div>
                        </div>
                        <div className="col-1 px-0">
                          <h3 className="px-1 skeleton-text-placeholder mb-0">/</h3>
                        </div>
                        <div className="col-3">
                          <div className="skeleton skeleton-title mb-0  w-100"></div>
                        </div>
                      </div>
                      <div className="row mx-0 col-12 px-0 p-2">
                        <div className="col-6 ml-auto">
                          <div className="skeleton skeleton-reward mb-0 w-100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <>
            {relatedProducts.length > 0 && (
              <Row className="mx-0 position-relative col-12 px-0">
                <span
                  className={
                    sliderTracker === 0
                      ? 'd-none'
                      : 'd-flex justify-content-center align-items-center duty-free-arrow-icon-container duty-free-arrow-icon-left'
                  }
                  role="button"
                  onClick={() => (sliderTracker === 0 ? null : prev())}
                  disabled={sliderTracker === 0}
                  tabIndex="0"
                  aria-label="Back"
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="duty-free-arrow-icon" />
                </span>
                <Col sm={12} className="px-0">
                  <Row className="mx-0">
                    {getResult().map(
                      (item, index) =>
                        index < 4 && (
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={6}
                            xs={12}
                            className="row px-0 mx-0"
                            key={item.name}
                          >
                            <Link
                              to={'/product-detail/' + item.id}
                              className="text-decoration-none text-dark"
                            >
                              <Card
                                item={{
                                  detail: '',
                                  availableOffer: false,
                                  newArrival: false,
                                  offer: false,
                                  ...item,
                                }}
                              />
                            </Link>
                            <AddToCart product={item} />
                          </Col>
                        )
                    )}
                  </Row>{' '}
                </Col>
                <span
                  className={
                    sliderTracker >= props.specialOffers.length - 4
                      ? 'd-none'
                      : 'd-flex justify-content-center align-items-center duty-free-arrow-icon-container duty-free-arrow-icon-right'
                  }
                  role="button"
                  tabIndex="0"
                  onClick={() => (sliderTracker >= props.specialOffers.length - 4 ? null : next())}
                  disabled={sliderTracker >= props.specialOffers.length - 4}
                  aria-label="Next"
                >
                  <FontAwesomeIcon icon={faChevronRight} className="duty-free-arrow-icon" />
                </span>
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.global.locale,
    isMobile: state.global.isMobile,
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    specialOffers: state.batch.specialOffers,
    products: state.batch.products,
  };
};
export default connect(mapStateToProps)(RelatedProducts);
