import React from "react";
import "./ProgressSimpler.css";

const ProgressSimpler = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default ProgressSimpler;
