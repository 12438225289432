import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Modal } from "react-bootstrap";

import { connect } from "react-redux";
import "./Product.css";
import { cartActionType } from "../../redux/actions/cart/cartAction";
import Toast from "react-bootstrap/Toast";
import ProductService from "../../service/product service/ProductService";
import RelatedProducts from "./RelatedProducts";
import ProgressSimpler from "../shared/UI/ProgressSimpler";
import Translation from "i18next";
import {
  bannedItemCodesInEU,
  thousandsSeparators,
} from "src/service/shared/utilits";
import {
  getBuyGetPriceList,
  getPricesForVolumeOrder,
  getProductRewardMile,
} from "src/service/shared/product/product";
import ProductImage from "./ProductImage";

const Product = (props) => {
  const history = useHistory();

  const triggerNewPriceForProduct = (itemId) => {
    let productService = new ProductService();
    let payload = {
      currencyId: props.currencyList.find(
        (el) => el.currencyCode == props.currency
      )?.id,
      itemIds: [itemId],
    };
    productService
      .getProductsDetail(props.clientToken, props.accessToken, payload)
      .then((response) => {
        setIsPriceLoading(false);
        setProduct(response.data.availabilitiesRes[0]);
      })
      .catch((error) => {
        setIsPriceLoading(false);
        // setProduct(error.response.data.availabilitiesRes[0]);
        history.push("/404");
        console.log(error);
      });
  };

  const [quantity, setQuantity] = useState(1);
  const [euRegulationModal, setEuRegulationModal] = useState(false);

  const [product, setProduct] = useState(() => {
    if (!props.isLoading || props.products.length !== 0)
      return (
        props.products.find((element) => element.id == props.match.params.id) ||
        {}
      );
    history.push("/404");
    return {};
  });
  const [show, setShow] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [isLoading, setIsloading] = useState(() => {
    return props.isLoading && props.products.length === 0;
  });
  const [outOfStockModal, setOutOfStockModal] = useState(false);
  const [itemQuantityExceededAlertModal, setItemQuantityExceededAlertModal] =
    useState(false);
  const [consent, setConsent] = useState(false);
  const [hasBadge, setHasBadge] = useState(false);

  const setImageTrackerConfig = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    let index = 0;
    let item = props.products.find(
      (element) => element.id == props.match.params.id
    );
    if (item?.itemImage !== null && item?.itemImage.length > 1) {
      index =
        item?.itemImage.filter((itemImage) => itemImage.isDefualt).length === 0
          ? 0
          : item?.itemImage.findIndex((itemImage) => itemImage.isDefualt);
    }
    let temp = {
      imageList:
        item?.itemImage !== null ? item?.itemImage : [{ imageUrl: "" }],
      index: index,
    };
    return temp;
  };
  const [imageTracker, setImageTracker] = useState(() => {
    if (!props.isLoading || props.products.length !== 0)
      return setImageTrackerConfig();
    return {};
  });

  const [isPriceLoading, setIsPriceLoading] = useState(() => {
    if (product && product.currency !== props.currency) {
      triggerNewPriceForProduct(product.id);
      return true;
    } else {
      return false;
    }
  });

  const addToCart = (item, quantity) => {
    //out of stock handler
    if (product.quantity < 2) {
      if (quantity > product.quantity) {
        setOutOfStockModal(true);
        return;
      }
      if (item.id in props.cart) {
        if (quantity + props.cart[item.id].quantity > product.quantity) {
          setOutOfStockModal(true);
          return;
        }
      }
    }
    //check if the allowed single item is present in the cart
    if (item.id in props.cart) {
      if (quantity + props.cart[item.id].quantity > item.quantity) {
        setItemQuantityExceededAlertModal(true);
        return;
      }
    }

    //check if it is above 18
    if (
      (item.itemCategory !== "TOBACCO" &&
        item.itemCategory !== "LIQUOR" &&
        bannedItemCodesInEU.indexOf(item.itemCode) === -1) ||
      consent
    ) {
      if (item.id in props.cart) {
        // check for discount
        checkForOfferUpdate(item, quantity + props.cart[item.id].quantity);
        props.updateQuantity(item.id, quantity);
      } else {
        let cartItemInfo = {
          quantity: quantity,
          item: item,
        };
        // check for discount
        checkForOfferUpdate(item, quantity);
        // add to cart
        props.updateCart(cartItemInfo);
      }
      setShow(true);
    } else {
      if (bannedItemCodesInEU.indexOf(item.itemCode) === -1)
        setAlertModal(true);
      else setEuRegulationModal(true);
    }

    //
  };
  const checkForOfferUpdate = (item, quantity) => {
    getBuyGetPriceList(item, quantity);
    getPricesForVolumeOrder(item, quantity);
  };
  const addToCartDirectly = (item, quantity) => {
    // add to cart
    if (item.id in props.cart) {
      // check for discount
      checkForOfferUpdate(item, quantity + props.cart[item.id].quantity);
      props.updateQuantity(item.id, quantity);
    } else {
      let cartItemInfo = {
        quantity: quantity,
        item: item,
      };
      // check for discount
      checkForOfferUpdate(item, quantity);
      props.updateCart(cartItemInfo);
    }
    setShow(true);
  };

  const roundTwoDecimal = (val) => {
    return Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100;
  };

  useEffect(() => {
    if (!props.isLoading || props.products.length !== 0) {
      const tempP =
        props.products.find((element) => element.id == props.match.params.id) ||
        {};
      setImageTracker(setImageTrackerConfig());
      setProduct(tempP);
    }
  }, [props.match.params.id]);

  useEffect(() => {
    if (!props.isLoading || props.products.length !== 0) {
      const tempP =
        props.products.find((element) => element.id == props.match.params.id) ||
        {};
      setProduct(tempP);
      setImageTracker(setImageTrackerConfig());
      setIsloading(false);
    }
  }, [props.products]);

  useEffect(() => {
    if (props.offers.filter((s) => s.id == product.id).length) {
      setHasBadge(true);
    }
    if (props.newArrivals.filter((s) => s.id == product.id).length) {
      setHasBadge(true);
    }
    if (product.isTravelExeclusive) {
      setHasBadge(true);
    }
  }, [product]);

  if (show) {
    setTimeout(() => {
      props.history.push("/cart");
    }, 700);
  }

  const youtubeEmbed = (embedId) => (
    <div className="video-responsive">
      <iframe
        width="700"
        height="360"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );

  document.title =
    Translation.t("product detail") + " | " + (product.name || "...");

  const getProductPrice = () => {
    if (
      product.volumePriceList &&
      (product.volumePriceList != null) & (product.volumePriceList.length !== 0)
    ) {
      let volumePriceList = product.volumePriceList.filter(
        (volumePrice) =>
          (volumePrice.startQuantity <= quantity) &
          (volumePrice.endQuantity > quantity)
      );
      if (volumePriceList.length != 0)
        return volumePriceList[0].customePrice + ".00";
      else return product.currentPrice + ".00";
    //} else return product.currentPrice + ".00";
    } else return product.isPercentage ? product.salesRate + ".00" : product.currentPrice + ".00";
  };

  const displayBrand = () => {
    if (product.brand)
      return (
        <span>
          Brand:{" "}
          <span className="text-decoration-underline">{product.brand}</span>
        </span>
      );
  };
  const offerBadge = () => {
    if (props.offers.filter((s) => s.id == product.id).length) {
      return (
        <span className="duty-free-text-badge">
          {Translation.t("offer badge")}
        </span>
      );
    }
  };

  const newArrivalsBadge = () => {
    if (props.newArrivals.filter((s) => s.id == product.id).length) {
      return (
        <span className="duty-free-text-badge">{Translation.t("new")}</span>
      );
    }
  };

  const travelExclusiveBadge = () => {
    if (product.isTravelExeclusive) {
      return (
        <span className="duty-free-card-badge">
          <img
            src={
              process.env.PUBLIC_URL +
              "/icons/card badge icon/svg/Travel Exclusive.svg"
            }
            alt="Travel Exclusive"
            loading="lazy"
          ></img>
        </span>
      );
    }
  };
  return (
    <section className="product-list-container df-product-container">
      <div className="container">
        {isLoading ? (
          <ProgressSimpler />
        ) : (
          <>
            <Toast
              className="dty-free-toast-container"
              onClose={() => setOutOfStockModal(false)}
              show={outOfStockModal}
              bg={"warning"}
              delay={5000}
              autohide
            >
              <Toast.Header>
                <h6 className="py-3 duty-free-unselectable-text">
                  <strong>{Translation.t("out of stock")}</strong>
                  <br />
                  {Translation.t("number of items in the stock")}{" "}
                  <strong>{product.quantity}</strong>.
                </h6>
              </Toast.Header>
              {/* <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body> */}
            </Toast>
            <Toast
              className="dty-free-toast-warring-container dty-free-toast-container"
              onClose={() => setItemQuantityExceededAlertModal(false)}
              show={itemQuantityExceededAlertModal}
              bg={"warning"}
              delay={5000}
              autohide
            >
              <Toast.Header>
                <h6 className="py-3 duty-free-unselectable-text">
                  The maximum order quantity for this item is <strong>2</strong>
                  . You have already added
                  <strong>
                    {product.id in props.cart
                      ? props.cart[product.id].quantity
                      : 0}
                  </strong>
                  item to the cart.
                </h6>
              </Toast.Header>
              {/* <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body> */}
            </Toast>
            <Toast
              className="dty-free-toast-container duty-free-unselectable-text"
              onClose={() => setShow(false)}
              show={show}
              bg={"success"}
              delay={1000}
              autohide
            >
              <Toast.Header>
                {imageTracker.imageList && (
                  <img
                    // src="/img2.jpg"
                    src={imageTracker.imageList[imageTracker.index].imageUrl}
                    className="rounded me-2"
                    style={{ height: "50px", width: "auto" }}
                    alt="product"
                    loading="lazy"
                  />
                )}

                <strong className="me-auto">X {quantity}</strong>
                <small>{product.name?.substring(0, 10) + "... "} Added! </small>
              </Toast.Header>
              {/* <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body> */}
            </Toast>
            <Modal
              show={alertModal}
              onHide={() => setAlertModal(false)}
              backdrop="static"
              // animation={false}
            >
              <Modal.Header
                style={{ borderBottom: "none" }}
                closeButton
              ></Modal.Header>
              <Modal.Body className="px-4 pb-4">
                <h4 className="text-center">Are you over 18?</h4>
                {product.itemCategory === "TOBACCO" ? (
                  <p className="text-center">
                    It is illegal to sell tobacco products to persons under the
                    age of 18. You confirm and warrant to us that you are over
                    the age of 18 and are not purchasing tobacco products on
                    behalf of a person under the age 18
                  </p>
                ) : (
                  <p className="text-center">
                    It is illegal to sell liquor products to persons under the
                    age of 18. You confirm and warrant to us that you are over
                    the age of 18 and are not purchasing liquor products on
                    behalf of a person under the age 18
                  </p>
                )}
                <div className="row">
                  <div className="col-12 col-sm-6 justify-content-center d-flex">
                    <button
                      className="duty-free-outline-secondary-btn btn"
                      onClick={() => {
                        window.location = "/";
                        setAlertModal(false);
                      }}
                    >
                      under 18 Exit
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 justify-content-center d-flex">
                    <button
                      className="duty-free-outline-primary-btn btn"
                      onClick={() => {
                        setConsent(true);
                        addToCartDirectly(product, quantity);
                        setAlertModal(false);
                      }}
                    >
                      Yes, I'm over 18
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            {/* europe requlation */}
            <Modal
              show={euRegulationModal}
              onHide={() => setEuRegulationModal(false)}
              backdrop="static"
              // animation={false}
            >
              <Modal.Header
                style={{ borderBottom: "none" }}
                closeButton
              ></Modal.Header>
              <Modal.Body className="px-4 pb-4">
                <h4 className="text-center">Is your destination in Europe?</h4>
                <p className="text-center">
                  According to the EU Cosmetics Regulation, from 01.03.2022 it
                  is prohibited in the EU, EEA, EFTA, TR, RU, EUWU to sell this
                  product with the ingredient Lilial (Butylphenyl
                  Methylpropional).
                </p>
                <div className="row">
                  <div className="col-12 col-sm-6 justify-content-center d-flex">
                    <button
                      className="duty-free-outline-secondary-btn btn"
                      onClick={() => {
                        window.location = "/";
                        setEuRegulationModal(false);
                      }}
                    >
                      Yes, Abort
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 justify-content-center d-flex">
                    <button
                      className="duty-free-outline-primary-btn btn"
                      onClick={() => {
                        setConsent(true);
                        addToCartDirectly(product, quantity);
                        setEuRegulationModal(false);
                      }}
                    >
                      No, Proceed
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Row>
              {product.name && <ProductImage product={product} />}

              <Col
                sm={12}
                lg={6}
                className={
                  hasBadge
                    ? "pt-5 text-left bg-white rounded mt-3 mt-lg-0"
                    : "text-left pt-3 bg-white rounded mt-3 mt-lg-0"
                }
              >
                <div class="d-pos">
                  <div class="d-flex gap-2 duty-free-badge-container">
                    {offerBadge()}
                    {newArrivalsBadge()}
                    {/* {bestSellerBadge()} */}
                    {travelExclusiveBadge()}
                  </div>
                </div>

                {/* {dealsBadge()} */}
                {displayBrand()}
                <h1
                  style={{ fontSize: "calc(1.3rem + .6vw)" }}
                  className="duty-free-product-title mb-3"
                >
                  {product.name}
                </h1>
                <p>{product.description}</p>
                <div className="my-5" style={{ fontWeight: "500" }}>
                  <span className="duty-free-product-currency pr-1">
                    {isPriceLoading ? (
                      <span className="mb-5 skeleton skeleton-title ml-3 skeleton-on-product-price"></span>
                    ) : (
                      props.currency
                    )}
                  </span>
                  <span className="duty-free-product-price">
                    {isPriceLoading ? (
                      <span className="mb-5 skeleton skeleton-title ml-3 skeleton-on-product-price"></span>
                    ) : (
                      getProductPrice()
                    )}
                  </span>

                  {isPriceLoading ? (
                    <p className="mb-0">
                      <span className="duty-free-product-price-in-miles">
                        {Translation.t("or")}
                      </span>{" "}
                      <span className="mb-5 skeleton skeleton-title ml-3 skeleton-on-product-price"></span>
                      <span className="mb-5 skeleton skeleton-title ml-3 skeleton-on-product-price mile-continaer"></span>
                    </p>
                  ) : product.milesForRedeem ? (
                    <p className="mb-0">
                      <span className="duty-free-product-price-in-miles">
                        {Translation.t("or")}
                      </span>
                      <span
                        className="duty-free-product-currency pl-1"
                        style={{ fontSize: "13px" }}
                      >
                        {Translation.t("miles")}
                      </span>
                      <span className="duty-free-product-price-in-miles">
                        {thousandsSeparators(
                          roundTwoDecimal(product.milesForRedeem)
                        )}
                      </span>
                    </p>
                  ) : null}
                  {isPriceLoading ? (
                    <div>
                      <span className="skeleton skeleton-title ml-3 skeleton-on-product-price"></span>
                      <span className="skeleton skeleton-title ml-3 skeleton-on-product-price mile-continaer"></span>
                    </div>
                  ) : product.milesForAccrual ? (
                    <div>
                      <span
                        className="duty-free-product-price"
                        style={{ fontSize: "15px" }}
                      >
                        {Translation.t("reward")}{" "}
                        {thousandsSeparators(
                          // roundTwoDecimal(product.milesForAccrual)
                          roundTwoDecimal(
                            getProductRewardMile(product, quantity)
                          )
                        )}
                      </span>
                      <span
                        className="duty-free-product-currency pl-1"
                        style={{ fontSize: "15px" }}
                      >
                        {Translation.t("miles")}
                      </span>
                    </div>
                  ) : null}
                  {/* <span className="duty-free-product-price">/ 5,000</span>{" "}
            <span className="duty-free-product-currency">Mile</span>{" "} */}
                </div>

                <Row>
                  <Col xs={4}>
                    <h4 className="font-weight-normal text-capitalize">
                      {Translation.t("quantity").toLocaleLowerCase()}
                    </h4>
                  </Col>
                  <Col xs={6} sm={4} xl={3}>
                    <Row className="align-items-center">
                      <Col xs={4} className="pr-0">
                        <button
                          className="w-100 btn-outline-et minus-quantity-btn btn btn-plus-or-minus"
                          disabled={quantity === 1}
                          onClick={() =>
                            quantity !== 1 ? setQuantity(quantity - 1) : null
                          }
                        >
                          -
                        </button>
                      </Col>
                      <Col xs={4} className="align-self-center px-0">
                        <span className="w-100 duty-free-quantity-container d-flex justify-content-center align-items-center duty-free-unselectable-text">
                          {quantity}
                        </span>
                      </Col>
                      <Col xs={4} className="pl-0">
                        <button
                          className="w-100 btn-outline-et plus-quantity-btn btn btn-plus-or-minus"
                          disabled={quantity === product.quantity}
                          onClick={() => setQuantity(quantity + 1)}
                        >
                          +
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="row">
                  {product?.volumePriceList?.map((volumePrice) => {
                    if (volumePrice.customePrice != product.currentPrice)
                      return (
                        <div
                          key={"volumePriceListId-" + volumePrice.volumePriceId}
                          className="col"
                        >
                          <div className="listing listing-radius listing-success mt-4">
                            <div className="shape">
                              <div className="shape-text">
                                {parseInt(
                                  ((product.currentPrice -
                                    volumePrice.customePrice) /
                                    product.currentPrice) *
                                    100
                                )}
                                %
                              </div>
                            </div>
                            <div className="listing-content">
                              <h3 className="lead">Discount</h3>
                              <p>
                                Buy more than{" "}
                                <strong>{volumePrice.startQuantity}</strong>{" "}
                                quantities up to{" "}
                                <strong>{volumePrice.endQuantity}</strong>{" "}
                                quantities and get each item for
                                <strong>
                                  {volumePrice.customePrice} {props.currency}
                                </strong>
                                .
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                  })}
                </div>
                <hr className="mb-4 mt-2 px-0 col-sm-8 col-md-7" />
                <Row>
                  <Col>
                    {product.quantity < 1 ? (
                      <h4 className="text-warning">Out of Stock</h4>
                    ) : (
                      <>
                        {product.quantity < 3 ? (
                          <h6 className="text-danger mb-3 font-weight-bold">
                            Only {product.quantity} left in stock - order soon.
                          </h6>
                        ) : null}
                        <button
                          className="btn-primary-et btn py-2"
                          disabled={isPriceLoading}
                          onClick={() => addToCart(product, quantity)}
                        >
                          <FontAwesomeIcon icon={faCartPlus} />
                          <span> {Translation.t("add to cart")}</span>
                        </button>
                      </>
                    )}
                  </Col>
                </Row>
                <br />
              </Col>
            </Row>
            <br />
            {product.name && <RelatedProducts {...props} />}
          </>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    isMobile: state.global.isMobile,
    cart: state.cart.cart,
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    currencyList: state.global.currencyList,
    currency: state.global.currency,
    products: state.batch.products,
    isLoading: state.batch.isLoading,
    brandList: state.global.brandList,
    offers: state.batch.specialOffers,
    newArrivals: state.batch.newArrivals,
    deals: state.batch.deals,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCart: (cartItem) =>
      dispatch({
        type: cartActionType.ADDTOCART,
        data: cartItem,
      }),
    updateQuantity: (id, quantity) =>
      dispatch({
        type: cartActionType.UPDATEQUANTITY,
        value: quantity,
        id: id,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
