import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Pagination from 'react-bootstrap/Pagination';
import { connect } from 'react-redux';
import HttpService from '../../service/shared/HttpService';
import ProgressSimpler from '../shared/UI/ProgressSimpler';
import { globalActionType } from 'src/redux/actions/shared/globalAction';

const BrandsPage = (props) => {
  document.title = 'All Brands';

  // const capitalizeFirstLater = (text) => {
  //   var arr = [];
  //   if (text.split(" ").length > 0) {
  //     arr = text.split(" ");
  //     for (var i = 0; i < arr.length; i++) {
  //       arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
  //     }
  //     return arr.join(" ");
  //   }
  //   if (text.split("-").length > 0) {
  //     arr = text.split("-");
  //     for (var i = 0; i < arr.length; i++) {
  //       arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
  //     }
  //     return arr.join("-");
  //   }

  //   return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
  // };

  const triggerBrandFetchReq = () => {
    let brandUrl = '/MasterData/api/V1.0/Brand/GetAll';
    HttpService.getService(brandUrl, props.clientToken, props.accessToken)
      .then((response) => {
        props.updateBrandList(
          response.data.brandsRes.sort(function (a, b) {
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const page_size = 30;

  const [activePage, setActivePage] = useState(() => {
    triggerBrandFetchReq();
    return 1;
  });

  const [isLoading, setIsLoading] = useState(true);

  const returnResult = () => {
    return props.brandList;
  };

  const getPaginatedResult = (searchResult) => {
    if (Math.ceil(returnResult().length / page_size) === activePage) {
      return searchResult.slice((activePage - 1) * page_size);
    }
    return searchResult.slice((activePage - 1) * page_size, activePage * page_size);
  };

  const paginationRender = () => {
    if (Math.ceil(returnResult().length / page_size) < 2) {
      return;
    }
    var totalPages = Math.ceil(returnResult().length / page_size);
    let items = [];
    if (Math.ceil(returnResult().length / page_size) < 7) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === activePage}
            onClick={() => setActivePage(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
      return <Pagination className="df-pagination-container">{items}</Pagination>;
    }

    items.push(<Pagination.First onClick={() => setActivePage(1)} disabled={activePage === 1} />);
    items.push(
      <Pagination.Prev onClick={() => setActivePage(activePage - 1)} disabled={activePage === 1} />
    );
    if (totalPages > 5) {
      if (activePage < 3 || activePage === totalPages) {
        items.push(
          <Pagination.Item key={1} active={1 === activePage} onClick={() => setActivePage(1)}>
            {1}
          </Pagination.Item>
        );
        items.push(
          <Pagination.Item key={2} active={2 === activePage} onClick={() => setActivePage(2)}>
            {2}
          </Pagination.Item>
        );
        items.push(<Pagination.Ellipsis />);
        const midpoint = parseInt(totalPages / 2);
        for (let number = midpoint; number <= midpoint + 1; number++) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === activePage}
              onClick={() => setActivePage(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
        items.push(<Pagination.Ellipsis />);
        items.push(
          <Pagination.Item
            key={totalPages}
            active={totalPages === activePage}
            onClick={() => setActivePage(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        );
      } else {
        items.push(<Pagination.Ellipsis />);
        if (activePage - 1 !== 2) {
          items.push(
            <Pagination.Item
              key={activePage - 1}
              active={false}
              onClick={() => setActivePage(activePage - 1)}
            >
              {activePage - 1}
            </Pagination.Item>
          );
        }
        items.push(
          <Pagination.Item key={activePage} active={true} onClick={() => setActivePage(activePage)}>
            {activePage}
          </Pagination.Item>
        );

        if (activePage + 1 !== totalPages) {
          items.push(
            <Pagination.Item
              key={activePage + 1}
              active={false}
              onClick={() => setActivePage(activePage + 1)}
            >
              {activePage + 1}
            </Pagination.Item>
          );
        }
        if (activePage + 2 < totalPages) {
          items.push(<Pagination.Ellipsis />);
        }
        if (activePage < totalPages) {
          items.push(
            <Pagination.Item
              key={totalPages}
              active={totalPages === activePage}
              onClick={() => setActivePage(totalPages)}
            >
              {totalPages}
            </Pagination.Item>
          );
        }
      }
    } else {
      for (let number = 1; number <= Math.ceil(totalPages / page_size); number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === activePage}
            onClick={() => setActivePage(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    items.push(
      <Pagination.Next
        onClick={() => setActivePage(activePage + 1)}
        disabled={activePage === totalPages}
      />
    );

    items.push(
      <Pagination.Last
        onClick={() => setActivePage(totalPages)}
        disabled={activePage === totalPages}
      />
    );
    return <Pagination className="df-pagination-container">{items}</Pagination>;
  };
  return (
    <Container className="my-3 px-3 py-3" style={{ marginBottom: '5.7em !important' }}>
      <h4 className="text-left mb-3">All Brands</h4>
      <Row className="popular-brands-container">
        {isLoading && !props.brandList ? (
          <Col xs={12}>
            <ProgressSimpler />
          </Col>
        ) : null}
        {getPaginatedResult(props.brandList).map((brand, index) => (
          <a
            href={'/product-list-by-brand/' + brand.name + '/' + brand.id}
            xs={6}
            sm={4}
            md={3}
            lg={2}
            className="row mx-0 col-lg-2 col-md-3 col-sm-4 col-6"
            key={index}
          >
            {brand.brandImageURL ? (
              <img className="w-100" src={brand.brandImageURL} loading="lazy" />
            ) : (
              <>
                <img
                  className="mx-auto"
                  style={{ maxWidth: '162px' }}
                  src={
                    process.env.PUBLIC_URL +
                    '/images/brand images/no-brand-image-white-placeholder.jpg'
                  }
                  loading="lazy"
                />
                <p className="text-dark">{brand.name}</p>
              </>
            )}
          </a>
        ))}
      </Row>
      <div className="my-3">{paginationRender()}</div>
    </Container>
  );
};
const mapStateToProps = (state) => {
  return {
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    cart: state.cart,
    userInfo: state.account.userInfo,
    isSignedIn: state.account.isSignedIn,
    brandList: state.global.brandList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateBrandList: (value) =>
      dispatch({
        type: globalActionType.SETBRANDLIST,
        value: value,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandsPage);
