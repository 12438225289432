import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Translation from 'i18next';
import './AddToCart.css';
import Toast from 'react-bootstrap/Toast';
import { Modal } from 'react-bootstrap';
import { cartActionType } from '../../redux/actions/cart/cartAction';
import { connect } from 'react-redux';
import { bannedItemCodesInEU } from 'src/service/shared/utilits';
import { getBuyGetPriceList, getPricesForVolumeOrder } from 'src/service/shared/product/product';

const AddToCart = (props) => {
  const [quantity, setQuantity] = useState(1);
  const [euRegulationModal, setEuRegulationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [itemQuantityExceededAlertModal, setItemQuantityExceededAlertModal] = useState(false);
  const [consent, setConsent] = useState(false);
  const [outOfStockModal, setOutOfStockModal] = useState(false);

  const addToCart = (item, quantity) => {
    //out of stock handler
    if (props.product.quantity < 2) {
      if (quantity > props.product.quantity) {
        setOutOfStockModal(true);
        return;
      }
      if (item.id in props.cart) {
        if (quantity + props.cart[item.id].quantity > props.product.quantity) {
          setOutOfStockModal(true);
          return;
        }
      }
    }
    //check if the allowed single item is present in the cart
    if (item.id in props.cart) {
      if (quantity + props.cart[item.id].quantity > item.quantity) {
        setItemQuantityExceededAlertModal(true);
        return;
      }
    }

    //check if it is above 18
    if (
      (item.itemCategory !== 'TOBACCO' &&
        item.itemCategory !== 'LIQUOR' &&
        bannedItemCodesInEU.indexOf(item.itemCode) === -1) ||
      consent
    ) {
      if (item.id in props.cart) {
        // check for discount
        checkForOfferUpdate(item, quantity + props.cart[item.id].quantity);
        props.updateQuantity(item.id, quantity);
      } else {
        let cartItemInfo = {
          quantity: quantity,
          item: item,
        };
        // check for discount
        checkForOfferUpdate(item, quantity);
        // add to cart
        props.updateCart(cartItemInfo);
      }
      setShow(true);
    } else {
      if (bannedItemCodesInEU.indexOf(item.itemCode) === -1) setAlertModal(true);
      else setEuRegulationModal(true);
    }
    //
  };
  const checkForOfferUpdate = (item, quantity) => {
    getPricesForVolumeOrder(item, quantity);
    getBuyGetPriceList(item, quantity);
  };

  const addToCartDirectly = (item, quantity) => {
    // add to cart
    if (item.id in props.cart) {
      checkForOfferUpdate(item, quantity + props.cart[item.id].quantity);
      // check for discount
      props.updateQuantity(item.id, quantity);
    } else {
      let cartItemInfo = {
        quantity: quantity,
        item: item,
      };
      // check for discount
      checkForOfferUpdate(item, quantity);
      props.updateCart(cartItemInfo);
    }
    setShow(true);
  };
  return (
    <>
      <Toast
        className="dty-free-toast-container"
        onClose={() => setOutOfStockModal(false)}
        show={outOfStockModal}
        bg={'warning'}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <h6 className="py-3 duty-free-unselectable-text">
            <strong>{Translation.t('out of stock')}</strong>
            <br />
            {Translation.t('number of items in the stock')}{' '}
            <strong>{props.product.quantity}</strong>.
          </h6>
        </Toast.Header>
        {/* <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body> */}
      </Toast>

      <Toast
        className="dty-free-toast-warring-container dty-free-toast-container"
        onClose={() => setItemQuantityExceededAlertModal(false)}
        show={itemQuantityExceededAlertModal}
        bg={'warning'}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <h6 className="py-3 duty-free-unselectable-text">
            The maximum order quantity for this item is <strong>2</strong>. You have already added{' '}
            <strong>
              {props.product.id in props.cart ? props.cart[props.product.id].quantity : 0}
            </strong>{' '}
            item to the cart.
          </h6>
        </Toast.Header>
        {/* <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body> */}
      </Toast>
      <Toast
        className="dty-free-toast-container duty-free-unselectable-text"
        onClose={() => setShow(false)}
        show={show}
        bg={'success'}
        delay={54000}
        autohide
      >
        <Toast.Header>
          <img
            // src="/img2.jpg"
            src={props.product?.itemImage[0]?.imageUrl}
            className="rounded me-2"
            style={{ height: '50px', width: 'auto' }}
            alt="product"
            loading="lazy"
          />
          <strong className="me-auto">X {quantity}</strong>
          <small>{props.product.name.substring(0, 10) + '... '} Added! </small>
        </Toast.Header>
        {/* <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body> */}
      </Toast>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header style={{ borderBottom: 'none' }} closeButton></Modal.Header>
        <Modal.Body className="p-0">
          <img
            id="img-1-itself"
            className="img-fluid p-3"
            src={props.product?.itemImage[0]?.imageUrl}
            alt="product"
            loading="lazy"
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={alertModal}
        onHide={() => setAlertModal(false)}
        backdrop="static"
        // animation={false}
      >
        <Modal.Header style={{ borderBottom: 'none' }} closeButton></Modal.Header>
        <Modal.Body className="px-4 pb-4">
          <h4 className="text-center">Are you over 18?</h4>
          {props.product.itemCategory === 'TOBACCO' ? (
            <p className="text-center">
              It is illegal to sell tobacco products to persons under the age of 18. You confirm and
              warrant to us that you are over the age of 18 and are not purchasing tobacco products
              on behalf of a person under the age 18
            </p>
          ) : (
            <p className="text-center">
              It is illegal to sell liquor products to persons under the age of 18. You confirm and
              warrant to us that you are over the age of 18 and are not purchasing liquor products
              on behalf of a person under the age 18
            </p>
          )}
          <div className="row">
            <div className="col-12 col-sm-6 justify-content-center d-flex">
              <button
                className="duty-free-outline-secondary-btn btn"
                onClick={() => {
                  window.location = '/';
                  setAlertModal(false);
                }}
              >
                under 18 Exit
              </button>
            </div>
            <div className="col-12 col-sm-6 justify-content-center d-flex">
              <button
                className="duty-free-outline-primary-btn btn"
                onClick={() => {
                  setConsent(true);
                  addToCartDirectly(props.product, quantity);
                  setAlertModal(false);
                }}
              >
                Yes, I'm over 18
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* europe requlation */}
      <Modal
        show={euRegulationModal}
        onHide={() => setEuRegulationModal(false)}
        backdrop="static"
        // animation={false}
      >
        <Modal.Header style={{ borderBottom: 'none' }} closeButton></Modal.Header>
        <Modal.Body className="px-4 pb-4">
          <h4 className="text-center">Is your destination in Europe?</h4>
          <p className="text-center">
            According to the EU Cosmetics Regulation, from 01.03.2022 it is prohibited in the EU,
            EEA, EFTA, TR, RU, EUWU to sell this product with the ingredient Lilial (Butylphenyl
            Methylpropional).
          </p>
          <div className="row">
            <div className="col-12 col-sm-6 justify-content-center d-flex">
              <button
                className="duty-free-outline-secondary-btn btn"
                onClick={() => {
                  window.location = '/';
                  setEuRegulationModal(false);
                }}
              >
                Yes, Abort
              </button>
            </div>
            <div className="col-12 col-sm-6 justify-content-center d-flex">
              <button
                className="duty-free-outline-primary-btn btn"
                onClick={() => {
                  setConsent(true);
                  addToCartDirectly(props.product, quantity);
                  setEuRegulationModal(false);
                }}
              >
                No, Proceed
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="row mx-0 align-items-start d-flex px-4 add-top-cart-container">
        <div className="col pl-0 col-2 d-flex my-auto">
          <button
            className="w-100 btn-outline-et minus-quantity-btn btn btn-plus-or-minus"
            disabled={quantity === 1}
            onClick={() => (quantity !== 1 ? setQuantity(quantity - 1) : null)}
          >
            -
          </button>
        </div>
        <div className="col pl-0 col-1 d-flex my-auto">
          <span className="w-100 duty-free-quantity-container d-flex justify-content-center align-items-center duty-free-unselectable-text">
            {quantity}
          </span>
        </div>
        <div className="col pl-0 col-2 d-flex my-auto">
          <button
            className="w-100 btn-outline-et plus-quantity-btn btn btn-plus-or-minus"
            disabled={quantity === props.product.quantity}
            onClick={() => setQuantity(quantity + 1)}
          >
            +
          </button>
        </div>
        <div className="col px-0 col-7 d-flex justify-content-end my-auto">
          <button
            className="btn btn-lg btn-primary-et "
            onClick={() => addToCart(props.product, quantity)}
          >
            <FontAwesomeIcon icon={faCartPlus} />
            <span> {Translation.t('add to cart')}</span>
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isMobile: state.global.isMobile,
    cart: state.cart.cart,
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    currencyList: state.global.currencyList,
    currency: state.global.currency,
    products: state.batch.products,
    isLoading: state.batch.isLoading,
    locale: state.global.locale,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCart: (cartItem) =>
      dispatch({
        type: cartActionType.ADDTOCART,
        data: cartItem,
      }),
    updateQuantity: (id, quantity) =>
      dispatch({
        type: cartActionType.UPDATEQUANTITY,
        value: quantity,
        id: id,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
