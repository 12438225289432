import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'src/component/home/Card';
import Translation from 'i18next';
import AddToCart from 'src/component/cart/AddToCart';
import SortService from 'src/service/shared/sort/SortService';

const HomeProducts = (props) => {
  const sortService = new SortService();

  const [sliderTracker, setSliderTracker] = useState(0);
  const next = () => {
    setSliderTracker(sliderTracker + 1);
  };
  const prev = () => {
    setSliderTracker(sliderTracker - 1);
  };

  const getResult = () => {
    return sortService.sortProducts(props.list, 'ByPrice', 'DESC').slice(sliderTracker);
  };

  return (
    <Container className="pt-2 pb-4">
      <div>
        {props.isLoading ? (
          <div className="py-4">
            <h3 className="mb-5 skeleton skeleton-title mx-auto"> </h3>
            <Row className="mx-0 col-12 px-0">
              {[...Array(4)].map((element, index) => (
                <Col xs={12} sm={6} md={4} lg={3} key={index} className="mb-4">
                  <div className="duty-free-card d-flex mb-4">
                    <div className="mx-0 row w-100">
                      <div className="duty-free-card-image col-sm-12 px-0">
                        <div
                          className="skeleton skeleton-product-img"
                          style={{ width: '100%' }}
                        ></div>
                      </div>
                      <div className="text-left col-sm-12 mt-3">
                        <h5 className="duty-free-card-title text-left skeleton skeleton-title w-100">
                          {' '}
                        </h5>
                      </div>
                      <div className="text-left py-3 col-sm-12">
                        <div className="row mx-0 col-12 px-0 mb-2">
                          <div className="col-3">
                            <div className="skeleton skeleton-title mb-0 w-100"></div>
                          </div>
                          <div className="col-1 px-0">
                            <h3 className="px-1 skeleton-text-placeholder mb-0">/</h3>
                          </div>
                          <div className="col-3">
                            <div className="skeleton skeleton-title mb-0  w-100"></div>
                          </div>
                        </div>
                        <div className="row mx-0 col-12 px-0 p-2">
                          <div className="col-6 ml-auto">
                            <div className="skeleton skeleton-reward mb-0 w-100"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          props.list.length > 0 && (
            <div className="py-4">
              <h3 className="mb-0 text-center pl-3">{Translation.t(props.title)}</h3>
              {props.list.length > 4 ? (
                <a
                  href={props.viewAllUrl}
                  className="float-right float-left duty-free-outline-primary-btn btn btn-primary mb-2"
                  style={{
                    textDecoration: 'none',
                    color: '#000',
                    fontWeight: 600,
                  }}
                >
                  {Translation.t('view all')}
                </a>
              ) : null}

              <Row className="mx-0 position-relative col-12 px-0">
                <span
                  className={
                    sliderTracker === 0
                      ? 'd-none'
                      : 'd-flex justify-content-center align-items-center duty-free-arrow-icon-container duty-free-arrow-icon-left'
                  }
                  role="button"
                  onClick={() => (sliderTracker === 0 ? null : prev())}
                  disabled={sliderTracker === 0}
                  tabIndex="0"
                  aria-label="Back"
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="duty-free-arrow-icon" />
                </span>
                <Col sm={12} className="px-0">
                  <Row className="mx-0">
                    {getResult().map(
                      (product, index) =>
                        index < 4 && (
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            className="row px-0 mx-0"
                            sm={6}
                            xs={12}
                            key={product.name}
                          >
                            <Link
                              to={'/product-detail/' + product.id}
                              className="text-decoration-none text-dark"
                            >
                              <Card
                                item={{
                                  detail: '',
                                  availableOffer: props.availableOffer,
                                  newArrival: props.newArrival,
                                  offer: props.offer,
                                  ...product,
                                }}
                              />
                            </Link>
                            <AddToCart product={product} />
                          </Col>
                        )
                    )}
                  </Row>
                </Col>
                <span
                  className={
                    sliderTracker >= props.list.length - 4
                      ? 'd-none'
                      : 'd-flex justify-content-center align-items-center duty-free-arrow-icon-container duty-free-arrow-icon-right'
                  }
                  role="button"
                  tabIndex="0"
                  onClick={() => (sliderTracker >= props.list.length - 4 ? null : next())}
                  disabled={sliderTracker >= props.list.length - 4}
                  aria-label="Next"
                >
                  <FontAwesomeIcon icon={faChevronRight} className="duty-free-arrow-icon" />
                </span>
              </Row>
            </div>
          )
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.batch.isLoading,
  };
};

export default withRouter(connect(mapStateToProps)(HomeProducts));
