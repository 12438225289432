import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Translation from 'i18next';
import ProgressSimpler from '../../UI/ProgressSimpler';

const Menu = (props) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const handleClick = (url) => {
    window.location = url;
  };

  return (
    <div
      className="mega-menu custom-mega-menu-container dropdown-menu"
      style={{
        display: 'block',
        maxWidth: '277px',
        textAlign: props.isLoading ? 'left' : 'none',
      }}
    >
      {props.isLoading && !props.categoryList ? (
        <ProgressSimpler />
      ) : (
        <div className="menu-item-container">
          <div className="menu-item-list px-0">
            <Row className="mx-0">
              {props.categoryList.map((category, index) => (
                <Col xs={12} key={index} className="parent-col px-0">
                  <ul className="list-group parent-ul">
                    <li
                      className="list-group-item dropdown"
                      onMouseEnter={() => setShowSubMenu('block')}
                      onMouseLeave={() => setShowSubMenu('none')}
                    >
                      <a
                        href={'/product-list/' + category.name + '/' + category.id}
                        onClick={(event) => {
                          // event.preventDefault();
                          handleClick('/product-list/' + category.name + '/' + category.id);
                        }}
                        className="product-cat"
                        style={{
                          textDecoration: 'none',
                          color: 'inherit',
                        }}
                      >
                        {Translation.t(category.name.toLowerCase())}
                      </a>
                      <span></span>
                    </li>
                  </ul>
                  {category.children.length !== 0 ? (
                    <>
                      <ul
                        className="list-group child-list-group row mx-0"
                        style={{
                          top: -11 - 40 * index + 'px',
                          height: 22 + 40 * props.categoryList.length + 'px',
                        }}
                      >
                        {category.children
                          .sort(function (a, b) {
                            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                              return -1;
                            }
                            if (nameA > nameB) {
                              return 1;
                            }

                            // names must be equal
                            return 0;
                          })
                          .map((subCat, index2) => (
                            <li className="list-group-item sub-cat col-12" key={index2}>
                              <a
                                onClick={() =>
                                  handleClick('/product-list/' + subCat.name + '/' + subCat.id)
                                }
                                href={'/product-list/' + subCat.name + '/' + subCat.id}
                                className="product-cat"
                                style={{
                                  textDecoration: 'none',
                                  color: 'inherit',
                                }}
                              >
                                {Translation.t(subCat.name.toLowerCase())}
                              </a>
                            </li>
                          ))}
                      </ul>
                      {category.category_ImageURL && (
                        <div
                          onClick={() => {
                            handleClick('/product-list/' + category.name + '/' + category.id);
                          }}
                          className="list-group category-image child-list-group row mx-0"
                          style={{
                            top: -11 - 40 * index + 'px',
                            backgroundImage: `url(` + category.category_ImageURL + `)`,
                            height: 22 + 40 * props.categoryList.length + 'px',
                          }}
                        ></div>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              ))}
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};
export default Menu;
