import { accountActionType } from '../../actions/auth/accountAction';

const initialState = {
  isSignedIn: false,
  userAccessToken: '',
  userInfo: {},
  paymentOptions: [],
  paymentSummary: {},
  preOrderList: [],
  userProfile : {},
  shebaMilesCustomerProfile : {},
  guestProfile : {},
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case accountActionType.UPDATEISSIGNIN:
      return {
        ...state,
        isSignedIn: action.value,
      };
    case accountActionType.UPDATECUSTOMERINFOMATION:
      return {
        ...state,
        userInfo: action.value.user,
        userAccessToken: action.value.accessToken,
      };
    case accountActionType.UPDATESHEBAMILESCUSTOMERINFO:
      return {
        ...state,
        shebaMilesCustomerProfile: action.value,
      };
    case accountActionType.UPDATEPAYMENTOPTIONS:
      return {
        ...state,
        paymentOptions: action.value,
      };
    case accountActionType.UPDATEPAYMENTSUMMARY:
      return {
        ...state,
        paymentSummary: action.value,
      };
    case accountActionType.SIGNOUT:
      return {
        ...state,
        isSignedIn: false,
        userInfo: {},
        preOrderList: [],
        shebaMilesCustomerProfile: {},
      };
    case accountActionType.UPDATEPREORDERLIST:
      return {
        ...state,
        preOrderList: action.value,
      };
    case accountActionType.UPDATEUSERPROFILE:
      var temp = JSON.parse(JSON.stringify(state.userInfo));
      temp.firstName = action.value.firstName;
      temp.lastName = action.value.lastName;

      return {
        ...state,
        userProfile: action.value,
        userInfo: temp,
      };
      case accountActionType.SETGUESTPROFILE:
        return {
          ...state,
          guestProfile: action.value,
        };

    default:
      return state;
  }
};

export default accountReducer;
