import HttpService from "../shared/HttpService";

export default class AccountService {
  loginToDutyFree(clientToken, payload) {
    return HttpService.getAccessTokenServive(
      clientToken,
      payload.email,
      payload.password
    );
  }

  loginWithShebaMiles(clientToken, accessToken, payload) {
    let relativeUrl = "/User/api/V1.0/Account/ShebamilesSignIn";
    let ActionPrivilegies = "Account-Save";

    let tempPayload = {
      MemberID: "",
      Password : ""
    };

    tempPayload.MemberID = payload.MemberID;
    tempPayload.Password = payload.Password;
    

    return HttpService.postServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      tempPayload,
      ActionPrivilegies
    );
    // return HttpService.getAccessTokenServive(
    //   clientToken,
    //   payload.email,
    //   payload.password
    // );
  }

  registerToDutyFree(clientToken, accessToken, payload) {
    let relativeUrl = "/MasterData/api/V1.0/CustomerProfile/Create";
    let ActionPrivilegies = "Account-Save";

    let tempPayload = {
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      title: "",
      nationality: "",
      mobileNumber: "",
      middleName: "",
      username: "",
      ShabamilesMembershipNumber: "",
    };

    tempPayload.email = payload.email;
    tempPayload.password = payload.password;
    tempPayload.firstName = payload.firstName;
    tempPayload.lastName = payload.lastName;
    tempPayload.title = parseInt(payload.title);
    tempPayload.nationality = payload.nationality;
    tempPayload.middleName = payload.middleName;

    tempPayload.username = payload.username;
    tempPayload.ShabamilesMembershipNumber = payload.ShabamilesMembershipNumber;
    
    tempPayload.mobileNumber = "+" + payload.countryCode + payload.phoneNumber;

    return HttpService.postServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      tempPayload,
      ActionPrivilegies
    );
  }

  forgotPassword(clientToken, accessToken, email) {
    let relativeUrl = "/User/api/V1.0/Password/ForgotPassword";
    let ActionPrivilegies = "PreOrder-Access";

    let tempPayload = {
      username: email,
      EmailTemplateClientType: 1,
    };
    return HttpService.postServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      tempPayload,
      ActionPrivilegies
    );
  }
  resetPassword(clientToken, accessToken, payload) {
    let relativeUrl = "/User/api/V1.0/Password/ResetForgotPassword";
    let ActionPrivilegies = "PreOrder-Access";

    let tempPayload = {
      verification_token: payload.verification_token,
      password: payload.password,
    };
    return HttpService.postServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      tempPayload,
      ActionPrivilegies
    );
  }

  changePassword(clientToken, accessToken, payload) {
    let relativeUrl = "/User/api/V1.0/Password/ChangePassword";
    let ActionPrivilegies = "PreOrder-Access";

    let tempPayload = {
      username: payload.username,
      oldPassword: payload.oldPassword,
      newPassword: payload.newPassword,
    };
    return HttpService.postServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      tempPayload,
      ActionPrivilegies
    );
  }

  updateUserProfile(clientToken, accessToken, payload) {
    let relativeUrl = "/MasterData/api/V1.0/CustomerProfile/Update";
    let ActionPrivilegies = "PreOrder-Access";

    // let tempPayload = {
    //   id: 0,
    //   title: 0,
    //   firstName: "string",
    //   middleName: "string",
    //   lastName: "string",
    //   email: "user@example.com",
    //   password: "string",
    //   nationality: "string",
    //   mobileNumber: "string",
    // };
    payload.title = parseInt(payload.title);
    return HttpService.putServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      payload,
      ActionPrivilegies
    );
  }

  getProfile(clientToken, accessToken, userId) {
    let relativeUrl =
      "/MasterData/api/V1.0/CustomerProfile/GetPassengerProfile";
     let ActionPrivilegies = "PreOrder-Access";

    let tempPayload = { UserId :  userId};
    return HttpService.getServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      tempPayload,
      ActionPrivilegies
    );
  }

  getRedirectUrl = (querys) => {
    var redirectUrl = "/account/dashboard";
    var query = new URLSearchParams(querys);
    if(query.get("redirectUrl"))
      redirectUrl = query.get("redirectUrl");

    return redirectUrl;
  }

}
