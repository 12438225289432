import { batchActionType } from '../../actions/shared/batchAction';

const initialState = {
  newArrivals: [],
  specialOffers: [],
  products: [],
  travelExclusives: [],
  bestSellers: [],
  deals: [],
  isLoading: true,
  onBoardingStatus: false,
  onBoardingDetail: {},
  promocodeProducts: {},
  offerPriceLists: [],
};

const batchReducer = (state = initialState, action) => {
  switch (action.type) {
    case batchActionType.UPDATEBATCH:
      if (action.values.onBoardAvailabilitiesRes) {
        action.values.allInOneList = action.values.onBoardAvailabilitiesRes;
      }
      return {
        ...state,
        newArrivals: action.values.newArrivalItems || [],
        products: action.values.allInOneList || [],
        specialOffers: action.values.specialOfferItems || [],
        travelExclusives: action.values.travelExeclusiveItems || [],
        bestSellers: action.values.bestSellerItems || [],
        deals: action.values.deals || [],
      };
    case batchActionType.UPDATEBATCHISLOADING:
      return {
        ...state,
        isLoading: false,
      };

    case batchActionType.UPDATEBATCHISLOADINGTOTRUE:
      return {
        ...state,
        isLoading: true,
      };
    case batchActionType.SETONBOARDINGSTATUS:
      return {
        ...state,
        onBoardingStatus: action.value,
      };
    case batchActionType.UPDATEONBOARDINGDETAIL:
      return {
        ...state,
        onBoardingDetail: action.value,
      };
      case batchActionType.SETPROMOCODEPRODUCTS:
      return {
        ...state,
        promocodeProducts: action.value,
      };
      case batchActionType.SETOFFERPRICELISTS:
        return {
          ...state,
          offerPriceLists: action.value,
        };
    default:
      return state;
  }
};

export default batchReducer;
