import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './ConfirmationSummary.css';
import { connect } from 'react-redux';
import { cartActionType } from 'src/redux/actions/cart/cartAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { getSalesPrice, roundTwoDecimal, thousandsSeparators } from 'src/service/shared/utilits';

const ConfirmationSummary = (props) => {
  document.title = 'Confirmation';

  const getImageUrl = (itemId) => {
    if(props.products){
      const product = props.products.filter(prod => prod.id === itemId)
      return product[0] && product[0].itemImage !== null
      ? product[0].itemImage[0]
          .imageUrl
      : ''
      }
    return ''
  }
  const getName = (itemId) => {
    if(props.products){
    const product = props.products.filter(prod => prod.id === itemId)
    return product[0] ? product[0].name : ''
    }
    return ''
  }
  return (
    <section style={{ minHeight: '50vh', margin: '5rem 0' }}>
      <Container>
        {!(
          props.paymentSummary.orderItemRes !== null &&
          props.paymentSummary.orderItemRes !== undefined
        // ) || !props.isSignedIn ? (          
        ) ? (
          <div className="text-left">
            <div className="pb-3 summary-head-txt">
              <h3 style={{ color: '#2e7d32' }}>
                <b>THANK YOU FOR YOUR ORDER</b>
              </h3>
            </div>
          </div>
        ) : (
          <>
            {props.paymentSummary.orderItemRes !== null &&
            props.paymentSummary.orderItemRes !== undefined ? (
              <div className="text-left">
                <div className="pb-3 summary-head-txt">
                  <h3 style={{ color: '#2e7d32' }}>
                    <b>THANK YOU FOR YOUR ORDER</b>
                  </h3>
                </div>
                <div>
                  <p>
                    Your order number is
                    <span
                      style={{
                        fontSize: '20px',
                        fontWeight: 600,
                        borderRadius: '20px',
                        backgroundColor: '#eee',
                        padding: '5px 10px',
                      }}
                    >
                      {props.paymentSummary.orderCode}
                    </span>{' '}
                    , placed {props.paymentSummary.transactionDate}.
                  </p>
                  <p
                    style={{
                      fontSize: '17px',
                      fontWeight: 600,
                      borderRadius: '6px',
                      backgroundColor: '#eee',
                      padding: '5px 10px',
                    }}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                    Important notice: Please kindly bring your Confirmation Number on board to
                    collect Pre-Ordered items. As well, you are requested to show Confirmation
                    number for respected Cabin Crew along with your boarding pass
                  </p>
                </div>
                <hr />
                <Row>
                  <Col>
                    <div>
                      <h5 className="mb-3">
                        <b>Customer Details</b>
                      </h5>
                    </div>
                    <div>
                      <h6>{props.paymentSummary.customerName}</h6>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h5 className="mb-3">
                        <b>Payment method</b>
                      </h5>
                    </div>
                    <div>
                      {props.paymentSummary.paymentOption === 'Cash On Board' ? (
                        <h6>Pay On-Board</h6>
                      ) : (
                        <h6>{props.paymentSummary.paymentOption}</h6>
                      )}
                    </div>
                  </Col>
                </Row>
                <br />
                <br />
                {props.paymentSummary.orderItemRes.map((element) => {
                  return (
                    //props.cartForSummary[element.itemId] ?
                    <div key={element.id} className="confirmation-summary-item-container">
                      <Row>
                        <Col xs={12} sm={12} md={10} lg={7}>
                          <div>Item</div>
                          <Row>
                            <Col xs={4} sm={4} md={3} lg={2}>
                              <img
                                src={
                                  !props.cartForSummary[element.itemId] ? getImageUrl(element.itemId):
                                  props.cartForSummary[element.itemId].item.itemImage !== null
                                    ? props.cartForSummary[element.itemId].item.itemImage[0]
                                        .imageUrl
                                    : ''
                                }
                                className="img-fluid"
                                alt="product"
                                loading="lazy"
                              />
                            </Col>
                            <Col xs={8} sm={8} md={9} lg={8}>
                              <p className="text-left ">
                                {
                                                                  !props.cartForSummary[element.itemId] ? getName(element.itemId):
                                                                  props.cartForSummary[element.itemId].item.name}
                              </p>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={6} sm={6} md={3} lg={2}>
                          <div className="item-info">
                            <div>
                              Qty <span className="item-info-colon">:</span>
                            </div>
                            <div>
                              <p className="text-left ">{element.quantity}</p>
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={3}>
                          <div className="item-info">
                            <div>
                              Price <span className="item-info-colon">:</span>
                            </div>
                            <div>
                              {props.paymentSummary.paymentOption === 'Mile' ? (
                                <p className="text-left">
                                  {thousandsSeparators(
                                    roundTwoDecimal(element.totalPriceForRedemptionInMiles)
                                  )}{' '}
                                  Miles
                                </p>
                              ) : (
                                <p className="text-left">
                                  {thousandsSeparators(getSalesPrice(element.totalPrice))}{' '}
                                  {props.paymentSummary.currency}
                                </p>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>//: null
                  );
                })}

                <div className="confirmation-summary-item-container">
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={9}>
                      <div>
                        <h4 className="p-4">
                          <b>Total</b>
                        </h4>
                      </div>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={3}>
                      <div>
                        <div>
                          Price <span className="item-info-colon">:</span>
                        </div>
                        <div>
                          <h3 className="text-left">
                            <b>
                              {props.paymentSummary.paymentOption === 'Mile' ? (
                                <>
                                  {thousandsSeparators(
                                    props.paymentSummary.orderItemRes.reduce((total, key) => {
                                      return (
                                        total + roundTwoDecimal(key.totalPriceForRedemptionInMiles)
                                      );
                                    }, 0)
                                  )}{' '}
                                  Miles{' '}
                                </>
                              ) : (
                                <>
                                  {thousandsSeparators(
                                    props.paymentSummary.orderItemRes.reduce((total, key) => {
                                      return total + getSalesPrice(key.totalPrice);
                                    }, 0)
                                  )}{' '}
                                  {props.paymentSummary.currency}{' '}
                                </>
                              )}
                            </b>
                          </h3>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : null}
          </>
        )}
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    cartForSummary: state.cart.cartForSummary,
    isSignedIn: state.account.isSignedIn,
    userInfo: state.account.userInfo,
    paymentSummary: state.account.paymentSummary,
    products: state.batch.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    swapCart: (value) =>
      dispatch({
        type: cartActionType.SWAPPCART,
        value: value,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationSummary);
