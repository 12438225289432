export default class SortService {
  constructor() {
    this.orderBy = {
      ASC: "ASC",
      DESC: "DESC",
    };
    this.SortOptions = {
      Default: "Default",
      ByName: "ByName",
      ByPrice: "ByPrice",
      ByDate: "ByDate",
    };
  }
  static orderBy = {
    ASC: "ASC",
    DESC: "DESC",
  };
  static SortOptions = {
    Default: "Default",
    ByName: "ByName",
    ByPrice: "ByPrice",
    ByDate: "ByDate",
  };
  sortProducts(array, sortType, orderBy) {
    var resultArray = array;
    switch (sortType) {
      case this.SortOptions.ByName:
        resultArray = array.sort((firstElement, secondElement) => {
          if (firstElement.name > secondElement.name) return -1;
          if (firstElement.name < secondElement.name) return 1;
          return 0;
        });
        break;
      case this.SortOptions.ByPrice:
        resultArray = array.sort((firstElement, secondElement) => {
          if (firstElement.currentPrice > secondElement.currentPrice) return -1;
          if (firstElement.currentPrice < secondElement.currentPrice) return 1;
          return 0;
        });
        break;
      case this.SortOptions.ByDate:
        resultArray = array.sort((firstElement, secondElement) => {
          if (firstElement.regiseredDate > secondElement.regiseredDate) return -1;
          if (firstElement.regiseredDate < secondElement.regiseredDate) return 1;
          return 0;
        });
        break;
      default:
        break;
    }
    return orderBy === this.orderBy.ASC ? resultArray.reverse() : resultArray;
  }
}
