import React from 'react';
import { Container } from 'react-bootstrap';

import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ImportantPages.css';

const ContactUs = (props) => {
  document.title = 'Contact Us';

  return (
    <Container
      className="my-5 px-3 py-5 px-md-5 duty-free-payment-container"
      style={{ marginBottom: '5.7em !important' }}
    >
      <p>Want more information contact our Privacy Compliance Officer</p>
      <ul className="list-group-flush list-group">
        <li className="list-group-item">
          <a className="contact-us-number" href="tel:+251115178101">
            <FontAwesomeIcon icon={faPhoneAlt} className="mr-3" />
            +251 115 178101
          </a>
        </li>
        <li className="list-group-item">
          <a className="contact-us-number" href="mailto:DutyFreeServices@ethiopianairlines.com">
            <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
            DutyFreeServices@ethiopianairlines.com
          </a>
        </li>
        <li className="list-group-item">
          <a className="contact-us-number" href="mailto:InflightDutyfree@ethiopianairlines.com">
            <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
            InflightDutyfree@ethiopianairlines.com
          </a>
        </li>
      </ul>
    </Container>
  );
};

export default ContactUs;
