import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "../../../master-data/locale/en/translation.json";
import translationAR from "../../../master-data/locale/ar/translation.json";
import translationZH from "../../../master-data/locale/zh/translation.json";

const fallbackLng = ["en"];
const availableLanguages = ["en", "ar", "zh"];

const resources = {
  en: {
    translation: translationEN
  },
  ar : {
    translation: translationAR
  },
  zh : {
    translation: translationZH
  },
  // fr : {
  //   translation: translationFR
  // },
  // pt : {
  //   translation: translationPT
  // },
  // es : {
  //   translation: translationES
  // },
  // de : {
  //   translation: translationDE
  // },
  // it : {
  //   translation: translationIT
  // }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },
    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });
export default i18n;
