import Translation from 'i18next';
import './PromotionPage.css';

const PromotionPage = (props) => {
  return (
    <div className="w-100 my-4 promotion-container d-flex">
      <div
        className="d-flex justify-content-center m-auto m-md-0 align-items-center align-items-md-start p-4 flex-column w-auto h-auto rounded-lg"
        style={{}}
      >
        {/* <h1
          className="text-white"
          style={{
            textShadow: "#a3a3a3 1px 1px 1px",
            fontFamily: "'Pinyon Script', cursive",
            fontSize: "3.4em"
          }}
        >
          Special Offers
        </h1> */}
        <button
          onClick={() => props.history.push('/new-arrivals')}
          className="btn duty-free-shop-now-btn px-5 mb-md-3 py-3 d-flex justify-content-center align-items-center"
        >
          {Translation.t('shop now')}
        </button>
      </div>
    </div>
  );
};

export default PromotionPage;
