// import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";


import { connect } from "react-redux";
import { accountActionType } from "../../redux/actions/auth/accountAction";
import { withRouter } from "react-router-dom";
import Loading from "../shared/UI/Loading";
import Translation from "i18next";

const AccountSideMenu = (props) => {
  const [signiningOut, setSigniningOut] = useState(false);

  const logOut = () => {
    setSigniningOut(true);
    setTimeout(() => {
      setSigniningOut(false);
      props.signOut();
      props.history.push("/account");
    }, 1000);
  };

  return (
    <Row className="text-left dy-side-menu-container">
      {signiningOut ? <Loading /> : null}

      <Col xs="12">
        <div
          className={
            props.from === 1
              ? "dy-side-menu-item active-menu"
              : "dy-side-menu-item"
          }
          onClick={() => {
            props.from !== 1
              ? props.history.push("/account/dashboard")
              : console.log("");
          }}
        >
          <span>{Translation.t("dashboard")}</span>
        </div>
      </Col>
      <Col xs="12">
        <div
          className={
            props.from === 2
              ? "dy-side-menu-item active-menu"
              : "dy-side-menu-item"
          }
          onClick={() => {
            props.from !== 2
              ? props.history.push("/account/orders")
              : console.log("");
          }}
        >
          <span>{Translation.t("orders")}</span>
        </div>
      </Col>
      <Col xs="12">
        <div
          className={
            props.from === 3
              ? "dy-side-menu-item active-menu"
              : "dy-side-menu-item"
          }
          onClick={() => {
            props.from !== 3
              ? props.history.push("/account/account-details")
              : console.log("");
          }}
        >
          <span>{Translation.t("account details")}</span>
        </div>
      </Col>
      <Col xs="12">
        <div className="dy-side-menu-item" onClick={() => logOut()}>
          <span>{Translation.t("log out")}</span>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    clientToken: state.authClient.clientToken,
    accessToken: state.authAccess.accessToken,
    cart: state.cart.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () =>
      dispatch({
        type: accountActionType.SIGNOUT,
      }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountSideMenu));
