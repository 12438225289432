import HttpService from "../shared/HttpService";

export default class PreOrderService {
  cancelPreOrder(clientToken, accessToken, payload) {
    let relativeUrl = "/PreOrder/api/V1.0/PreOrder/CancelPreOrder";
    let ActionPrivilegies = "PreOrder-Access";

    let tempPayload = { ...payload };
    tempPayload.ReasonOfCancellation = parseInt(payload.ReasonOfCancellation);
    return HttpService.putServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      tempPayload,
      ActionPrivilegies
    );
  }

  getOrders(clientToken, accessToken, userId, userEmail) {
    let relativeUrl = "/PreOrder/api/V1.0/PreOrder/GetMyOrders";
    let ActionPrivilegies = "PreOrder-Access";

    let tempPayload = userId ? userId : userEmail;
    
    return HttpService.getServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      tempPayload,
      ActionPrivilegies
    );
  }

  updateOrderCollectingDetail(clientToken, accessToken, payload, order) {
    let relativeUrl = "/PreOrder/api/V1.0/PreOrder/UpdatePreOrder";
    let ActionPrivilegies = "PreOrder-Access";

    let tempPayload = { ...order };

    tempPayload.origin = payload.origin.value;
    tempPayload.destination = payload.destination.value;
    tempPayload.departureDate = payload.departureDate;
    tempPayload.flightNumber = payload.flightNumber.value;
    tempPayload.ticketNumber = payload.ticketNumber;

    tempPayload.orderItemRequet = JSON.parse(
      JSON.stringify(order.orderItemRes)
    );

    delete tempPayload.orderItemRes;
    delete tempPayload.paymentStatus;
    delete tempPayload.orderRequestStatus;
    delete tempPayload.paymentOption;

    return HttpService.putServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      tempPayload,
      ActionPrivilegies
    );
  }

  updateOrderDetail(clientToken, accessToken, payload, order) {
    let relativeUrl = "/PreOrder/api/V1.0/PreOrder/UpdatePreOrder";
    let ActionPrivilegies = "PreOrder-Access";

    // let tempPayload = { ...order };

    let tempPayload = { ...order };
    delete tempPayload.orderItemRes;
    delete tempPayload.paymentStatus;
    delete tempPayload.orderRequestStatus;
    delete tempPayload.paymentOption;

    tempPayload.orderItemRequet = payload;

    return HttpService.putServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      tempPayload,
      ActionPrivilegies
    );
  }

  getPricesForVolumeOrder(clientToken, accessToken, payload) {
    let relativeUrl = "/PreOrder/api/V1.0/PreOrder/GetPricesForVolumeOrder";
    let ActionPrivilegies = "PreOrder-Access";

    return HttpService.postServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      [payload],
      ActionPrivilegies
    );
  }

  getSubscribetoMail(clientToken, accessToken, payload) {
    let relativeUrl = "/PreOrder/api/V1.0/PreOrder/SubscribetoMail";
    let ActionPrivilegies = "PreOrder-SubscribetoMail";

    return HttpService.postServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      payload,
      ActionPrivilegies
    );
  }
}
