import { cartActionType } from '../../actions/cart/cartAction';

const initialState = {
  cartList: [],
  cart: {},
  cartForSummary: {},
  specialDiscount: {},
  offerThroughSpecialDiscount: {},
  buyGetSpcialOffer: {},
  selectedItemsInCart: {},
};

const cartReducer = (state = initialState, action) => {
  var updatedCart = null;
  var updatedSpecialDiscount = null;
  var updatedOfferThroughSpecialDiscount = null;
  var updatedBuyGetSpcialOffer = null;
  var updatedSelectedItemsInCart = null;
  switch (action.type) {
    case cartActionType.ADDTOCART:
      // if
      updatedCart = JSON.parse(JSON.stringify(state.cart));
      // obj["key"]
      updatedSelectedItemsInCart = JSON.parse(JSON.stringify(state.selectedItemsInCart));
      updatedSelectedItemsInCart[action.data.item.id] = true;

      if (updatedCart[action.data.item.id] === undefined) {
        updatedCart[action.data.item.id] = action.data;
      } else {
        updatedCart[action.data.item.id] = action.data;
      }
      return {
        ...state,
        cart: updatedCart,
        selectedItemsInCart: updatedSelectedItemsInCart,
      };
    case cartActionType.UPDATEQUANTITY:
      // if
      updatedCart = JSON.parse(JSON.stringify(state.cart));
      // obj["key"]
      updatedCart[action.id].quantity = updatedCart[action.id].quantity + action.value;

      return {
        ...state,
        cart: updatedCart,
      };
    case cartActionType.REMOVEFITEMFROMCART:
      // if
      updatedCart = JSON.parse(JSON.stringify(state.cart));
      updatedSpecialDiscount = JSON.parse(JSON.stringify(state.specialDiscount));
      updatedOfferThroughSpecialDiscount = JSON.parse(
        JSON.stringify(state.offerThroughSpecialDiscount)
      );

      updatedBuyGetSpcialOffer = JSON.parse(JSON.stringify(state.buyGetSpcialOffer));
      // obj["key"]
      delete updatedCart[action.id];
      delete updatedSpecialDiscount[action.id];
      delete updatedOfferThroughSpecialDiscount[action.id];
      delete updatedBuyGetSpcialOffer[action.id];
      updatedSelectedItemsInCart = JSON.parse(JSON.stringify(state.selectedItemsInCart));
      delete updatedSelectedItemsInCart[action.id];

      return {
        ...state,
        cart: updatedCart,
        specialDiscount: updatedSpecialDiscount,
        offerThroughSpecialDiscount: updatedOfferThroughSpecialDiscount,
        buyGetSpcialOffer: updatedBuyGetSpcialOffer,
        selectedItemsInCart: updatedSelectedItemsInCart,
      };
    case cartActionType.SWAPPCART:
      // if
      var swappedCart = JSON.parse(JSON.stringify(state.cart));
      var cartForSummaryTemp = {};

      if (swappedCart == {}) {
        return {
          ...state,
        };
      } else {
        updatedSelectedItemsInCart = JSON.parse(JSON.stringify(state.selectedItemsInCart));
        Object.keys(updatedSelectedItemsInCart).map((index) => {
          if (updatedSelectedItemsInCart[index]) {
            cartForSummaryTemp[index] = swappedCart[index];
            delete swappedCart[index];
          }
          return index;
        });
      }
      return {
        ...state,
        cart: swappedCart,
        cartForSummary: cartForSummaryTemp,
        selectedItemsInCart: {},
      };
    case cartActionType.UPDATESPECIALDISCOUNT:
      // if
      updatedSpecialDiscount = JSON.parse(JSON.stringify(state.specialDiscount));
      updatedOfferThroughSpecialDiscount = JSON.parse(
        JSON.stringify(state.offerThroughSpecialDiscount)
      );
      updatedBuyGetSpcialOffer = JSON.parse(JSON.stringify(state.buyGetSpcialOffer));
      // obj["key"]
      // loop through
      var currentItem = action.data.find((element) => element.itemId === action.payload.itemId);
      var offerList = action.data.filter((element) => element.itemId !== action.payload.itemId);
      if (action.isBuyGet) {
        updatedBuyGetSpcialOffer[action.payload.itemId] = offerList;
      } else {
        if (
          updatedSpecialDiscount[action.payload.itemId] &&
          action.payload.currentPrice === updatedSpecialDiscount[action.payload.itemId].currentPrice
        ) {
          delete updatedSpecialDiscount[currentItem.itemId];
        } else if (currentItem && action.payload.currentPrice !== currentItem.currentPrice) {
          updatedSpecialDiscount[currentItem.itemId] = currentItem;
        } else {
        }
        updatedOfferThroughSpecialDiscount[action.payload.itemId] = offerList;
      }

      return {
        ...state,
        specialDiscount: updatedSpecialDiscount,
        offerThroughSpecialDiscount: updatedOfferThroughSpecialDiscount,
        buyGetSpcialOffer: updatedBuyGetSpcialOffer,
      };
    case cartActionType.UPDATECARTAFTERCURRENCYCHANGE:
      // if
      updatedCart = JSON.parse(JSON.stringify(state.cart));
      // obj["key"]
      action.data.map((element) => {
        updatedCart[element.id].item = element;
        // updatedCart[element.id] = element;
      });

      return {
        ...state,
        cart: updatedCart,
      };
    case cartActionType.DELETESPECIALOFER:
      updatedSpecialDiscount = JSON.parse(JSON.stringify(state.specialDiscount));
      action.itemId.map((itemId) => {
        if (updatedSpecialDiscount[itemId]) {
          delete updatedSpecialDiscount[itemId];
        }
      });

      return {
        ...state,
        specialDiscount: updatedSpecialDiscount,

      };
      case cartActionType.DELETEBUYGETOFFER:  
        return {
          ...state,
          buyGetSpcialOffer: {},  
        };
    case cartActionType.UPDATESELECTEDITEM:
      // if
      updatedSelectedItemsInCart = JSON.parse(JSON.stringify(state.selectedItemsInCart));
      // obj["key"]
      updatedSelectedItemsInCart[action.key] = action.value;

      return {
        ...state,
        selectedItemsInCart: updatedSelectedItemsInCart,
      };
    default:
      return state;
  }
};

export default cartReducer;
