import HttpService from "../shared/HttpService";

export default class ProductService {
  getProductById(clientToken, accessToken, payload) {
    let relativeUrl = "/MasterData/api/V1.0/Item/GetById";
    let ActionPrivilegies = "PreOrder-Access";

    return HttpService.getServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      payload,
      ActionPrivilegies
    );
  }
  getProductsDetail(clientToken, accessToken, payload) {
    let relativeUrl = "/PreOrder/api/V1.0/PreOrder/GetItemDetail";
    let ActionPrivilegies = "PreOrder-Access";
   
    return HttpService.postServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      payload,
      ActionPrivilegies
    );
  }

  
  getRelatedProduct(clientToken, accessToken, payload) {
    let relativeUrl = "/PreOrder/api/V1.0/PreOrder/GetSimilarItems";
    let ActionPrivilegies = "PreOrder-GetSimilarItems";
   
    return HttpService.getServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      payload,
      ActionPrivilegies
    );
  }
  getProductsByPromocode(clientToken, accessToken, payload) {
    let relativeUrl = "/PreOrder/api/V1.0/PriceList/GetPriceListByPromoCode";
    let ActionPrivilegies = "PreOrder-Access";

    return HttpService.getServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      payload,
      ActionPrivilegies
    );
  }
}
