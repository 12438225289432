import HttpService from "../shared/HttpService";

export default class FeedBackService {
  submitFeedBack(clientToken, accessToken, payload) {
    let relativeUrl = "/PreOrder/api/V1.0/CustomerFeedBack/Create";
    let ActionPrivilegies = "PreOrder-Access";

    return HttpService.postServiceGeneralPurpose(
      relativeUrl,
      clientToken,
      accessToken,
      payload,
      ActionPrivilegies
    );
  }
}
