import { clientTokenActionType } from "../../actions/auth/clientTokenAction";

const initialState = {
  clientToken: "",
};

const clientTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case clientTokenActionType.SAVECLIENTTOKEN:
      return {
        ...state,
        clientToken: action.token,
      };

    default:
      return state;
  }
};

export default clientTokenReducer;
