import { cartActionType } from 'src/redux/actions/cart/cartAction';
import { store } from 'src/redux/store/store';
import { getSalesPrice } from '../utilits';
import { getBundleItems } from 'src/service/payment/BundleItems';

export function getPricesForVolumeOrder(item, quantity) {
  if (item.volumePriceList && item.volumePriceList.length) {
    let payload = {
      itemId: item.id,
      quantity: quantity,
      // priceListId: 35,
      priceListId: item.priceListId,
      currentPrice: item.currentPrice,
    };

    const maxVolumePriceEnd = Math.max(...item.volumePriceList.map(o => o.endQuantity))
    let volumePriceList = item.volumePriceList.filter(
      (volumePrice) =>
        (volumePrice.startQuantity <= quantity) & (volumePrice.endQuantity >= quantity) ||
        (maxVolumePriceEnd && volumePrice.endQuantity === maxVolumePriceEnd && volumePrice.endQuantity
          < quantity)
    );
    if (volumePriceList.length !== 0) {
      let data = volumePriceList;
      data[0].currentPrice = data[0].customePrice;
      //   updateSpecialDiscount(data, payload);
      store.dispatch(updateSpecialDiscount(data, payload));
    } else {
      store.dispatch(updateSpecialDiscount([], payload));
      store.dispatch(deleteSpecialDiscount([item.id]));
    }
  }
}

export function getBuyGetPriceList(item, quantity) {
  if (item.buyGetPriceList && item.buyGetPriceList.length) {
    let payload = {
      itemId: item.id,
      quantity: quantity,
      priceListId: item.priceListId,
      currentPrice: item.currentPrice,
    };

    let buyGetPriceList = item.buyGetPriceList.filter(
      (buyGetPrice) => buyGetPrice.buyQuantity <= quantity
    );

    if (buyGetPriceList.length) {
      const state = store.getState();
      const products = state.batch.products;
      let data = JSON.parse(
        JSON.stringify(products.filter((product) => product.id === buyGetPriceList[0].getItemId))
      );
      if (data.length) {
        data[0].individualPrice = data[0].currentPrice;
        data[0].currentPrice = 0;
        data[0].itemId = data[0].id;
        store.dispatch(updateSpecialDiscount(data, payload, true));
      }
    } else {
      store.dispatch(updateSpecialDiscount([], payload, true));
      store.dispatch(deleteSpecialDiscount([item.id]));
    }
  }
}

export function getDiscount() {
  const state = store.getState();
  const cart = state.cart.cart;
  const selectedItemsInCart = state.cart.selectedItemsInCart;
  const specialDiscount = state.cart.specialDiscount;
  const promocodeProducts = state.batch.promocodeProducts;

  const bundleItemResponse = getBundleItems();
  var bundleItemIds = bundleItemResponse.bundleItemIds;
  var selectedBundlePriceList = bundleItemResponse.selectedBundlePriceList;
  var savedAmountInBundles =  parseFloat(
    selectedBundlePriceList && selectedBundlePriceList.amount && selectedBundlePriceList.amount !== null
      ? selectedBundlePriceList.amount
      : 0
  );

  // return Object.keys(specialDiscount).reduce((total, key) => {
  //   // discount
  //   if (key in cart && key in selectedItemsInCart && selectedItemsInCart[key]) {
  //     var tempDiscount =
  //       getSalesPrice(cart[key]?.item?.currentPrice) -
  //       getSalesPrice(specialDiscount[key].currentPrice);
  //     tempDiscount = tempDiscount * cart[key].quantity;
  //     return total + tempDiscount;
  //   } else return total + 0;
  // }, 0);
  return savedAmountInBundles + Object.keys(cart).reduce((total, key) => {
    // discount
    if (key in selectedItemsInCart && selectedItemsInCart[key]) {
      //promocode
      if (
        promocodeProducts.priceListIndividualRateRes &&
        promocodeProducts.priceListIndividualRateRes.filter(
          (item) => item.itemId == key
        ).length > 0
      ) {
        var promocodeItem = promocodeProducts.priceListIndividualRateRes.filter(
          (item) => item.itemId == key
        );
        var tempDiscount =
          getSalesPrice(cart[key]?.item?.currentPrice) -
          getSalesPrice(promocodeItem[0]?.amount);
        tempDiscount = tempDiscount * cart[key].quantity;
        return total + tempDiscount;
      } else if (key in specialDiscount) {
        var tempDiscount =
          getSalesPrice(cart[key]?.item?.currentPrice) -
          getSalesPrice(specialDiscount[key].currentPrice);

        tempDiscount = tempDiscount * cart[key].quantity;
        return total + tempDiscount;
      }else if(cart[key]?.item?.isPercentage && cart[key]?.quantity >=
        cart[key]?.item?.orderQuantity){
          var tempDiscount =
          getSalesPrice(cart[key]?.item?.salesRate) -
          getSalesPrice(cart[key]?.item?.currentPrice);

        tempDiscount = tempDiscount * cart[key].quantity;
        return total + tempDiscount;
      } else return total + 0;
    } else return total + 0;
  }, 0);
}

export function updateSpecialDiscount(value, payload, isBuyGet) {
  return {
    type: cartActionType.UPDATESPECIALDISCOUNT,
    data: value,
    payload: payload,
    isBuyGet: isBuyGet,
  };
}
export function deleteSpecialDiscount(value) {
  return {
    type: cartActionType.DELETESPECIALOFER,
    itemId: value,
  };
}
export function getProductRewardMile(product, quantity) {
  if ((product.volumePriceList != null) & (product.volumePriceList.length !== 0)) {
    let volumePriceList = product.volumePriceList.filter(
      (volumePrice) =>
        (volumePrice.startQuantity <= quantity) & (volumePrice.endQuantity > quantity)
    );
    if (volumePriceList.length != 0) return volumePriceList[0].customePrice;
    else return product.currentPrice;
  //} else return product.currentPrice;  
  } else return product.isPercentage ? product.salesRate : product.currentPrice;
}
